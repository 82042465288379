import React, { useEffect, useState, useContext, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as S from "./Estilos";
import Header from "../../Componentes/Header/";
import Load from "../../Componentes/Load";
import Footer from "../../Componentes/Footer/";
import ViewerContext from "../../context/context";
import ImgBaixoSobreMim from "../../Images/SobreMim/ImgBaixoSobreMim.png";
import Snack from "../../Componentes/Snack";

function Cadastro(props) {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const AbreCadastro = () => {
    history.push("/Cadastro");
  };

  const AbreAssinaturas = () => {
    history.push("/Assinaturas");
  };

  return (
    <>
      <Header tipo={"Cadastro"} />

      <S.ForaBotaoEditaCadastro>
        <S.BotaoEditaCadastro onClick={() => AbreCadastro()}>
          Editar Cadastro
        </S.BotaoEditaCadastro>
        <S.BotaoEditaCadastro onClick={() => AbreAssinaturas()}>
          Minhas assinaturas
        </S.BotaoEditaCadastro>
      </S.ForaBotaoEditaCadastro>

      <S.ForaImgBaixoSobreMim>
        <S.ImagemBaixoSobreMim src={ImgBaixoSobreMim} />
      </S.ForaImgBaixoSobreMim>

      <Footer />
    </>
  );
}

export default Cadastro;
