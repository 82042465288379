import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as S from "./Estilos";
import Header from "../../Componentes/Header";
import Footer from "../../Componentes/Footer";
import { getData, postData } from "../../utils/useData";

import ImgBanner from "../../Images/SobreMim/Banner.jpg";
import ImgBannerResp from "../../Images/SobreMim/BannerResp.jpg";
import ImgLizzy from "../../Images/SobreMim/ImgLizzy.png";
import ImgPresente from "../../Images/SobreMim/ImgPresente.png";
import ImgCoracao from "../../Images/SobreMim/ImgCoracao.png";
import ImgSobreMim1 from "../../Images/SobreMim/ImgSobreMim1.png";
import ImgSobreMim2 from "../../Images/SobreMim/ImgSobreMim2.png";
import ImgSobreMim3 from "../../Images/SobreMim/ImgSobreMim3.png";
import ImgWhats from "../../Images/SobreMim/ImgWhats.png";
import ImgBaixoSobreMim from "../../Images/SobreMim/ImgBaixoSobreMim.png";

function SobreMim(props) {
  const history = useHistory();
  const [Loading, setLoading] = useState(false);
  const [box, setBox] = useState(false);
  const { handleSubmit } = useForm();
  const [biografia, setBiografia] = useState();
  const [idade, setIdade] = useState();
  const [quadril, setQuadril] = useState();
  const [altura, setAltura] = useState();
  const [pes, setPes] = useState();
  const [peso, setPeso] = useState();
  const [data, setData] = useState([]);
  const [dataProduto, setDataProduto] = useState([]);

  useEffect(() => {
    getData(
      "/site/dados_iniciais" //url
    )
      .then((response) => {
        // setData(response);
        setBiografia(response.tb_biografia[0].c_texto);
      })
      .catch((err) => {});
  }, []);  
  
  useEffect(() => {
    getData(
      "/site/SobreMim" //url
    )
      .then((response) => {
        // setData(response);
        setIdade(response[0].c_idade);
        setQuadril(response[0].c_quadril);
        setAltura(response[0].c_altura);
        setPes(response[0].c_pes);
        setPeso(response[0].c_peso);
      })
      .catch((err) => {});
  }, []);  
  
  useEffect(() => {
    getData(
      "/site/Mimos" //url
    )
      .then((response) => {
        setData(response.tb_mimo);
        setDataProduto(response.tb_produto);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const MandaWhats = () => {
    window.location.href = "https://wa.me/5511952875123";
  };

  const MandaFotos = () => {
    // document.location = "/Fotos";
    history.push("/Fotos");
  };

  const MandaAtendimento = () => {
    // document.location = "/Atendimento";
    history.push("/Atendimento");
  };

  const MandaAreaPremium = () => {
    // document.location = "/AreaPremium";
    history.push("/AreaPremium");
  };

  return (
    <>
      <Header />

      <S.ForaBanner>
        <S.ImagemBanner src={ImgBanner} />
      </S.ForaBanner>

      <S.ForaBannerResp>
        <S.ImagemBanner src={ImgBannerResp} />
      </S.ForaBannerResp>

      <S.ForaConteudoSobreMim>
        <S.ForaImgLizzy>
          <S.ImagemLizzy src={ImgLizzy} />
        </S.ForaImgLizzy>

        <S.CimaSobreMim>
          <S.EsqCimaSobreMim>
            <S.TextCimaSobreMim1>
              Olá, sou a <span>Lizzy Sweet!</span>
            </S.TextCimaSobreMim1>
            <S.TextCimaSobreMim2>
               {biografia}
            </S.TextCimaSobreMim2>
          </S.EsqCimaSobreMim>

          <S.DirCimaSobreMim>
            <S.RiscoCimaSobreMim></S.RiscoCimaSobreMim>
            <S.ForaConteudoDirSobreMim>
              <S.CimaConteudoDirSobreMim>
                <S.ItemDirSobreMim className="ItemDirSobreMim">
                  <S.TextDirSobreMim1>Idade</S.TextDirSobreMim1>
                  <S.TextDirSobreMim2>{idade}</S.TextDirSobreMim2>
                </S.ItemDirSobreMim>
                <S.ItemDirSobreMim className="ItemDirSobreMim">
                  <S.TextDirSobreMim1>Quadril</S.TextDirSobreMim1>
                  <S.TextDirSobreMim2>{quadril}</S.TextDirSobreMim2>
                </S.ItemDirSobreMim>
              </S.CimaConteudoDirSobreMim>

              <S.BaixoConteudoDirSobreMim>
                <S.ItemDirSobreMim>
                  <S.TextDirSobreMim1>Altura</S.TextDirSobreMim1>
                  <S.TextDirSobreMim2>{altura}</S.TextDirSobreMim2>
                </S.ItemDirSobreMim>
                <S.ItemDirSobreMim>
                  <S.TextDirSobreMim1>Pés</S.TextDirSobreMim1>
                  <S.TextDirSobreMim2>{pes}</S.TextDirSobreMim2>
                </S.ItemDirSobreMim>
                <S.ItemDirSobreMim>
                  <S.TextDirSobreMim1>Peso</S.TextDirSobreMim1>
                  <S.TextDirSobreMim2>{peso}</S.TextDirSobreMim2>
                </S.ItemDirSobreMim>
              </S.BaixoConteudoDirSobreMim>
            </S.ForaConteudoDirSobreMim>
          </S.DirCimaSobreMim>
        </S.CimaSobreMim>

        <S.RiscoSobreMim></S.RiscoSobreMim>

        <S.ForaSugestoes>
          <S.CimaSugestoes>
            <S.ForaImgPresente>
              <S.ImagemPresente src={ImgPresente} />
            </S.ForaImgPresente>
            <S.TextSugestoes1>Sugestões de Mimos</S.TextSugestoes1>
            <S.ForaImgPresente className="Presente2">
              <S.ImagemPresente src={ImgPresente} />
            </S.ForaImgPresente>
          </S.CimaSugestoes>

          <S.ConteudoSugestoes>

          {data.map((res, index) => (
             <S.ItemSugestoes>
               <S.TextConteudoSugestoes1>{res.c_titulo}</S.TextConteudoSugestoes1>
               <S.TextConteudoSugestoes2>
                 {res.c_subtitulo}
               </S.TextConteudoSugestoes2>
              {dataProduto.map((res1, index) => (
                
              res.id_tb_mimo == res1.id_tb_mimo && (
              <S.ItemCoracao>
                <S.ForaImgCoracao>
                  <S.ImagemCoracao src={ImgCoracao} />
                </S.ForaImgCoracao>
                <S.TextCoracao>{res1.c_titulo}</S.TextCoracao>
              </S.ItemCoracao>  
               )
               ))}     
             </S.ItemSugestoes>
          ))}
          
          </S.ConteudoSugestoes>
        </S.ForaSugestoes>

        <S.Fora3Img>
          <S.ForaImgSobreMim onClick={() => MandaFotos()}>
            <S.ImagemSobreMim src={ImgSobreMim1} />
          </S.ForaImgSobreMim>
          <S.ForaImgSobreMim onClick={() => MandaAtendimento()}>
            <S.ImagemSobreMim src={ImgSobreMim2} />
          </S.ForaImgSobreMim>
          <S.ForaImgSobreMim
            onClick={() => MandaAreaPremium()}
            className="TerceiraImg"
          >
            <S.ImagemSobreMim src={ImgSobreMim3} />
          </S.ForaImgSobreMim>
        </S.Fora3Img>

        <S.ForaEntreContato>
          <S.TextEntreContato1>Entre em contato!</S.TextEntreContato1>
          <S.ForaBotaoEntreContato onClick={() => MandaWhats()}>
            <S.ForaImgWhats>
              <S.ImagemWhats src={ImgWhats} />
            </S.ForaImgWhats>
            <S.TextEntreContato2>
              +55 11 <span>95287-5123 </span>
            </S.TextEntreContato2>
          </S.ForaBotaoEntreContato>
        </S.ForaEntreContato>
      </S.ForaConteudoSobreMim>

      <S.ForaImgBaixoSobreMim>
        <S.ImagemBaixoSobreMim src={ImgBaixoSobreMim} />
      </S.ForaImgBaixoSobreMim>

      <Footer />
    </>
  );
}

export default SobreMim;
