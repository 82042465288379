import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as S from "./Estilos";
import coracao_pop from "../../Images/coracao_pop.png";
import { socketService } from "../../services/socketService";

function AlertPayment({ children }) {
  const history = useHistory();
  const [pagamentoAprovado, setPagamentoAprovado] = useState(false);
  useEffect(() => {
    socketService.on("connect", () => {
      console.log("connect", socketService.id);
    });

    socketService.on("disconnect", () => {
      console.log("disconnect", socketService.id);
      socketService.open();
    });

    socketService.on("PagamentoAprovado", function (data) {
      console.log("setPagamentoAprovado");
      setPagamentoAprovado(true);
    });
  }, []);

  const MandaAreaPremium = () => {
    setPagamentoAprovado(false);
    history.push("/AreaPremium");
  };

  return (
    <>
      {pagamentoAprovado && (
        <S.ForaHoverPagamento>
          <S.ForaResultadoPagamento>
            <S.ForaCoracaoPop>
              <S.ImagemCoracaoPop src={coracao_pop} />
            </S.ForaCoracaoPop>

            <S.TextResultadoPagamento1Apro>
              Pagamento Aprovado
            </S.TextResultadoPagamento1Apro>

            <S.TextResultadoPagamento2 onClick={() => MandaAreaPremium()}>
              área premium
            </S.TextResultadoPagamento2>
            <S.TextResultadoPagamento2
              onClick={() => setPagamentoAprovado(false)}
            >
              FECHAR
            </S.TextResultadoPagamento2>
          </S.ForaResultadoPagamento>
        </S.ForaHoverPagamento>
      )}
      {children}
    </>
  );
}

export default AlertPayment;
