import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as S from "./Estilos";
import Header from "../../Componentes/Header";
import Footer from "../../Componentes/Footer";

import ImgBanner from "../../Images/Fotos/Banner.jpg";
import ImgBannerResp from "../../Images/Fotos/BannerResp.jpg";
import ImgWhats from "../../Images/SobreMim/ImgWhats.png";
import ImgBaixoSobreMim from "../../Images/SobreMim/ImgBaixoSobreMim.png";
import ImgMinhasFotos from "../../Images/ImgMinhasFotos.png";
import { getData } from "../../utils/useData";
function Fotos(props) {
  const history = useHistory();
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [box, setBox] = useState(false);
  const { handleSubmit } = useForm();
  const page = useRef(1);
  const [foto, setFoto] = useState([]);
  const [AbreFoto, setAbreFoto] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    CarregaDados();
  }, []);

  function CarregaDados() {
    getData(
      "/site/fotos/" + page.current //url
    )
      .then((response) => {
        setData(response);
      })
      .catch((err) => {});
  }

  function CarregaMais() {
    page.current = page.current + 1;
    getData(
      "/site/fotos/" + page.current //url
    )
      .then((response) => {
        setData((data) => [...data, ...response]);
      })
      .catch((err) => {});
  }

  return (
    <>
      <Header />

      <S.ForaBanner>
        <S.ImagemBanner src={ImgBanner} />
      </S.ForaBanner>

      <S.ForaBannerResp>
        <S.ImagemBanner src={ImgBannerResp} />
      </S.ForaBannerResp>

      <S.BgMinhasFotos>
        <S.ForaMinhasFotos>
          <S.ForaGaleria>
            {data.map((res, index) => (
              <>
                <S.ForaFoto
                  onClick={() =>
                    setAbreFoto(
                      "https://lizzysweet.com.br/upload/foto/" +
                        res.id_tb_foto +
                        "a." +
                        res.c_extensao1 +
                        ""
                    )
                  }
                  style={{
                    backgroundImage:
                      "url('https://lizzysweet.com.br/upload/foto/" +
                      res.id_tb_foto +
                      "a." +
                      res.c_extensao1 +
                      "')",
                  }}
                ></S.ForaFoto>
              </>
            ))}
          </S.ForaGaleria>

          <S.MaisFotos
            onClick={() => {
              CarregaMais();
            }}
          >
            <S.TextMaisFotos>
              <span>+</span> fotos
            </S.TextMaisFotos>
          </S.MaisFotos>
        </S.ForaMinhasFotos>
      </S.BgMinhasFotos>

      {AbreFoto !== "" && (
        <>
          <S.BgImgMaior>
            <S.XImg onClick={() => setAbreFoto("")}>X</S.XImg>
            <S.ForaImgMaior>
              <S.ImgMaior src={AbreFoto} />
            </S.ForaImgMaior>
          </S.BgImgMaior>
        </>
      )}

      <S.ForaConteudoSobreMim>
        <S.ForaEntreContato>
          <S.TextEntreContato1>Entre em contato!</S.TextEntreContato1>
          <S.ForaBotaoEntreContato>
            <S.ForaImgWhats>
              <S.ImagemWhats src={ImgWhats} />
            </S.ForaImgWhats>
            <S.TextEntreContato2>
              +55 11 <span>95287-5123 </span>
            </S.TextEntreContato2>
          </S.ForaBotaoEntreContato>
        </S.ForaEntreContato>
      </S.ForaConteudoSobreMim>

      <S.ForaImgBaixoSobreMim>
        <S.ImagemBaixoSobreMim src={ImgBaixoSobreMim} />
      </S.ForaImgBaixoSobreMim>

      <Footer />
    </>
  );
}

export default Fotos;
