import io from "socket.io-client";

const url = `${process.env.REACT_APP_ENDPOINT}:${process.env.REACT_APP_SOCKET_PORT}`;

const socketService = io(url, {
  transports: ["websocket", "polling"],
  forceNew: true,
});

export { socketService };
