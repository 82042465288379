import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as S from "./Estilos";
import Header from "../../Componentes/Header";
import Footer from "../../Componentes/Footer";

import ImgBanner from "../../Images/Loja/Banner.jpg";
import ImgBannerResp from "../../Images/Loja/BannerResp.jpg";
import ImgWhats from "../../Images/SobreMim/ImgWhats.png";
import ImgBaixoSobreMim from "../../Images/SobreMim/ImgBaixoSobreMim.png";
import ImgConteudos from "../../Images/ImgConteudos.png";
import ImgItemConteudo1 from "../../Images/ImgItemConteudo1.png";
import { getData } from "../../utils/useData";
function Fotos(props) {
  const history = useHistory();
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [box, setBox] = useState(false);
  const { handleSubmit } = useForm();
  const page = useRef(1);

  useEffect(() => {
    window.scrollTo(0, 0);
    CarregaDados();
  }, []);

  function CarregaDados() {
    getData(
      "/site/loja/" + page.current //url
    )
      .then((response) => {
        setData(response);
      })
      .catch((err) => {});
  }

  function CarregaMais() {
    page.current = page.current + 1;
    getData(
      "/site/loja/" + page.current //url
    )
      .then((response) => {
        setData((data) => [...data, ...response]);
      })
      .catch((err) => {});
  }

  const MandaWhats = () => {
    window.location.href = "https://wa.me/5511952875123";
  };

  return (
    <>
      <Header />

      <S.ForaBanner>
        <S.ImagemBanner src={ImgBanner} />
      </S.ForaBanner>

      <S.ForaBannerResp>
        <S.ImagemBanner src={ImgBannerResp} />
      </S.ForaBannerResp>

      <S.BgConteudo>
        <S.ForaConteudo>
          <S.ForaItemConteudo>
            {data.map((res, index) => (
              <>
                <S.ItemConteudo>
                  <S.ForaImgConteudo
                   style={{
                     backgroundImage:
                       "url('https://lizzysweet.com.br/upload/loja/" +
                       res.id_tb_loja +
                       "a." +
                       res.c_extensao1 +
                       "')",
                   }}
                  >
                  </S.ForaImgConteudo>
                  <S.BaixoConteudo>
                    <S.ForaBaixoConteudo>
                      <S.TextConteudo3>{res.c_titulo}</S.TextConteudo3>
                      <S.TextConteudo4>• {res.c_descritivo1}</S.TextConteudo4>
                      <S.TextConteudo4>• {res.c_descritivo2}</S.TextConteudo4>
                      <S.TextConteudo5>
                        <span>R$</span> {res.c_valor}
                      </S.TextConteudo5>
                      <S.BotaoAdquirir onClick={() => MandaWhats()}>
                        Adquirir
                      </S.BotaoAdquirir>
                    </S.ForaBaixoConteudo>
                  </S.BaixoConteudo>
                </S.ItemConteudo>
              </>
            ))}
          </S.ForaItemConteudo>
        </S.ForaConteudo>
      </S.BgConteudo>

      <S.ForaConteudoSobreMim>
        <S.ForaEntreContato>
          <S.TextEntreContato1>Entre em contato!</S.TextEntreContato1>
          <S.ForaBotaoEntreContato onClick={() => MandaWhats()}>
            <S.ForaImgWhats>
              <S.ImagemWhats src={ImgWhats} />
            </S.ForaImgWhats>
            <S.TextEntreContato2>
              +55 11 <span>95287-5123 </span>
            </S.TextEntreContato2>
          </S.ForaBotaoEntreContato>
        </S.ForaEntreContato>
      </S.ForaConteudoSobreMim>

      <S.ForaImgBaixoSobreMim>
        <S.ImagemBaixoSobreMim src={ImgBaixoSobreMim} />
      </S.ForaImgBaixoSobreMim>

      <Footer />
    </>
  );
}

export default Fotos;
