import React, { useEffect, useState } from "react";
import * as S from "./Estilos";
import { Oval } from "react-loader-spinner";
function Load(props) {
  return (
    props.status && (
      <>
        <S.ForaSpiner>
          <Oval
            type="Puff"
            color="#310b55"
            height={100}
            width={100}
            secondaryColor="#c32f89"
            //timeout={3000} //3 secs
          />
        </S.ForaSpiner>
      </>
    )
  );
}

export default Load;
