import React, { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./Estilos";
import ViewerContext from "../../context/context";
import ImgLogoTopo from "../../Images/Logo.png";
import ImgIconeTopo1 from "../../Images/IconeTopo1.png";
import ImgIconeTopo2 from "../../Images/IconeTopo2.png";
import IconeTopo2Logo from "../../Images/IconeTopo2Logo.png";
import ImgIconeTopo3 from "../../Images/IconeTopo3.png";
import BotaoMobile from "../../Images/BotaoMobile.png";
import ImgMenuMobile from "../../Images/ImgMenuMobile.png";
import ImgAreaPremiumMenu from "../../Images/ImgAreaPremiumMenu.png";
import LogoMenuMobile from "../../Images/LogoMenuMobile.png";
import ImgBotaoMobile from "../../Images/ImgBotaoMobile.png";
import ImgBotaoWhats from "../../Images/ImgBotaoWhats.png";
import ImgPopIdade1 from "../../Images/ImgPopIdade1.png";
import ImgPopParabens1 from "../../Images/ImgPopParabens1.png";
import IconeCodigo from "../../Images/IconeCodigo.png";
import { getAssinatura } from "../../utils/getAssinatura";
function PopUp(props) {
  const [Menu, setMenu] = useState(0);
  const [PopIdade, setPopIdade] = useState(false);
  const [PopCookies, setPopCookies] = useState(false);
  const [PopParabens, setPopParabens] = useState(0);

  useEffect(() => {
    if (
      localStorage.getItem("idade") === "false" ||
      localStorage.getItem("idade") === null
    ) {
      setPopIdade(true);
    } else {
      if (
        localStorage.getItem("cookies") === "false" ||
        localStorage.getItem("cookies") === null
      ) {
        setPopCookies(true);
      }
    }
  }, []);

  const AbreCookie = () => {
    setMenu(1);
  };

  return (
    <>
      <link rel="stylesheet" href="fontes/stylesheet.css" />
      {PopIdade && (
        <S.FundoPopUp>
          <S.PopIdade>
            <S.ForaPopIdade>
              <S.ForaImgIdade>
                <S.ImagemIdade src={ImgPopIdade1} />
              </S.ForaImgIdade>
              <S.TextPopIdade1>
                Este site exige que você tenha 18 anos ou mais para entrar
              </S.TextPopIdade1>
              <S.TextPopIdade2>Você tem mais de 18 anos?</S.TextPopIdade2>
              <S.BotaoSim
                onClick={() => {
                  localStorage.setItem("idade", "true");
                  setPopIdade(false);
                  setPopCookies(true);
                }}
              >
                Sim, tenho!
              </S.BotaoSim>
              <S.BotaoNao
                onClick={() => {
                  document.location = "https://www.gov.br/pf/pt-br";
                }}
              >
                não
              </S.BotaoNao>
            </S.ForaPopIdade>
          </S.PopIdade>
        </S.FundoPopUp>
      )}

      {PopCookies && (
        <S.FundoPopUp2>
          <S.PopIdade2>
            <S.ForaPopIdade2>
              {/* <S.TextPopCookie2>Configuração de Cookies</S.TextPopCookie2> */}
              <S.TextPopCookie3>
              <span>Configuração de Cookies</span> Eles são usados para aprimorar a sua experiência. Ao fechar este banner ou continuar na página, você concorda com o uso de cookies. Saber mais
              </S.TextPopCookie3>
              <S.BotaoSimCookie
                onClick={() => {
                  localStorage.setItem("cookies", "true");
                  setPopCookies(false);
                }}
              >
                Aceitar
              </S.BotaoSimCookie>
            </S.ForaPopIdade2>
          </S.PopIdade2>
        </S.FundoPopUp2>
      )}

      {PopParabens == 1 ? (
        <S.FundoPopUp>
          <S.PopIdade>
            <S.ForaPopIdade>
              <S.ForaImgIdade>
                <S.ImagemIdade src={ImgPopParabens1} />
              </S.ForaImgIdade>
              <S.TextPopCadastroMaior>Parabéns!</S.TextPopCadastroMaior>
              <S.TextPopCadastro1>
                Cadastro efetuado com sucesso.
              </S.TextPopCadastro1>

              <S.ForaQuadradoParabens>
                <S.QuadradoParabens></S.QuadradoParabens>
              </S.ForaQuadradoParabens>
              <S.TextPopCadastro2>ADJASIDASJDASIDPAA</S.TextPopCadastro2>

              <S.ForaCopiarCodigo>
                <S.ForaImgCopiarCodigo>
                  <S.ImagemCopiarCodigo src={IconeCodigo} />
                </S.ForaImgCopiarCodigo>
                <S.TextCopiarCodigo>Copiar Código</S.TextCopiarCodigo>
              </S.ForaCopiarCodigo>
              <S.TextPopCadastro3>
                Scanei ou copie o código acima.
              </S.TextPopCadastro3>
            </S.ForaPopIdade>
          </S.PopIdade>
        </S.FundoPopUp>
      ) : (
        <></>
      )}
    </>
  );
}

export default withRouter(PopUp);
