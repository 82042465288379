import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Clappr from "clappr";
import PlaybackRatePlugin from "clappr-playback-rate-plugin";
import ClapprMarkersPlugin from "clappr-markers-plugin";
import * as S from "./Estilos";
import Header from "../../Componentes/Header";
import Footer from "../../Componentes/Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Load from "../../Componentes/Load";
import ImgBanner from "../../Images/AreaPremium/Banner.jpg";
import IconeWhats from "../../Images/ImgWhats.png";
import ImgPremiumInterna from "../../Images/AreaPremium/ImgPremiumInterna.jpg";
import ImgBannerResp from "../../Images/AreaPremium/BannerResp.jpg";
import ImgBaixoSobreMim from "../../Images/SobreMim/ImgBaixoSobreMim.png";
import ImgSeta from "../../Images/AreaPremium/SetaFiltro.png";
import { getAssinatura } from "../../utils/getAssinatura";
import { getData } from "../../utils/useData";
import { Rowing } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import CoracaoPop from "../../Images/coracao_pop.png";
import coracao_pop from "../../Images/coracao_pop.png";
import { AlertAssine } from "../../Componentes/Funcoes";
import "../../indexPremium.css";

function AreaPremium(props) {
  const history = useHistory();
  const [assine, setAssine] = useState(false);
  const [visible, setVisible] = useState(false);
  const [load, setLoad] = useState(true);
  const [video, setVideo] = useState("");
  const [titulo, setTitulo] = useState("");
  const [iv, setIv] = useState("");
  const [idGaleria, setIdGaleria] = useState("");
  const [myKey, setMyKey] = useState(0);
  const [data, setData] = useState([]);
  const [fotos, setFotos] = useState([]);
  const [categoria, setCategoria] = useState([]);
  const page = useRef(1);
  const id = useRef(0);
  var settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const hash = props.history.location.hash;
    if (hash && document.getElementById(hash.substr(1))) {
      // Check if there is a hash and if an element with that id exists
      document
        .getElementById(hash.substr(1))
        .scrollIntoView({ behavior: "smooth" });
    }
    // }, []);
  }, [props.history.location.hash]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function CarregaDados() {
      getData(
        "/site/galeria/"+page.current+"/"+ id.current
        // "/site/galeriafelipe/1/0"
      )
        .then((response) => {
          setFotos([]);
          setData(response);
          setLoad(false);
        })
        .catch((err) => {});
  }

  function CarregaMais() {
    page.current = page.current + 1;
    setLoad(true);
    getData(
      "/site/galeria/" + page.current + "/" + id.current //url
    )
      .then((response) => {
        setLoad(false);
        setData((data) => [...data, ...response]);
      })
      .catch((err) => {});
  }  
  
  function AbreGaleria(x) {
    setLoad(true);
    getData(
      "/site/fotosgaleria/"+x
    )
      .then((response) => {
        setFotos(response);
        setLoad(false);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    getData(
      "/site/dados_iniciais" //url
    )
      .then((response) => {
        // setData(response);
        setCategoria(response.tb_categoria);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    CarregaDados();
   
    async function fetchMyAPI() {
      
      if (localStorage.getItem("logged") === "true") {
        const valida = await getAssinatura(localStorage.getItem("id_tb_user"));
        if (valida === true) {
          setVisible(true);
          CarregaDados();
        } else {
          setLoad(false);
          setVisible(true);
          // setAssine(true);
          //history.push("/MinhaConta");
        }
      } else {
        history.push("/Login");
      }
    }
    fetchMyAPI();
  }, [history]);

  const MandaCadastro = () => {
    document.location = "/MinhaConta";
  };
  const MandaWhats = () => {
    window.location.href = "https://wa.me/5511952875123";
  };  
  
  const MandaVideos = () => {
    history.push("/AreaPremium");
  };

  return (
    <>
      <Header />
      <Load status={load} />
      {assine && (
        <S.ForaHoverPagamento>
          <S.ForaResultadoPagamento>
            <S.ForaCoracaoPop>
              <S.ImagemCoracaoPop src={coracao_pop} />
            </S.ForaCoracaoPop>

            <S.TextResultadoPagamento1Apro>
              Você não possui uma assinatura ativa.{"\n"}<br/>
              Clique abaixo para Assinar / Renovar sua assinatura
            </S.TextResultadoPagamento1Apro>    
      
            <S.TextResultadoPagamento2
              onClick={() => {
                setAssine(false);
                history.push("/Assinaturas");
              }}
            >
              CONTINUAR
            </S.TextResultadoPagamento2>
          </S.ForaResultadoPagamento>
        </S.ForaHoverPagamento>
      )}
      <div id="video_player">
        <>
          {!video && (
            <S.BgBanner>
              <S.ForaBanner>
                <S.ImagemBanner src={ImgBanner} />
              </S.ForaBanner>
            </S.BgBanner>
          )}
          ;
          {/* { <S.ForaImgInterna>
          
            { <S.ImgInterna src={ImgPremiumInterna} /> }
            <div>
              {!!video && (
                <ClapprComponent
                  id="video"
                  source={video}
                  width={"100%"}
                  // height={"auto"}
                  mute={false}
                />
              )}
            </div>
          </S.ForaImgInterna> } */}
          {!!video && (
            <video
              class="VideoResp"
              key={myKey}
              controls
              width="100%"
              loop=""
              autoplay=""
              height="auto"
            >
              <source src={video} type="video/mp4" />
              <source src="movie.ogg" type="video/ogg" />
            </video>
          )}
          {video !== "" && (
            <S.BaixoVideo>
              <S.ForaBaixoVideo>
                <S.TituloVideo>{titulo}</S.TituloVideo>
                <S.BotaoRodape onClick={() => MandaWhats()}>
                  <S.ForaImgRodape>
                    <S.ImgRodape src={IconeWhats} />
                  </S.ForaImgRodape>
                  <S.TextBotaoRodape>
                    <span>+55 11</span> 95287-5123
                  </S.TextBotaoRodape>
                </S.BotaoRodape>
              </S.ForaBaixoVideo>
            </S.BaixoVideo>
          )}
          {!video && (
            <S.ForaBannerResp>
              <S.ImagemBanner src={ImgBannerResp} />
            </S.ForaBannerResp>
          )}
          ;
          {visible && (
            <S.ForaExclusivo>
              <S.TextExclusivo1>Conteúdo Exclusivo</S.TextExclusivo1>
              <S.ForaFiltro>
                <S.ItemFiltro>
                  <select
                    id="InputSelect"
                    name="select"
                    style={{ outline: "none" }}
                    onChange={(e) => {
                      id.current = e.target.value;
                      page.current = 1;
                      setLoad(true);
                      CarregaDados();
                    }}
                  >
                  <option 
                    onChange={(e) => {
                      id.current = 0;
                      page.current = 1;
                      setLoad(true);
                      CarregaDados();
                    }}
                    selected value={0}>
                      Todos os Conteúdos
                    </option>
                    {categoria.map((res, index) => (
                      <>
                        <option value={res.id_tb_categoria}>
                          {res.c_titulo}
                        </option>
                      </>
                    ))}
                  </select>
                </S.ItemFiltro>

                  <S.BotaoTrocaPagina onClick={() => {MandaVideos();}}>Ver Vídeos</S.BotaoTrocaPagina>
              </S.ForaFiltro>

              <S.ConteudoExclusivo>
                {data.map((res, index) => (
                  <>
                    <S.ItemPremiumExclusivo
                      onClick={() => {
                        setIv(res.iv);
                        AbreGaleria(res.id_tb_galeria);
                      }}
                    >
                      <S.QuadradoGaleria   
                         style={{
                           backgroundImage:
                             "url('https://lizzysweet.com.br/upload/photo/" +
                             res.iv +
                             "/" +
                             res.id_tb_galeria_foto +
                             "_thumb" +
                             res.c_extensao +
                             "')",
                         }}            
                      >
                      
                      </S.QuadradoGaleria>
                      <S.TextExclusivo2>{res.c_titulo}</S.TextExclusivo2>
                    </S.ItemPremiumExclusivo>
                  </>
                ))}
              </S.ConteudoExclusivo>             
              {fotos.length == 0 ? (
                <></>
              ) : (
                  <S.FundoImgGaleria>
                      <S.XImg  onClick={() => {
                        //setIv(res.iv);
                        setFotos([]);
                      }}>X</S.XImg>
                      <S.ConteudoExclusivo>
                        <Slider className="SlickFotos" {...settings2}>
                          {fotos.map((res, index) => (
                            <>
                                {res.size == "largura" ? (
                                   <S.ItemPremiumExclusivo2
                                       onClick={() => {
                                       }}
                                     >
                                       <S.ForaImgGaleria>
                                         <S.ImgGaleria src={'https://lizzysweet.com.br/upload/photo/'+iv+'/'+res.id_tb_galeria_foto+res.c_extensao} />
                                       </S.ForaImgGaleria>
                                    </S.ItemPremiumExclusivo2>
                                ) : (
                                  <S.ItemPremiumExclusivo2Altura
                                    onClick={() => {
                                    }}
                                  >
                                    <S.ForaImgGaleria>
                                      <S.ImgGaleria src={'https://lizzysweet.com.br/upload/photo/'+iv+'/'+res.id_tb_galeria_foto+res.c_extensao} />
                                    </S.ForaImgGaleria>
                                </S.ItemPremiumExclusivo2Altura>
                                )}
                              
                            </>
                          ))}
                        </Slider>
                      </S.ConteudoExclusivo>
                </S.FundoImgGaleria>
              )}


              <div className="ForaBotaoCarregar" style={{ marginTop: "10px" }}>
                <button className="BotaoCarregar" onClick={() => CarregaMais()}>
                  Carregar mais Fotos
                </button>
              </div>
            </S.ForaExclusivo>
          )}
        </>
      </div>
      <S.ForaImgBaixoSobreMim id="ForaImgBaixoSobreMim">
        <S.ImagemBaixoSobreMim src={ImgBaixoSobreMim} />
      </S.ForaImgBaixoSobreMim>

      <Footer />
    </>
  );
}

const ClapprComponent = ({ id, source, mute, height, width }) => {
  let clappr_player = null;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    clappr_player = new Clappr.Player({
      parentId: `#${id}`,
      source: source,
      mute,
      autoPlay: true,
      height,
      width,
      plugins: [PlaybackRatePlugin, ClapprMarkersPlugin],
      markersPlugin: {
        markers: [
          new ClapprMarkersPlugin.StandardMarker(0, "The beginning!"),
          new ClapprMarkersPlugin.StandardMarker(5, "Something interesting."),
          new ClapprMarkersPlugin.StandardMarker(9, "The conclusion."),
        ],
        tooltipBottomMargin: 17, // optional
      },
      playbackRateConfig: {
        defaultValue: 1,
        options: [
          { value: 0.5, label: "0.5x" },
          { value: 1, label: "1x" },
          { value: 2, label: "2x" },
        ],
        // rateSuffix: 'x',
      },
    });

    clappr_player.getPlugin("markers-plugin");

    return () => {
      clappr_player.destroy();
      clappr_player = null;
    };
  }, [source, mute, height, width]);

  return (
    <div>
      <p id={id}></p>
    </div>
  );
};

export default AreaPremium;
