import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as S from "./Estilos";
import Header from "../../Componentes/Header";
import Footer from "../../Componentes/Footer";
import { getData, postData } from "../../utils/useData";

import ImgBanner from "../../Images/Atendimento/Banner.jpg";
import ImgBannerResp from "../../Images/Atendimento/BannerResp.jpg";
import ImgCertinho from "../../Images/Atendimento/Certinho.png";
import ImgAtendimento from "../../Images/Atendimento/ImgAtendimento.png";
import ImgItemAtendimento1 from "../../Images/Atendimento/ImgItemAtendimento1.png";
import ImgItemAtendimento2 from "../../Images/Atendimento/ImgItemAtendimento2.png";
import ImgWhats from "../../Images/SobreMim/ImgWhats.png";
import ImgBaixoSobreMim from "../../Images/SobreMim/ImgBaixoSobreMim.png";

function SobreMim(props) {
  const history = useHistory();
  const [Loading, setLoading] = useState(false);
  const [box, setBox] = useState(false);
  const { handleSubmit } = useForm();
  const [data, setData] = useState([]);
  const [dataAtendimento, setDataAtendimento] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getData(
      "/site/Cache" //url
    )
      .then((response) => {
        setData(response.tb_cache);
        setDataAtendimento(response.tb_atendimento);
      })
      .catch((err) => {});
  }, []); 

  const MandaWhats = () => {
    window.location.href = "https://wa.me/5511952875123";
  };

  return (
    <>
      <Header />

      <S.ForaBanner>
        <S.ImagemBanner src={ImgBanner} />
      </S.ForaBanner>

      <S.ForaBannerResp>
        <S.ImagemBanner src={ImgBannerResp} />
      </S.ForaBannerResp>

      <S.ForaCimaAtendimento>
        <S.ForaImgAtendimento>
          <S.ImagemAtendimento src={ImgAtendimento} />
        </S.ForaImgAtendimento>
        <S.ForaDirAtendimento>
          <S.TextAtendimento1>Meu Atendimento</S.TextAtendimento1>

          <S.ItemAtendimento>
            <S.TextAtendimento2>O que eu faço:</S.TextAtendimento2>
            <S.ForaCertinho>
              <S.ForaImgCertinho>
                <S.ImagemCertinho src={ImgCertinho} />
              </S.ForaImgCertinho>
              <S.TextCertinho>Atendimento convencional (oral e vaginal), podendo ser o oral sem preservativo com finalização</S.TextCertinho>
            </S.ForaCertinho>
            <S.ForaCertinho>
              <S.ForaImgCertinho>
                <S.ImagemCertinho src={ImgCertinho} />
              </S.ForaImgCertinho>
              <S.TextCertinho>Sexo anal (avise com antecedência)</S.TextCertinho>
            </S.ForaCertinho>
            <S.ForaCertinho>
              <S.ForaImgCertinho>
                <S.ImagemCertinho src={ImgCertinho} />
              </S.ForaImgCertinho>
              <S.TextCertinho>Massagem relaxante (tenho curso em Relaxamento muscular)</S.TextCertinho>
            </S.ForaCertinho>
          </S.ItemAtendimento>

          <S.ItemAtendimento>
            <S.TextAtendimento2>Restrições:</S.TextAtendimento2>
            <S.ForaCertinho>
              <S.ForaImgCertinho>
                <S.ImagemCertinho src={ImgCertinho} />
              </S.ForaImgCertinho>
              <S.TextCertinho>Não realizo penetração sem preservativo</S.TextCertinho>
            </S.ForaCertinho>
            <S.ForaCertinho>
              <S.ForaImgCertinho>
                <S.ImagemCertinho src={ImgCertinho} />
              </S.ForaImgCertinho>
              <S.TextCertinho>Não atendo casais/mulheres</S.TextCertinho>
            </S.ForaCertinho>
            <S.ForaCertinho>
              <S.ForaImgCertinho>
                <S.ImagemCertinho src={ImgCertinho} />
              </S.ForaImgCertinho>
              <S.TextCertinho>Não realizo fetiches de que não compartilho.</S.TextCertinho>
            </S.ForaCertinho>
          </S.ItemAtendimento>
        </S.ForaDirAtendimento>

        <S.ForaImgAtendimento className="ImgAtendimentoResp">
          <S.ImagemAtendimento src={ImgAtendimento} />
        </S.ForaImgAtendimento>
      </S.ForaCimaAtendimento>

      <S.BgCache>
        <S.ForaCache>
          <S.TextCache1>Meu Cachê</S.TextCache1>
          <S.ForaItensCache>

          {data.map((res, index) => (
            <S.ItemCache>
              <S.RiscoRosa></S.RiscoRosa>
              <S.ForaConteudoItemCache>
                <S.TextCache2>{res.c_hora}</S.TextCache2>
                <S.TextCache3>
                  <span></span> {res.c_valor1}
                </S.TextCache3>
                <S.TextCache4>{res.c_valor2}</S.TextCache4>
                <S.TextCache4>{res.c_valor3}</S.TextCache4>
                <S.TextCache4>{res.c_valor4}</S.TextCache4>
                <S.TextCacheValido>
                  {res.c_valor_adicional}
                </S.TextCacheValido>
                <S.RiscoCache></S.RiscoCache>
                <S.TextCache5>{res.c_descricao1}</S.TextCache5>
                <S.TextCache5>{res.c_descricao2}</S.TextCache5>
                <S.TextCache5>{res.c_descricao3}</S.TextCache5>
                <S.RiscoCache></S.RiscoCache>
                <S.TextCache6>
                  {res.c_pagamento1}
                </S.TextCache6>
                <S.TextCache7>
                  {res.c_pagamento2}
                </S.TextCache7>
              </S.ForaConteudoItemCache>
              <S.TextAgende onClick={() => MandaWhats()}>
                AGENDE JÁ
              </S.TextAgende>
            </S.ItemCache>
          ))}      
          </S.ForaItensCache>
        </S.ForaCache>
      </S.BgCache>

      <S.ForaAtendimento>
        <S.TextLocalAtendimento1>Local de Atendimento</S.TextLocalAtendimento1>
        <S.ConteudoAtendimento>
        {dataAtendimento.map((res1, index) => (
           <S.ItemLocalAtendimento>
             <S.RiscoAtendimento
               style={{ backgroundColor: "#491d74" }}
             ></S.RiscoAtendimento>
             <S.DentroItemAtendimento>
               <S.CimaDentroAtendimento>
                 <S.ForaImgDentroAtendimento>
                   <S.ImagemDentroAtendimento src={ImgItemAtendimento1} />
                 </S.ForaImgDentroAtendimento>
                 <S.TextDentroAtendimento>{res1.c_texto1}</S.TextDentroAtendimento>
               </S.CimaDentroAtendimento>
               <S.TextItemAtendimento1>
                  {res1.c_texto2}
               </S.TextItemAtendimento1>
             </S.DentroItemAtendimento>
           </S.ItemLocalAtendimento>
        ))}  

        </S.ConteudoAtendimento>
      </S.ForaAtendimento>

      <S.ForaConteudoSobreMim>
        <S.ForaEntreContato>
          <S.TextEntreContato1>Entre em contato!</S.TextEntreContato1>
          <S.ForaBotaoEntreContato onClick={() => MandaWhats()}>
            <S.ForaImgWhats>
              <S.ImagemWhats src={ImgWhats} />
            </S.ForaImgWhats>
            <S.TextEntreContato2>
              +55 11 <span>95287-5123 </span>
            </S.TextEntreContato2>
          </S.ForaBotaoEntreContato>
        </S.ForaEntreContato>
      </S.ForaConteudoSobreMim>

      <S.ForaImgBaixoSobreMim>
        <S.ImagemBaixoSobreMim src={ImgBaixoSobreMim} />
      </S.ForaImgBaixoSobreMim>

      <Footer />
    </>
  );
}

export default SobreMim;
