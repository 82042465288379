import React, { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./Estilos";
import ViewerContext from "../../context/context";
import ImgLogoTopo from "../../Images/Logo.png";
import ImgIconeTopo1 from "../../Images/IconeTopo1.png";
import ImgIconeTopo2 from "../../Images/IconeTopo2.png";
import IconeTopo2Logo from "../../Images/IconeTopo2Logo.png";
import ImgIconeTopo3 from "../../Images/IconeTopo3.png";
import BotaoMobile from "../../Images/BotaoMobile.png";
import ImgMenuMobile from "../../Images/ImgMenuMobile.png";
import ImgAreaPremiumMenu from "../../Images/ImgAreaPremiumMenu.png";
import LogoMenuMobile from "../../Images/LogoMenuMobile.png";
import ImgBotaoMobile from "../../Images/ImgBotaoMobile.png";
import ImgBotaoWhats from "../../Images/ImgBotaoWhats.png";
import ImgPopIdade1 from "../../Images/ImgPopIdade1.png";
import ImgPopParabens1 from "../../Images/ImgPopParabens1.png";
import IconeCodigo from "../../Images/IconeCodigo.png";
import { getAssinatura } from "../../utils/getAssinatura";
import PopUp from "../PopUp";
import { LocalDiningOutlined } from "@material-ui/icons";

function Header(props) {
  const [Menu, setMenu] = useState(0);
  const [PopIdade, setPopIdade] = useState(0);
  const [PopCookies, setPopCookies] = useState(0);
  const [PopParabens, setPopParabens] = useState(0);
  const history = useHistory();
  const [context, setContext] = useContext(ViewerContext);

  useEffect(() => {
    // const hash = props.history.location.hash;
    // if (hash && document.getElementById(hash.substr(1))) {
    //   // Check if there is a hash and if an element with that id exists
    //   document
    //     .getElementById(hash.substr(1))
    //     .scrollIntoView({ behavior: "smooth" });
    // }
  }, [props.history.location.hash]);

  const AbreMenu = () => {
    setMenu(1);
  };

  const FechaMenu = () => {
    setMenu(0);
  };

  const MandaWhats = () => {
    window.open("https://wa.me/5511952875123", "_blank");
  };

  const MandaLogin = () => {
    // document.location = "/lizzy/Login";
    history.push("/Login");
  };

  const MandaCadastro = () => {
    // document.location = "/lizzy/MinhaConta";
    history.push("/#Cadastrese");
  };

  const MandaPremium = () => {
    // document.location = "/lizzy/MinhaConta";
    history.push("/AreaPremium");
  };

  const RedirectMenu1 = () => {
    // document.location = "/";
    history.push("/");
    setMenu(0);
  };

  const RedirectMenu2 = () => {
    // document.location = "/lizzy/#BgSobreMim";
    history.push("/#BgSobreMim");

    setMenu(0);
  };

  const RedirectMenu3 = () => {
    // document.location = "/lizzy/#BgMinhasFotos";
    history.push("/#BgMinhasFotos");
    setMenu(0);
  };

  const RedirectMenu4 = () => {
    // document.location = "/lizzy/#BgDiario";
    history.push("/#BgDiario");

    setMenu(0);
  };

  const RedirectMenu5 = () => {
    // document.location = "/lizzy/#BgConteudo";
    history.push("/#BgConteudo");
    setMenu(0);
  };

  const RedirectMenu6 = () => {
    // document.location = "/lizzy/#BgFeedback";
    history.push("/#BgFeedback");
    setMenu(0);
  };

  const RedirectMenu7 = () => {
    // document.location = "/lizzy/Atendimento";
    history.push("/Atendimento");
    setMenu(0);
  };

  const RedirectBlog = () => {
    //document.location = "https://lizzysweet.wixsite.com/model/blog";
    window.open("https://lizzysweet.wixsite.com/model/blog", "_blank");
    // history.push("/Atendimento");
    setMenu(0);
  };

  const RedirectRestrito = () => {
    // if (localStorage.getItem("logged") === "true") {
    //   if (getAssinatura(localStorage.getItem("id_tb_user"))) {
    //     history.push("/AreaPremium");
    //   } else {
    //     history.push("/AssinaturaVencida");
    //   }
    // } else {
    //   history.push("/Login");
    // }

    // setMenu(0);
    history.push("/AreaPremium");
  };

  const RedirectLogin = () => {
    history.push("/Login");
  };

  const Logout = () => {
    try {
      props.retorno(true);
    } catch (e) {
      console.log("Error");
    }

    localStorage.removeItem("logged");
    localStorage.removeItem("id_tb_user");
    localStorage.removeItem("c_email");
    localStorage.removeItem("token");
    history.push("/");
  };

  if (props.tipo === "Cadastro") {
    return (
      <>
        <link rel="stylesheet" href="fontes/stylesheet.css" />
        <S.BgTopoCadastro>
          <PopUp />
          <S.ForaTopo>
            <S.MenuResp onClick={() => AbreMenu()}>
              <S.ImgMenuResp src={BotaoMobile} />
            </S.MenuResp>
            <S.ForaLogoTopo onClick={() => RedirectMenu1()}>
              <S.LogoTopo src={ImgLogoTopo} />
            </S.ForaLogoTopo>

            <S.MenuTopo>
              <S.TextMenuTopo onClick={() => RedirectMenu2()}>
                Sobre Mim
              </S.TextMenuTopo>
              <S.TextMenuTopo onClick={() => RedirectMenu3()}>
                Fotos
              </S.TextMenuTopo>
              {/*
              <S.TextMenuTopo onClick={() => RedirectMenu4()}>
                Diário
              </S.TextMenuTopo>
               
              <S.TextMenuTopo onClick={() => RedirectMenu5()}>
                Avulsos
              </S.TextMenuTopo>
              */}
              <S.TextMenuTopo onClick={() => RedirectMenu6()}>
                FeedBacks
              </S.TextMenuTopo>
              {/*
              <S.TextMenuTopo onClick={() => RedirectMenu7()}>
                Presencial
              </S.TextMenuTopo>
              */}
              <S.TextMenuTopo onClick={() => RedirectRestrito()}>
                Vídeos
              </S.TextMenuTopo>
              <S.TextMenuTopo onClick={() => RedirectBlog()}>
                Blog
              </S.TextMenuTopo>
            </S.MenuTopo>

            {localStorage.getItem("logged") === "true" ? (
              <S.DireitaTopo>
                <S.IconeDireitaTopo onClick={() => MandaWhats()}>
                  <S.IconeTopo src={ImgIconeTopo1} />
                </S.IconeDireitaTopo>
                <S.IconeDireitaTopo
                  onClick={() => MandaPremium()}
                  className="IconeDireitaTopo2"
                >
                  <S.IconeTopo src={IconeTopo2Logo} />
                </S.IconeDireitaTopo>

                <div>
                  {/* <S.DivDireita1
                    onClick={() => {
                      history.push("/MinhaConta");
                    }}
                  >
                    {localStorage.getItem("c_nome")}
                  </S.DivDireita1> */}
                  {/* <S.DivDireita1
                    onClick={() => {
                      history.push("/MinhaConta");
                    }}
                  >
                    {localStorage.getItem("c_nome")}
                  </S.DivDireita1> */}
                  <S.DivDireita2
                    onClick={() => {
                      history.push("/MinhaConta");
                    }}
                  >
                    Minha conta
                  </S.DivDireita2>
                  <S.DivDireita3
                    onClick={() => {
                      Logout();
                    }}
                  >
                    Logout
                  </S.DivDireita3>
                </div>
              </S.DireitaTopo>
            ) : (
              <S.DireitaTopo>
                <S.IconeDireitaTopo onClick={() => MandaWhats()}>
                  <S.IconeTopo src={ImgIconeTopo1} />
                </S.IconeDireitaTopo>
                <S.IconeDireitaTopo
                  onClick={() => MandaCadastro()}
                  className="IconeDireitaTopo2"
                >
                  <S.IconeTopo src={ImgIconeTopo2} />
                </S.IconeDireitaTopo>
                <S.IconeDireitaTopo
                  onClick={() => RedirectLogin()}
                  className="IconeDireitaTopo2"
                >
                  <S.IconeTopo src={ImgIconeTopo3} />
                </S.IconeDireitaTopo>
              </S.DireitaTopo>
            )}
          </S.ForaTopo>
        </S.BgTopoCadastro>

        {Menu == 1 ? (
          <>
            <S.MenuMobile>
              <S.ForaMenuMobile>
                <S.CimaMenuMobile>
                  <S.ForaImgMenuMobille>
                    <S.ImagemMenuMobile src={ImgMenuMobile} />
                  </S.ForaImgMenuMobille>
                  <S.ForaLogoMenuMobille onClick={() => RedirectMenu1()}>
                    <S.ImagemLogoMobile src={ImgLogoTopo} />
                  </S.ForaLogoMenuMobille>
                  <S.FechaMenu onClick={() => FechaMenu()}>X</S.FechaMenu>
                </S.CimaMenuMobile>

                <S.BotaoMobile onClick={() => MandaLogin()}>
                  <S.ForaImgBotaoMobile>
                    <S.ImagemBotaoMobile src={ImgBotaoMobile} />
                  </S.ForaImgBotaoMobile>
                  <S.TextBotaoMobile>entrar</S.TextBotaoMobile>
                </S.BotaoMobile>

                <S.TextMenuMobile onClick={() => RedirectMenu2()}>
                  Sobre Mim
                </S.TextMenuMobile>
                <S.TextMenuMobile onClick={() => RedirectMenu3()}>
                  Fotos
                </S.TextMenuMobile>
                {/* 
                <S.TextMenuMobile onClick={() => RedirectMenu4()}>
                  Diário
                </S.TextMenuMobile>
                
                <S.TextMenuMobile onClick={() => RedirectMenu5()}>
                  Avulsos
                </S.TextMenuMobile>
                */}
                <S.TextMenuMobile onClick={() => RedirectMenu6()}>
                  FeedBacks
                </S.TextMenuMobile>
                {/*
                <S.TextMenuMobile onClick={() => RedirectMenu7()}>
                  Presencial
                </S.TextMenuMobile>
                */}
                <S.TextMenuMobile onClick={() => RedirectRestrito()}>
                  Área Premium
                </S.TextMenuMobile>
                <S.TextMenuMobile onClick={() => RedirectBlog()}>
                  Blog
                </S.TextMenuMobile>

                <S.ForaImgBotaoWhats onClick={() => MandaWhats()}>
                  <S.ImagemBotaoWhats src={ImgBotaoWhats} />
                </S.ForaImgBotaoWhats>
              </S.ForaMenuMobile>
            </S.MenuMobile>
          </>
        ) : (
          <></>
        )}
      </>
    );
  } else {
    return (
      <>
        <link rel="stylesheet" href="fontes/stylesheet.css" />
        <S.BgTopo>
          <PopUp />
          <S.ForaTopo>
            <S.MenuResp onClick={() => AbreMenu()}>
              <S.ImgMenuResp src={BotaoMobile} />
            </S.MenuResp>
            <S.ForaLogoTopo onClick={() => RedirectMenu1()}>
              <S.LogoTopo src={ImgLogoTopo} />
            </S.ForaLogoTopo>

            <S.MenuTopo>
              <S.TextMenuTopo onClick={() => RedirectMenu2()}>
                Sobre Mim
              </S.TextMenuTopo>
              <S.TextMenuTopo onClick={() => RedirectMenu3()}>
                Fotos
              </S.TextMenuTopo>
              {/* 
              <S.TextMenuTopo onClick={() => RedirectMenu4()}>
                Diário
              </S.TextMenuTopo>
              
              <S.TextMenuTopo onClick={() => RedirectMenu5()}>
                Avulsos
              </S.TextMenuTopo>
              */}
              <S.TextMenuTopo onClick={() => RedirectMenu6()}>
                FeedBacks
              </S.TextMenuTopo>
              {/*
              <S.TextMenuTopo onClick={() => RedirectMenu7()}>
                Presencial
              </S.TextMenuTopo>
              */}
              <S.TextMenuTopo onClick={() => RedirectRestrito()}>
                Vídeos
              </S.TextMenuTopo>
              <S.TextMenuTopo
                onClick={() => {
                  RedirectBlog();
                }}
              >
                Blog
              </S.TextMenuTopo>
            </S.MenuTopo>

            {localStorage.getItem("logged") === "true" ? (
              <S.DireitaTopo>
                <S.IconeDireitaTopo onClick={() => MandaWhats()}>
                  <S.IconeTopo src={ImgIconeTopo1} />
                </S.IconeDireitaTopo>
                <S.IconeDireitaTopo
                  onClick={() => MandaPremium()}
                  className="IconeDireitaTopo2"
                >
                  <S.IconeTopo src={IconeTopo2Logo} />
                </S.IconeDireitaTopo>

                <div>
                  {/* <S.DivDireita1
                    onClick={() => {
                      history.push("/MinhaConta");
                    }}
                  >
                    {localStorage.getItem("c_nome")}
                  </S.DivDireita1> */}
                  {/* <S.DivDireita1
                    onClick={() => {
                      history.push("/MinhaConta");
                    }}
                  >
                    {localStorage.getItem("c_nome")}
                  </S.DivDireita1> */}
                  <S.DivDireita2
                    onClick={() => {
                      history.push("/MinhaConta");
                    }}
                  >
                    Minha conta
                  </S.DivDireita2>
                  <S.DivDireita3
                    onClick={() => {
                      Logout();
                    }}
                  >
                    Logout
                  </S.DivDireita3>
                </div>
              </S.DireitaTopo>
            ) : (
              <S.DireitaTopo>
                <S.IconeDireitaTopo onClick={() => MandaWhats()}>
                  <S.IconeTopo src={ImgIconeTopo1} />
                </S.IconeDireitaTopo>
                <S.IconeDireitaTopo
                  onClick={() => MandaCadastro()}
                  className="IconeDireitaTopo2"
                >
                  <S.IconeTopo src={ImgIconeTopo2} />
                </S.IconeDireitaTopo>
                <S.IconeDireitaTopo
                  onClick={() => RedirectLogin()}
                  className="IconeDireitaTopo2"
                >
                  <S.IconeTopo src={ImgIconeTopo3} />
                </S.IconeDireitaTopo>
              </S.DireitaTopo>
            )}
          </S.ForaTopo>
        </S.BgTopo>

        {Menu == 1 ? (
          <>
            <S.MenuMobile>
              <S.ForaMenuMobile>
                <S.CimaMenuMobile>
                  <S.ForaImgMenuMobille>
                    <S.ImagemMenuMobile src={ImgMenuMobile} />
                  </S.ForaImgMenuMobille>
                  <S.ForaLogoMenuMobille onClick={() => RedirectMenu1()}>
                    <S.ImagemLogoMobile src={ImgLogoTopo} />
                  </S.ForaLogoMenuMobille>
                  <S.FechaMenu onClick={() => FechaMenu()}>X</S.FechaMenu>
                </S.CimaMenuMobile>

                <S.BotaoMobile onClick={() => MandaLogin()}>
                  <S.ForaImgBotaoMobile>
                    <S.ImagemBotaoMobile src={ImgBotaoMobile} />
                  </S.ForaImgBotaoMobile>
                  <S.TextBotaoMobile>entrar</S.TextBotaoMobile>
                </S.BotaoMobile>

                <S.TextMenuMobile onClick={() => RedirectMenu2()}>
                  Sobre Mim
                </S.TextMenuMobile>
                <S.TextMenuMobile onClick={() => RedirectMenu3()}>
                  Fotos
                </S.TextMenuMobile>
                {/* 
                <S.TextMenuMobile onClick={() => RedirectMenu4()}>
                  Diário
                </S.TextMenuMobile>
               
                <S.TextMenuMobile onClick={() => RedirectMenu5()}>
                  Avulsos
                </S.TextMenuMobile>
                 */}
                <S.TextMenuMobile onClick={() => RedirectMenu6()}>
                  FeedBacks
                </S.TextMenuMobile>
                {/* 
                <S.TextMenuMobile onClick={() => RedirectMenu7()}>
                  Presencial
                </S.TextMenuMobile>
                */}
                <S.TextMenuMobile onClick={() => RedirectRestrito()}>
                  Vídeos
                </S.TextMenuMobile>
                <S.TextMenuMobile onClick={() => RedirectBlog()}>
                  Blog
                </S.TextMenuMobile>

                <S.ForaImgBotaoWhats onClick={() => MandaWhats()}>
                  <S.ImagemBotaoWhats src={ImgBotaoWhats} />
                </S.ForaImgBotaoWhats>
              </S.ForaMenuMobile>
            </S.MenuMobile>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default withRouter(Header);
