import styled from 'styled-components';

import ImgBgCadastro from "../../Images/Cadastro/BgCadastro.jpg";

export const ForaSpiner = styled.div`
    color: rgb(0, 0, 0);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.8);
    z-index: 999;
`;


export const BgCadastro = styled.div`
    background-image: url(${ImgBgCadastro});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`;

export const ForaCadastro = styled.div`
    width: 1250px;
    margin: 0 auto;
    padding-top: 85px;

    @media (max-width: 1300px) {
        width: 900px;
        padding-top: 35px;
    } 

    @media (max-width: 900px) {
        width: 90%;
    } 
`;

export const ForaImgTextCadastro = styled.div`
    width: 650px;
    margin: 0 auto;

    @media (max-width: 740px) {
        width: 410px;
    } 

    @media (max-width: 480px) {
        width: 90%;
        margin: 0 auto;
    } 
`;

export const ImagemTextCadastro = styled.img`

`;

export const TextCadastro1 = styled.div`
    font-family: 'Lato';
    font-size: 37px;
    font-weight: 900;
    color: #491d74;
    font-style: italic;
    text-transform: uppercase;
    text-align: center;
    padding-top: 30px;

    @media (max-width: 740px) {     
        font-size: 32px;
        padding-top: 10px;
    } 

    @media (max-width: 480px) {
        font-size: 25px;
        padding-top: 5px;
    } 
`;



export const ForaItensPremium = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 55px;
    width: 1255px;
    margin: 0 auto;
    padding-bottom: 110px;

    @media (max-width: 1300px) {
        width: 900px;
        padding-bottom: 60px;
    } 

    @media (max-width: 900px) {
        width: 100%;
        flex-direction: column;
        padding-top: 30px;
    } 

    @media (max-width: 500px) {
        width: 100%;
        margin: 0 auto;
    } 
`;

export const ItemPremium = styled.div`
    width: 31%;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;

    @media (max-width: 1175px) {
       width: 32.5%;
    } 

    @media (max-width: 900px) {
       width: 94%;
       display: flex;
       margin-top: 16px;
       align-items: center;
       padding: 25px;
       justify-content: space-between;
    } 

    @media (max-width: 795px) {
        padding-bottom: 35px;
        width: 90%;
        margin: 0 auto;
    } 

    @media (max-width: 650px) {
       flex-wrap: wrap;
       padding-bottom: 45px;
    } 

    @media (max-width: 500px) {
        padding: 0px;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 40px;
        border-radius: 12px;
    } 

`;

export const ForaImgItemPremium = styled.div`
    width: 85%;
    margin: 0 auto;
    margin-top: -88px;

    @media (max-width: 900px) {
        display: none;
    } 
`;

export const ForaImgItemPremiumResp = styled.div`
    display: none;

    @media (max-width: 900px) {
        display: block;
    } 

    @media (max-width: 795px) {
        width: 130px;
    } 

    @media (max-width: 500px) {
        width: 160px;
    } 
`;

export const ForaImgItemPremiumResp2 = styled.div`
    @media (max-width: 650px) {
        width: 100%;
        display: flex;
        justify-content: center;
    } 
`;

export const ImagemItemPremium = styled.img`

`;

export const ForaTextItemPremium = styled.div`
    @media (max-width: 500px) {
        width: 100%;
    } 
`;

export const TextItemPremium1 = styled.div`
    font-family: 'Lato';
    font-size: 37px;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    margin-top: 30px;

    @media (max-width: 900px) {
        margin-top: 0px;
        font-size: 30px;
    } 
`;

export const TextItemPremium2 = styled.div`
    font-family: 'Lato';
    font-size: 37px;
    font-weight: 900;
    color: #fff;
    text-align: center;

    span {
        font-weight: 400;
    }
`;

export const TextItemPremium3 = styled.div`
    font-family: 'Lato';
    font-size: 13px;
    text-align: center;
    text-transform: uppercase;
`;

export const ForaConteudoItemPremium = styled.div`
    width: 63%;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;

    @media (max-width: 900px) {
        margin: unset;
        height: 128px;
        width: 284px;
    } 

    @media (max-width: 795px) {
        height: 115px;
        width: 236px;
    }

    @media (max-width: 500px) {
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 0px;
    } 
`;

export const TextConteudoItemPremium = styled.div`
    font-family: 'Lato';
    font-size: 15px;
    line-height: 22px;

    @media (max-width: 900px) {
        font-size: 18px;
        line-height: 25px;
    } 

    @media (max-width: 795px) {
        font-size: 15px;
        line-height: 22px;
    } 
`;

export const ForaAssineJa = styled.div`
    display: flex;
    width: 130px;
    height: 40px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    border-radius: 11px;
    margin-bottom: 30px;

    :hover {
        cursor: pointer;
        background-image: linear-gradient(#ff63d8, #aa3d8f);
    }

    @media (max-width: 900px) {
        display: none;
    } 
`;

export const ForaAssineJa2 = styled.div`
    display: none;
    width: 130px;
    height: 40px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    border-radius: 11px;
    margin-bottom: 30px;

    :hover {
        cursor: pointer;
        background-image: linear-gradient(#ff63d8, #aa3d8f);
    }

    @media (max-width: 900px) {
        display: flex;
        background-image: linear-gradient(#ff63d8, #aa3d8f);
        margin-top: -21px;
    } 
`;

export const ForaImgAssineJa = styled.div`
    margin-top: 3px;
    margin-right: 5px;
`;

export const ImgAssineJa = styled.img`

`;

export const TextAssineJa = styled.div`
    font-family: 'Lato';
    font-size: 15px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
`;

export const TextCobrado = styled.div`
    font-family: 'Lato';
    font-size: 13px;
    text-align: center;
    padding-bottom: 50px;

    @media (max-width: 900px) {
        display: none;
    } 
`;

export const TextCobrado2 = styled.div`
    font-family: 'Lato';
    font-size: 13px;
    padding-bottom: 50px;
    display: none;

    @media (max-width: 900px) {
        display: block;
        font-size: 15px;
        padding-top: 10px;
    } 

    @media (max-width: 795px) {
        padding-top: 8px;
    } 
`;




export const ForaImgBaixoSobreMim = styled.div`
    margin-top: -48px;
    margin-bottom: -95px;

    @media (max-width: 670px) {
        margin-bottom: -35px;
    } 

    @media (max-width: 450px) {
        margin-bottom: -25px;
    } 
`;

export const ImagemBaixoSobreMim = styled.img`

`;


/* FORM FORM FORM FORM FORM */

export const ForaBotaoEditaCadastro = styled.div`
    display: flex;
    width: 815px;
    margin: 0 auto;
    justify-content: space-between;
    margin-top: 40px;
    flex-wrap: wrap;
    padding-bottom: 70px;

    @media (max-width: 860px) {
        width: 590px;
    } 

    @media (max-width: 640px) {
        width: 88%;
    } 

    @media (max-width: 480px) {
        margin-top: 20px;
    } 
`;

export const BotaoEditaCadastro = styled.div`
    background-color: #df59aa;
    color: #fff;
    width: 48%;
    font-weight: 600;
    font-family: 'lato',sans-serif;
    font-size: 22px;
    text-transform: uppercase;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    cursor: pointer;

    @media (max-width: 640px) {
        font-size: 17px;
        width: 49%;
        height: 40px;
    } 

    @media (max-width: 480px) {
        width: 100%;
        margin-top: 10px;
    } 
`;






export const ForaAssinaturas = styled.div`
    width: 815px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: -40px;
    padding-bottom: 90px;

    @media (max-width: 860px) {
        width: 590px;
    } 

    @media (max-width: 640px) {
        width: 88%;
    } 
`;

export const BotaoRenovar = styled.div`
    color: #fff;
    font-family: 'lato',sans-serif;
    font-size: 22px;
    font-weight: bold;
    background-color: #df59aa;
    width: 300px;
    margin: 0 auto;
    text-transform: uppercase;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    margin-bottom: 30px;
    cursor: pointer;
`;

export const verBoleto = styled.div`
    color: #fff;
    font-family: 'lato',sans-serif;
    font-size: 18px;
    font-weight: bold;
    background-color: #df59aa;
    width: 300px;
    margin: 0 auto;
    text-transform: uppercase;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    margin-top: 15px;
    cursor: pointer;
`;

export const ItemAssinatura = styled.div`
    width: 45%;
    margin-top: 30px;
    border-bottom: 1px solid #cf499a;
    padding-bottom: 35px;

    @media (max-width: 860px) {
        width: 100%;
        margin-top: 20px;
        padding-bottom: 20px;
    } 
`;

export const TextAssinatura1 = styled.div`
    color: #cf499a;
    font-family: 'lato',sans-serif;
    font-size: 22px;
    font-weight: bold;
    padding-top: 5px;
`;

export const AssinaturaBaixo = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
`;

export const TextAssinatura2 = styled.div`
    color: #8b8b8b;
    font-family: 'lato',sans-serif;
    font-size: 22px;

    @media (max-width: 640px) {
        font-size: 19px;
    } 
`;








export const ForaFormCadastro = styled.div`
    width: 815px;
    margin: 0 auto;
    margin-top: -45px;

    @media (max-width: 860px) {
        width: 590px;
    } 

    @media (max-width: 640px) {
        width: 88%;
    } 
`;

export const TextFormCadastro1 = styled.div`
    font-family: 'Lato';
    font-size: 18px;
    color: #d04a9b;
    text-transform: uppercase;
    margin-bottom: 30px;

    span {
        font-size: 18px;
        color: #491d74;
        font-weight: bold;
    }

    @media (max-width: 500px) {
        margin-bottom: 20px;
        font-size: 16px;
    } 
`;

export const ItemForm = styled.div`
    margin-top: 17px;
`;

export const ItemForm2 = styled.div`
    margin-top: 17px;
    display: flex;
`;

export const TextItemForm = styled.div`
    font-family: 'Lato';
    font-size: 16px;
    color: #491d74;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 8px;
`;

export const ContainerCompra = styled.div`
   
`;

export const TextItemForm2 = styled.div`
    font-family: 'Lato';
    font-size: 16px;
    color: #491d74;
    padding-left: 5px;

    a {
        text-decoration: underline;
        cursor: pointer;
    }
`;

export const BgPremium = styled.div`
  background-color: #a73b7e;
`;



export const ForaImgPremium = styled.div`
  width: 100%;
  margin: 0 auto;
  z-index: 99;
  position: relative;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const ForaImgPremium2 = styled.div`
  width: 820px;
  margin: 0 auto;
  margin-top: -125px;

  @media (max-width: 900px) {
    width: 600px;
    margin-top: -110px;
  }

  @media (max-width: 685px) {
    width: 100%;
    margin-top: -15vw;
  }
`;

export const ForaImgPremiumResp = styled.div`
  width: 100%;
  margin: 0 auto;
  z-index: 99;
  position: relative;
  display: none;

  @media (max-width: 900px) {
    display: block;
  }

  @media (max-width: 685px) {
    display: block;
  }
`;

export const ForaPremium = styled.div`
  width: 1490px;
  margin: 0 auto;
  padding-bottom: 190px;

  @media (max-width: 1500px) {
    width: 1140px;
    padding-bottom: 155px;
  }

  @media (max-width: 1175px) {
    width: 880px;
  }

  @media (max-width: 900px) {
    width: 90%;
    padding-bottom: 55px;
  }
`;

export const ImagemPremium = styled.img`
  margin-top: -484px;

  @media (max-width: 1500px) {
    margin-top: -370px;
  }

  @media (max-width: 1175px) {
    margin-top: -285px;
  }

  @media (max-width: 900px) {
    margin-top: -28.3vw;
  }
`;

export const ImgPremium2 = styled.img``;

export const TextPremium2 = styled.div`
  color: #461c70;
  font-family: "Lato";
  font-size: 25px;
  font-style: italic;
  font-weight: 900;
  text-align: center;
`;


export const ItemPagamento = styled.div`
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  width: 100px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;

export const ImgItemPagamento = styled.img``;

export const ColunaPagamento = styled.div`
  flex-direction: row;
  width: auto;
  display: flex;
`;


export const TextPremium3 = styled.div`
  font-family: "Lato";
  font-size: 15px;
  text-align: center;
  width: 790px;
  margin: 0 auto;
  line-height: 25px;
  margin-top: 20px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;