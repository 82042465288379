import styled from 'styled-components';

import ImgBgCadastro from "../../Images/Cadastro/BgCadastro.jpg";


export const ForaImgBaixoSobreMim = styled.div`
    margin-top: -48px;
    margin-bottom: -95px;

    @media (max-width: 670px) {
        margin-bottom: -35px;
    } 

    @media (max-width: 450px) {
        margin-bottom: -25px;
    } 
`;

export const ImagemBaixoSobreMim = styled.img`

`;

export const ForaTermos = styled.div`
    width: 950px;
    margin: 0 auto;

    @media (max-width: 1000px) {
        width: 720px;
    } 

    @media (max-width: 770px) {
        width: 90%;
    } 
`;

export const TextTermos1 = styled.div`
    font-family: 'Lato';
    font-size: 60px;
    color: #491d74;
    font-weight: bold;
    font-style: italic;
    text-align: center;
    padding-top: 70px;

    @media (max-width: 1000px) {  
        padding-bottom: 30px;
    } 

    @media (max-width: 770px) {
        padding-bottom: 20px;
        padding-top: 35px;
    } 

    @media (max-width: 500px) {
        font-size: 46px;
        padding-bottom: 10px;
        padding-top: 20px;
    } 
`;

export const TextTermos2 = styled.div`
    font-family: 'Lato';
    font-size: 18px;
    color: #696969;
    padding-top: 30px;
    line-height: 26px;
    padding-bottom: 15px;

    @media (max-width: 1000px) {
        padding-top: 10px;
    } 

    @media (max-width: 500px) {
        line-height: 21px;
        padding-bottom: 6px;
        font-size: 16px;
    } 
`;

export const BotaoCadastro = styled.div`
    font-family: 'Lato';
    font-size: 30px;
    color: #696969;
    background-color: #d04a9b;
    width: 440px;
    height: 80px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-transform: uppercase;
    margin: 0 auto;
    margin-top: 35px;
    margin-bottom: 100px;
    cursor: pointer;

    @media (max-width: 500px) {
        font-size: 25px;
        width: 270px;
        height: 60px;
        margin-top: 15px;
        margin-bottom: 80px;
    } 
`;
