import React, { useEffect, useState, useContext, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as S from "./Estilos";
import Header from "../../Componentes/Header/";
import Load from "../../Componentes/Load";
import Footer from "../../Componentes/Footer/";
import { confirmAlert } from "react-confirm-alert";
import { getCard, getCardFlag, getCardToken } from "../../utils/getCard";
import coracao_pop from "../../Images/coracao_pop.png";
import {
  AlertForm,
  AlertFormPagamentoPixx,
  AlertFormPagamentoBoleto,
} from "../../Componentes/Funcoes";
import { Oval } from "react-loader-spinner";
import CoracaoPop from "../../Images/coracao_pop.png";
import ViewerContext from "../../context/context";
import { getAssinatura } from "../../utils/getAssinatura";
import ImgBaixoSobreMim from "../../Images/SobreMim/ImgBaixoSobreMim.png";
import ImgTextCadastro from "../../Images/Cadastro/ImgTextCadastro.png";
import ImgCoracao from "../../Images/ImgCoracao.png";
import ImgItemPremium from "../../Images/ImgItemPremium.png";
import ImgItemPremium2 from "./../../Images/ImgItemPremium2.png";
import ImgAreaPremium2 from "../../Images/ImgAreaPremium2.png";
import ImgItemPremium3 from "../../Images/ImgItemPremium3.png";
import ImgItemPremiumResp from "./../../Images/ImgItemPremiumResp.png";
import ImgItemPremiumResp2 from "../../Images/ImgItemPremiumResp2.png";
import ImgItemPremiumResp3 from "../../Images/ImgItemPremiumResp3.png";
import { getData, postData } from "../../utils/useData";
import Snack from "../../Componentes/Snack";

import ImgPremium from "../../Images/ImgPremium.png";
import ImgPremiumResp from "../../Images/ImgPremiumResp.png";
import BoletoLogo from "../../Images/boleto-logo.png";
import CreditLogo from "../../Images/credit-logo.png";
import PixLogo from "../../Images/pix-logo.png";
import { socketService } from "../../services/socketService";
function Cadastro(props) {
  const history = useHistory();
  const [context, setContext] = useContext(ViewerContext);
  const [mensagem, setMensagem] = useState("");
  const [pagamento, setPagamento] = useState(false);
  const [status, setStatus] = useState(false);
  const [load, setLoad] = useState(true);
  const [box, setBox] = useState(false);
  const { handleSubmit } = useForm();
  const [cardNumber, setCardNumber] = useState("");
  const [cardholderName, setCardholderName] = useState("");
  const [expirationMonth, setExpirationMonth] = useState("");
  const [expirationYear, setExpirationYear] = useState("");
  const [code, setCode] = useState("");
  const [celular, setCelular] = useState("");
  const [identificationNumber, setIdentificationNumber] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [senha, setSenha] = useState("");
  const [editaCadastro, setEditaCadastro] = useState(0);
  const [assinaturas, setAssinaturas] = useState(0);
  const [valor, setValor] = useState("");
  const [checkbox, setCheckbox] = useState("");
  const [qrVisible, setQrVisible] = useState(false);
  const [qrcodebase64, setQrcodebase64] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [data, setData] = useState([]);
  const [mostraBotao, setMostraBotao] = useState(false);
  const [payment_method_id, setPayment_method_id] = useState("");
  const [payment_type_id, setPayment_type_id] = useState("");
  const [issuer, setIssuer] = useState([]);
  const [blocos, setBlocos] = useState(false);
  const [statusQr, setStatusQr] = useState(false);
  const [qr_code, setQr_code] = useState(false);
  const [qr_link, setQr_link] = useState(false);

  const [pagamentoAprovado, setPagamentoAprovado] = useState(0);
  const [pagamentoRecusado, setPagamentoRecusado] = useState(0);
  const [textPagamento, setTextPagamento] = useState("");

  const page = useRef(1);
  const id = useRef(0);

  useEffect(() => {
    setLoad(true);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    socketService.on("connect", () => {
      console.log("connect", socketService.id);
    });

    socketService.on("disconnect", () => {
      console.log("disconnect", socketService.id);
      socketService.open();
    });

    socketService.on("PagamentoAprovado", function (data) {
      setStatusQr(false);
    });
  }, []);

  useEffect(() => {}, [props.history.location.hash]);

  useEffect(() => {
    if (cardNumber.length === 9) {
      getCard(cardNumber)
        .then((response) => {
          if (Object.keys(response.results).length === 1) {
            getCardFlag(cardNumber)
              .then((response2) => {
                setPayment_method_id(response2[0].payment_method_id);
                setPayment_type_id(response2[0].payment_type_id);
                setIssuer(response2[0].issuer);
              })
              .catch(() => {
                console.log("erro");
              });
          } else {
            alert("cartao invalido");
          }
        })
        .catch(() => {
          console.log("erro");
        });
    }
  }, [cardNumber]);

  useEffect(() => {
    async function fetchMyAPI() {
      const valida = await getAssinatura(localStorage.getItem("id_tb_user"));
      if (valida === true) {
        setMostraBotao(false);
      } else {
        setMostraBotao(true);
      }
      Pagamentos();
    }

    fetchMyAPI();
  }, []);

  function formatReal(int) {
    var tmp = int;
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

    return tmp;
  }

  const Pagamentos = async () => {
    const dataPost = JSON.stringify({
      id_tb_user: localStorage.getItem("id_tb_user"),
      page: page.current,
    });

    const response = await postData("/pagamento/pagamentos", dataPost);
    setData(response);
    setLoad(false);
  };

  const onSubmitCompra = async () => {
    setLoad(true);

    if (
      context.tipo_assinatura === "" ||
      context.tipo_assinatura === undefined
    ) {
      AlertForm("Selecione um plano acima antes de continuar!");
      setLoad(false);
      return;
    }

    if (checkbox === "" || checkbox === undefined) {
      AlertForm("Aceite os termos de uso antes de continuar!");
      setLoad(false);
      return;
    }

    if (context.forma_pagamento === 2) {
      if (cardholderName === "" || cardholderName === undefined) {
        AlertForm("O campo Nome do cartão é de preenchimento obrigatório!");
        setLoad(false);
        return;
      }

      if (cardNumber === "" || cardNumber === undefined) {
        AlertForm("O campo Número do cartão é de preenchimento obrigatório!");
        setLoad(false);
        return;
      }

      if (identificationNumber === "" || identificationNumber === undefined) {
        AlertForm(
          "O campo CPF do proprietário do cartão é de preenchimento obrigatório!"
        );
        setLoad(false);
        return;
      }

      if (expirationMonth === "" || expirationMonth === undefined) {
        AlertForm("O campo mês do cartão é de preenchimento obrigatório!");
        setLoad(false);
        return;
      }

      if (expirationYear === "" || expirationYear === undefined) {
        AlertForm("O campo Ano do cartão é de preenchimento obrigatório!");
        setLoad(false);
      }

      if (code === "" || code === undefined) {
        AlertForm(
          "O campo Código de segurança do cartão é de preenchimento obrigatório!"
        );
        setLoad(false);
        return;
      }
    }

    if (checkbox !== "" && checkbox !== undefined) {
      setLoad(true);
      console.log("context.forma_pagamento", context.forma_pagamento);
      console.log("cardholderName", cardholderName);
      let data;

      if (context.forma_pagamento === 2) {
        data = JSON.stringify({
          id_tb_user: localStorage.getItem("id_tb_user"),
          c_tipo: context.forma_pagamento,
          c_plano: context.tipo_assinatura,
          c_card_cpf: identificationNumber,
          c_valor: context.valor_assinatura,
          c_nome_cartao: cardholderName,
          c_card_number: cardNumber,
          c_card_month: expirationMonth,
          c_card_year: expirationYear,
          c_code: code,
          payment_method_id: payment_method_id,
          payment_type_id: payment_type_id,
          issuer: issuer,
        });
      } else {
        data = JSON.stringify({
          id_tb_user: localStorage.getItem("id_tb_user"),
          c_tipo: context.forma_pagamento,
          c_card_cpf: identificationNumber,
          c_plano: context.tipo_assinatura,
          c_valor: context.valor_assinatura,
          c_nome_cartao: cardholderName,
          c_card_number: cardNumber,
          c_card_month: expirationMonth,
          c_card_year: expirationYear,
          c_code: code,
        });
      }
      console.log("context.valor_assinatura", context.valor_assinatura);

      const response = await postData(
        "/pagamento/cria_pagamento_mercado_user",
        data
      );

      setContext({
        ...context,
        tipo_assinatura: "",
        valor_assinatura: 0,
        logged: true,
        id_tb_user: response.id_tb_user,
      });
      if (response.tipo === "duplicado") {
        setLoad(false);
        AlertForm(
          "E-mail já cadastrado no sistema, por favor efetue login para prosseguir!"
        );
      } else {
        localStorage.setItem("logged", "true");
        localStorage.setItem("id_tb_user", response.id_tb_user);
        localStorage.setItem("c_email", email);
        localStorage.setItem("c_nome", nome);

        if (context.forma_pagamento === 0) {
          setStatusQr(true);
          setQr_code(response.qr_code_base64);
          setQr_link(response.qr_code);
          // AlertFormPagamentoPixRenova(
          //   response.qr_code_base64,
          //   response.qr_code
          // );
          //setStatus(true);
        } else if (context.forma_pagamento === 1) {
          AlertFormPagamentoBoleto(response.url_boleto);
          //setStatus(true);
        } else if (context.forma_pagamento === 2) {
          if (response.status === "approved") {
            setPagamentoAprovado(1);
            setTextPagamento(response.status_detail);
          } else {
            setTextPagamento(response.status_detail);
            setPagamentoRecusado(1);
          }
          setLoad(false);

          // alert(response.status_detail + " " + response.status);
        }
        setBlocos(false);
        // setNome("");
        // setEmail("");
        // setCpf("");
        // setSenha("");
        // setCheckbox("");

        setLoad(false);
      }
    }
  };

  const AbreCadastro = () => {
    history.push("/Cadastro");
  };

  const AbreAssinaturas = () => {
    history.push("/Assinaturas");
  };

  const MandaAreaPremium = () => {
    history.push("/AreaPremium");
  };

  const MandaMinhaConta = () => {
    history.push("/MinhaConta");
  };
  const MandaAssinaturas = () => {
    history.push("/Assinaturas");
  };

  const MandaWhats = () => {
    window.location.href = "https://wa.me/5511952875123";
  };

  const MandaTermosDeUso = () => {
    window.open("https://lizzysweet.com.br/TermosDeUso", "_blank");
  };

  const MandaCadastro = (tipo, valor) => {
    setContext({
      ...context,
      tipo_assinatura: tipo,
      valor_assinatura: valor,
    });
    // document
    //   .getElementById("FormularioCadastro")
    //   .scrollIntoView({ behavior: "smooth" });

    // //document.location = "/Cadastro#FormularioCadastro";
  };

  const MudaPagamento = async (tipo) => {
    setContext({
      ...context,
      forma_pagamento: tipo,
    });
  };

  const cpfMask = (value) => {
    return value
      .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  };

  const cartaoMask = (value) => {
    return value
      .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{4})(\d)/, "$1 $2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{4})(\d)/, "$1 $2")
      .replace(/(\d{4})(\d{1,2})/, "$1 $2")
      .replace(/(\d{4})(\d{1,2})/, "$1 $2");
  };

  const phoneMask = (value) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d{4})/, "$1-$2");
  };

  return (
    <>
      <Snack mensagem={mensagem} status={status} />
      <Load status={load} />
      <Header tipo={"Cadastro"} />
      {statusQr && (
        <div
        style={{
          width: "100%",
          top: "0px",
          position: "fixed",
          zIndex: 100,
          height: "100%",
          display: "flex",
          alignItems: "center",
          // paddingTop: "3vw"
        }}
        >
          <div
            className="AlertaJs"
            style={{
              zIndex: 10000,
              backgroundColor: "#FFFFFF",
            }}
          >
            <div style={{}}>
              <img
                src={CoracaoPop}
                alt=""
                style={{
                  width: "100px",
                  height: "103px",
                }}
              ></img>
            </div>
            <p>Parabéns!</p>
            <p>
              Cadastro efetuado com sucesso, por favor efetue o pagamento
              abaixo.
            </p>
            <div>
              <img
                src={`data:image/png;base64,${qr_code}`}
                style={{ width: "250px" }}
                alt=""
              />
            </div>
            <div style={{ width: "100%" }}>
              <p className="TextPix">{qr_link}</p>
            </div>
            <button
              className="BotaoAcessarBD"
              onClick={() => navigator.clipboard.writeText(qr_link)}
            >
              COPIAR CÓDIGO
            </button>
            <button
              className="BotaoAcessarBD"
              onClick={() => {
                setStatusQr(false);
                // onClose();
              }}
            >
              FECHAR
            </button>
          </div>
        </div>
      )}
      {pagamentoAprovado === 1 && (
        <S.ForaHoverPagamento>
          <S.ForaResultadoPagamento>
            <S.ForaCoracaoPop>
              <S.ImagemCoracaoPop src={coracao_pop} />
            </S.ForaCoracaoPop>

            <S.TextResultadoPagamento1Apro>
              {textPagamento}
            </S.TextResultadoPagamento1Apro>

            <S.TextResultadoPagamento2 onClick={() => MandaAreaPremium()}>
              área premium
            </S.TextResultadoPagamento2>
            <S.TextResultadoPagamento2 onClick={() => setPagamentoAprovado(0)}>
              FECHAR
            </S.TextResultadoPagamento2>
          </S.ForaResultadoPagamento>
        </S.ForaHoverPagamento>
      )}

      {pagamentoRecusado === 1 && (
        <S.ForaHoverPagamento>
          <S.ForaResultadoPagamento>
            <S.ForaCoracaoPop>
              <S.ImagemCoracaoPop src={coracao_pop} />
            </S.ForaCoracaoPop>
            <S.TextResultadoPagamento1>
              {textPagamento}
            </S.TextResultadoPagamento1>
            <S.TextResultadoPagamento2 onClick={() => setPagamentoRecusado(0)}>
              FECHAR
            </S.TextResultadoPagamento2>
          </S.ForaResultadoPagamento>
        </S.ForaHoverPagamento>
      )}
      <S.ForaBotaoEditaCadastro>
        <S.BotaoEditaCadastro onClick={() => AbreCadastro(1)}>
          Editar Cadastro
        </S.BotaoEditaCadastro>
        <S.BotaoEditaCadastro
          onClick={() => {
            setLoad(true);
            async function fetchMyAPI() {
              const valida = await getAssinatura(
                localStorage.getItem("id_tb_user")
              );
              if (valida === true) {
                setMostraBotao(false);
              } else {
                setMostraBotao(true);
              }
              Pagamentos();
            }

            fetchMyAPI();
            Pagamentos();
          }}
        >
          Minhas assinaturas
        </S.BotaoEditaCadastro>
      </S.ForaBotaoEditaCadastro>
      <>
        {
          //ESTE TEM QUE FICAR DESATIVADO ATÉ O PAGSEGURO HOMOLOGAR
          mostraBotao && (
            <S.BotaoRenovar
              href="#Cadastrese"
              onClick={() => {
                setPagamento(true);
              }}
            >
              assinar / renovar
            </S.BotaoRenovar>
          )
        }

        {pagamento && (
          <S.ContainerCompra>
            <S.BgPremium>
              <S.ForaPremium>
                <S.ForaImgPremium>
                  <div id="Cadastrese"></div>
                </S.ForaImgPremium>

                <S.TextPremium2>Conteúdo exclusivos e quentes!</S.TextPremium2>
                <S.TextPremium3>
                  Assine agora mesmo e tenha acesso aos meus conteúdos
                  exclusivos criados especialmente para você. Fotos, vídeos e
                  outros conteúdos multimídia. Os conteúdos são atualizados com
                  frequência.
                </S.TextPremium3>
                <S.ForaItensPremium>
                  <S.ItemPremium style={{ backgroundColor: "#c44693" }}>
                    <S.ForaImgItemPremium>
                      <S.ImagemItemPremium src={ImgItemPremium} />
                    </S.ForaImgItemPremium>

                    <S.ForaImgItemPremiumResp2>
                      <S.ForaImgItemPremiumResp>
                        <S.ImagemItemPremium src={ImgItemPremiumResp} />
                      </S.ForaImgItemPremiumResp>
                    </S.ForaImgItemPremiumResp2>

                    <S.ForaTextItemPremium>
                      <S.TextItemPremium1 style={{ color: "#491d74" }}>
                        Mensal
                      </S.TextItemPremium1>
                      <S.TextItemPremium2>
                        <span>R$</span> 45,90
                      </S.TextItemPremium2>
                    </S.ForaTextItemPremium>

                    <S.ForaConteudoItemPremium>
                      <S.TextConteudoItemPremium>
                        <span>•</span> Assinatura área Vip Mensal
                      </S.TextConteudoItemPremium>
                      <S.TextConteudoItemPremium>
                        <span>•</span> Acesso a todos os meus conteúdos
                      </S.TextConteudoItemPremium>
                      <S.TextConteudoItemPremium>
                        <span>•</span> Vídeos exclusivos
                      </S.TextConteudoItemPremium>
                      <S.TextConteudoItemPremium>
                        <span>•</span> Atualizações semanais
                      </S.TextConteudoItemPremium>
                    </S.ForaConteudoItemPremium>

                    <S.ForaAssineJa
                      onClick={() => MandaCadastro("Mensal", "4590")}
                      style={{ backgroundColor: "#a73b7e" }}
                      href="#Pagamento"
                    >
                      <S.ForaImgAssineJa>
                        <S.ImgAssineJa src={ImgCoracao} />
                      </S.ForaImgAssineJa>
                      <S.TextAssineJa>Assine já</S.TextAssineJa>
                    </S.ForaAssineJa>
                  </S.ItemPremium>

                  <S.ForaAssineJa2
                    onClick={() => MandaCadastro("Mensal", "4590")}
                    style={{ backgroundColor: "#a73b7e" }}
                    href="#Pagamento"
                  >
                    <S.ForaImgAssineJa>
                      <S.ImgAssineJa src={ImgCoracao} />
                    </S.ForaImgAssineJa>
                    <S.TextAssineJa>Assine já</S.TextAssineJa>
                  </S.ForaAssineJa2>

                  <S.ItemPremium style={{ backgroundColor: "#441b6d" }}>
                    <S.ForaImgItemPremium>
                      <S.ImagemItemPremium src={ImgItemPremium2} />
                    </S.ForaImgItemPremium>

                    <S.ForaImgItemPremiumResp2>
                      <S.ForaImgItemPremiumResp>
                        <S.ImagemItemPremium src={ImgItemPremiumResp2} />
                      </S.ForaImgItemPremiumResp>
                    </S.ForaImgItemPremiumResp2>

                    <S.ForaTextItemPremium>
                      <S.TextItemPremium1 style={{ color: "#c34592" }}>
                        trimestral
                      </S.TextItemPremium1>
                      <S.TextItemPremium2>
                        <span>R$</span> 114,90
                      </S.TextItemPremium2>
                    </S.ForaTextItemPremium>

                    <S.ForaConteudoItemPremium>
                      <S.TextConteudoItemPremium>
                        <span>•</span> Assinatura área Vip Mensal
                      </S.TextConteudoItemPremium>
                      <S.TextConteudoItemPremium>
                        <span>•</span> Acesso a todos os meus conteúdos
                      </S.TextConteudoItemPremium>
                      <S.TextConteudoItemPremium>
                        <span>•</span> Vídeos exclusivos
                      </S.TextConteudoItemPremium>
                      <S.TextConteudoItemPremium>
                        <span>•</span> Atualizações semanais
                      </S.TextConteudoItemPremium>
                    </S.ForaConteudoItemPremium>

                    <S.ForaAssineJa
                      onClick={() => MandaCadastro("Trimestral", "11490")}
                      style={{ backgroundColor: "#a73b7e" }}
                      href="#Pagamento"
                    >
                      <S.ForaImgAssineJa>
                        <S.ImgAssineJa src={ImgCoracao} />
                      </S.ForaImgAssineJa>
                      <S.TextAssineJa>Assine já</S.TextAssineJa>
                    </S.ForaAssineJa>
                  </S.ItemPremium>

                  <S.ForaAssineJa2
                    onClick={() => MandaCadastro("Trimestral", "11490")}
                    style={{ backgroundColor: "#a73b7e" }}
                    href="#Pagamento"
                  >
                    <S.ForaImgAssineJa>
                      <S.ImgAssineJa src={ImgCoracao} />
                    </S.ForaImgAssineJa>
                    <S.TextAssineJa>Assine já</S.TextAssineJa>
                  </S.ForaAssineJa2>

                  <S.ItemPremium style={{ backgroundColor: "#c44693" }}>
                    <S.ForaImgItemPremium>
                      <S.ImagemItemPremium src={ImgItemPremium3} />
                    </S.ForaImgItemPremium>

                    <S.ForaImgItemPremiumResp2>
                      <S.ForaImgItemPremiumResp>
                        <S.ImagemItemPremium src={ImgItemPremiumResp3} />
                      </S.ForaImgItemPremiumResp>
                    </S.ForaImgItemPremiumResp2>

                    <S.ForaTextItemPremium>
                      <S.TextItemPremium1 style={{ color: "#491d74" }}>
                        semestral
                      </S.TextItemPremium1>
                      <S.TextItemPremium2>
                        <span>R$</span> 205,90
                      </S.TextItemPremium2>
                    </S.ForaTextItemPremium>

                    <S.ForaConteudoItemPremium>
                      <S.TextConteudoItemPremium>
                        <span>•</span> Assinatura área Vip Mensal
                      </S.TextConteudoItemPremium>
                      <S.TextConteudoItemPremium>
                        <span>•</span> Acesso a todos os meus conteúdos
                      </S.TextConteudoItemPremium>
                      <S.TextConteudoItemPremium>
                        <span>•</span> Vídeos exclusivos
                      </S.TextConteudoItemPremium>
                      <S.TextConteudoItemPremium>
                        <span>•</span> Atualizações semanais
                      </S.TextConteudoItemPremium>
                    </S.ForaConteudoItemPremium>

                    <S.ForaAssineJa
                      onClick={() => MandaCadastro("Semestral", "20590")}
                      style={{ backgroundColor: "#a73b7e" }}
                    >
                      <S.ForaImgAssineJa>
                        <S.ImgAssineJa src={ImgCoracao} />
                      </S.ForaImgAssineJa>
                      <S.TextAssineJa>Assine já</S.TextAssineJa>
                    </S.ForaAssineJa>
                  </S.ItemPremium>

                  <S.ForaAssineJa2
                    onClick={() => MandaCadastro("Semestral", "20590")}
                    style={{ backgroundColor: "#a73b7e" }}
                    href="#Pagamento"
                  >
                    <S.ForaImgAssineJa>
                      <S.ImgAssineJa src={ImgCoracao} />
                    </S.ForaImgAssineJa>
                    <S.TextAssineJa>Assine já</S.TextAssineJa>
                  </S.ForaAssineJa2>
                </S.ForaItensPremium>
              </S.ForaPremium>
            </S.BgPremium>
          </S.ContainerCompra>
        )}

        <div id="Pagamento"></div>

        {context.tipo_assinatura !== "" && (
          <S.ForaFormCadastro>
            <S.TextFormCadastro1>
              Plano escolhido:{" "}
              <span>
                {context.tipo_assinatura} - R${" "}
                {formatReal(context?.valor_assinatura)}
              </span>
            </S.TextFormCadastro1>

            <S.ColunaPagamento>
              <S.TextFormCadastro1 style={{ marginRight: "20px" }}>
                Selecione a forma de pagamento:{" "}
              </S.TextFormCadastro1>

              <S.ItemPagamento
                style={{
                  backgroundColor:
                    context.forma_pagamento === 0 ? "#340e5a" : "#FFFFFF",
                }}
                onClick={() => {
                  MudaPagamento(0);
                }}
              >
                <S.ImgItemPagamento
                  src={PixLogo}
                  // style={{ height: "125px", width: "250px" }}
                />
              </S.ItemPagamento>
              {/* <S.ItemPagamento
                style={{
                  backgroundColor:
                    context.forma_pagamento === 1 ? "#340e5a" : "#FFFFFF",
                }}
                onClick={() => {
                  MudaPagamento(1);
                }}
              >
                <S.ImgItemPagamento src={BoletoLogo} />
              </S.ItemPagamento> */}
              <S.ItemPagamento
                style={{
                  backgroundColor:
                    context.forma_pagamento === 2 ? "#340e5a" : "#FFFFFF",
                }}
                onClick={() => {
                  MudaPagamento(2);
                }}
              >
                <S.ImgItemPagamento src={CreditLogo} />
              </S.ItemPagamento>
            </S.ColunaPagamento>

            {!qrVisible ? (
              <>
                {context.forma_pagamento === 2 && (
                  <>
                    <S.ItemForm>
                      <S.TextItemForm>
                        NÚMERO DO CARTÃO DE CRÉDITO
                      </S.TextItemForm>
                      <input
                        onChange={(e) => setCardNumber(e.target.value)}
                        className="InputFormCadastro"
                        name="c_nome"
                        id="c_nome"
                        value={cartaoMask(cardNumber)}
                        maxLength={19}
                        onPaste={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onCopy={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      />
                    </S.ItemForm>
                    <S.ItemForm>
                      <S.TextItemForm>NOME GRAVADO NO CARTÃO</S.TextItemForm>
                      <input
                        onChange={(e) => setCardholderName(e.target.value)}
                        className="InputFormCadastro"
                        name="c_nome"
                        id="c_nome"
                      />
                    </S.ItemForm>
                    <S.ItemForm>
                      <S.TextItemForm>
                        CPF DO PROPRIETÁRIO DO CARTÃO
                      </S.TextItemForm>
                      <input
                        onChange={(e) =>
                          setIdentificationNumber(e.target.value)
                        }
                        className="InputFormCadastro"
                        name="c_cpf"
                        id="c_cpf"
                        value={cpfMask(identificationNumber)}
                      />
                    </S.ItemForm>

                    <S.Fora2Form>
                      <S.ItemForm className="Item2Form">
                        <S.TextItemForm>
                          MÊS DE EXPIRAÇÃO DO CARTÃO
                        </S.TextItemForm>
                        <select
                          className="SelectCartao"
                          onChange={(e) => setExpirationMonth(e.target.value)}
                          id="SelectMes"
                          name="SelectMes"
                        >
                          <option value="0" selected>
                            Selecione o mês
                          </option>
                          <option value="01">Janeiro</option>
                          <option value="02">Fevereiro</option>
                          <option value="03">Março</option>
                          <option value="04">Abril</option>
                          <option value="05">Maio</option>
                          <option value="06">Junho</option>
                          <option value="07">Julho</option>
                          <option value="08">Agosto</option>
                          <option value="09">Setembro</option>
                          <option value="10">Outubro</option>
                          <option value="11">Novembro</option>
                          <option value="12">Dezembro</option>
                        </select>
                      </S.ItemForm>
                      <S.ItemForm className="Item2Form">
                        <S.TextItemForm>
                          ANO DE EXPIRAÇÃO DO CARTÃO
                        </S.TextItemForm>
                        <select
                          className="SelectCartao"
                          onChange={(e) => setExpirationYear(e.target.value)}
                          id="SelectAno"
                          name="SelectAno"
                        >
                          <option value="0" selected>
                            Selecione o Ano
                          </option>
                          <option value="2022">2022</option>
                          <option value="2023">2023</option>
                          <option value="2024">2024</option>
                          <option value="2025">2025</option>
                          <option value="2026">2026</option>
                          <option value="2027">2027</option>
                          <option value="2028">2028</option>
                          <option value="2029">2029</option>
                          <option value="2030">2030</option>
                        </select>
                      </S.ItemForm>

                      <S.ItemForm className="Item2Form">
                        <S.TextItemForm>
                          CÓDIGO DE SERGURANÇA DO CARTÃO
                        </S.TextItemForm>
                        <input
                          onChange={(e) => setCode(e.target.value)}
                          className="InputFormCadastro"
                          name="c_cpf"
                          id="c_cpf"
                        />
                      </S.ItemForm>
                    </S.Fora2Form>

                    {/* <S.Fora2Form>


          <S.ItemForm className="Item2Form">
            <S.TextItemForm>MÊS DE EXPIRAÇÃO DO CARTÃO</S.TextItemForm>
            <input
              onChange={(e) => setExpirationMonth(e.target.value)}
              className="InputFormCadastro"
              name="c_cpf"
              id="c_cpf"
            />
          </S.ItemForm>
          <S.ItemForm className="Item2Form">
            <S.TextItemForm>ANO DE EXPIRAÇÃO DO CARTÃO</S.TextItemForm>
            <input
              onChange={(e) => setExpirationYear(e.target.value)}
              className="InputFormCadastro"
              name="c_cpf"
              id="c_cpf"
            />
          </S.ItemForm>
        </S.Fora2Form> */}
                  </>
                )}
                <S.ItemForm2>
                  <input
                    onChange={(e) => setCheckbox(e.target.value)}
                    type="checkbox"
                    className="c_checkbox"
                    name="c_checkbox"
                    id="c_checkbox"
                  />
                  <S.TextItemForm2>
                    Li e estou de acordo com os{" "}
                    <a onClick={() => MandaTermosDeUso()} alt="">
                      termos de uso do site.
                    </a>
                  </S.TextItemForm2>
                </S.ItemForm2>
                <button
                  onClick={() => onSubmitCompra()}
                  className="BotaoCadastrar"
                >
                  AVANÇAR
                </button>
              </>
            ) : (
              <div>mostra qr</div>
            )}
          </S.ForaFormCadastro>
        )}

        <div>Minhas assinaturas</div>
        {data.length === 0 && <div>Você não possui assinaturas ativas</div>}
        <S.ForaAssinaturas>
          {data.map((res, index) => (
            <S.ItemAssinatura>
              <S.AssinaturaBaixo>
                <S.TextAssinatura2>
                  <strong>Data da compra:</strong>
                </S.TextAssinatura2>
                <S.TextAssinatura2>
                  {res.c_data_hora.toString().substring(6, 8)}/
                  {res.c_data_hora.toString().substring(4, 6)}/
                  {res.c_data_hora.toString().substring(0, 4)}
                </S.TextAssinatura2>
              </S.AssinaturaBaixo>
              <S.AssinaturaBaixo>
                {res.status_detail === "accredited" && (
                  <>
                    <S.TextAssinatura2>
                      <strong>Válido até:</strong>
                    </S.TextAssinatura2>
                    <S.TextAssinatura2>
                      <strong>Pago:</strong>{" "}
                      {res.c_data_hora.toString().substring(6, 8)}/
                      {res.c_data_hora.toString().substring(4, 6)}/
                      {res.c_data_hora.toString().substring(0, 4)}
                    </S.TextAssinatura2>
                  </>
                )}

                {res.status === "rejected" && (
                  <>
                    <S.TextAssinatura2>
                      <strong>Status:</strong>
                    </S.TextAssinatura2>
                    <S.TextAssinatura2>
                      <strong>Pagamento negado</strong>
                    </S.TextAssinatura2>
                  </>
                )}

                {res.status === "in_process" && (
                  <>
                    <S.TextAssinatura2>
                      <strong>Status:</strong>
                    </S.TextAssinatura2>
                    <S.TextAssinatura2>
                      <strong>Aguardando pagamento</strong>
                    </S.TextAssinatura2>
                  </>
                )}
              </S.AssinaturaBaixo>
              <S.AssinaturaBaixo>
                <S.TextAssinatura2>
                  <strong>Tipo:</strong>
                </S.TextAssinatura2>
                <S.TextAssinatura2>{res.c_plano.toString()}</S.TextAssinatura2>
              </S.AssinaturaBaixo>
              <S.TextAssinatura1>
                Valor: R${" "}
                {formatReal(
                  parseFloat(res.c_valor.toString()).toFixed(2).replace(".", "")
                )}
              </S.TextAssinatura1>
              {res.c_tipo == 0 && res.status !== "PAID" && (
                <S.verBoleto
                  onClick={() => {
                    // AlertFormPagamentoPixRenova(
                    //   res.qr_code_base64,
                    //   res.qr_link
                    // );
                    setStatusQr(true);
                    setQr_code(res.qr_code_base64);
                    setQr_link(res.qr_link);
                  }}
                >
                  visualizar QR Code
                </S.verBoleto>
              )}
              {res.c_tipo == 1 && res.status !== "PAID" && (
                <S.verBoleto
                  onClick={() => {
                    window.open(res.ticket_url);
                  }}
                >
                  visualizar boleto
                </S.verBoleto>
              )}
            </S.ItemAssinatura>
          ))}
          {/* <S.ItemAssinatura>
              <S.TextAssinatura1>R$ 33,00</S.TextAssinatura1>
              <S.AssinaturaBaixo>
                <S.TextAssinatura2>Data da compra:</S.TextAssinatura2>
                <S.TextAssinatura2>12/05/20022</S.TextAssinatura2>
              </S.AssinaturaBaixo>
              <S.AssinaturaBaixo>
                <S.TextAssinatura2>Data de validade:</S.TextAssinatura2>
                <S.TextAssinatura2>12/05/20022</S.TextAssinatura2>
              </S.AssinaturaBaixo>
            </S.ItemAssinatura>

            <S.ItemAssinatura>
              <S.TextAssinatura1>R$ 33,00</S.TextAssinatura1>
              <S.AssinaturaBaixo>
                <S.TextAssinatura2>Data da compra:</S.TextAssinatura2>
                <S.TextAssinatura2>12/05/20022</S.TextAssinatura2>
              </S.AssinaturaBaixo>
              <S.AssinaturaBaixo>
                <S.TextAssinatura2>Data de validade:</S.TextAssinatura2>
                <S.TextAssinatura2>12/05/20022</S.TextAssinatura2>
              </S.AssinaturaBaixo>
            </S.ItemAssinatura>
            
            <S.ItemAssinatura>
              <S.TextAssinatura1>R$ 33,00</S.TextAssinatura1>
              <S.AssinaturaBaixo>
                <S.TextAssinatura2>Data da compra:</S.TextAssinatura2>
                <S.TextAssinatura2>12/05/20022</S.TextAssinatura2>
              </S.AssinaturaBaixo>
              <S.AssinaturaBaixo>
                <S.TextAssinatura2>Data de validade:</S.TextAssinatura2>
                <S.TextAssinatura2>12/05/20022</S.TextAssinatura2>
              </S.AssinaturaBaixo>
            </S.ItemAssinatura> */}
        </S.ForaAssinaturas>
      </>
      <S.ForaImgBaixoSobreMim>
        <S.ImagemBaixoSobreMim src={ImgBaixoSobreMim} />
      </S.ForaImgBaixoSobreMim>
      <Footer />
    </>
  );
}

export default Cadastro;
