import React, { useEffect, useState } from "react";
import Testex from "../../Componentes/Teste";

function Teste(props) {
  function retorno(tipo) {
    alert(tipo);
  }

  return (
    <>
      <Testex retorno={(value) => retorno(value)}></Testex>
    </>
  );
}

export default Teste;
