import React, { useState, useEffect } from "react";

function MyDialog(props) {
  return (
    <>
      <div>
        <button
          onClick={() => {
            props.retorno("jjljkljlkj");
          }}
        >
          xxxx
        </button>
      </div>
    </>
  );
}

export default MyDialog;
