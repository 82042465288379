import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as S from './Estilos'
import Header from '../../Componentes/Header/';
import Footer from '../../Componentes/Footer/';

import ImgBaixoSobreMim from "../../Images/SobreMim/ImgBaixoSobreMim.png";

function Fotos(props) {
  const history = useHistory();
  const [Loading, setLoading] = useState(false);
  const [box, setBox] = useState(false);
  const { handleSubmit } = useForm();

  const MandaVoltar = () => {
    history.push("/#FormularioCadastro");
  };

  return (
    <>
      <Header /> 

      <S.ForaTermos>
        <S.TextTermos1>Termos de Uso</S.TextTermos1>
        <S.TextTermos2>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum massa a dolor convallis, sit amet cursus tellus viverra. 
            Nulla vulputate efficitur nulla, eget tempus nisi ornare in. Nulla id massa vestibulum leo commodo pellentesque. 
            Nullam et pharetra nibh. Nulla quis eros ac ex vehicula consectetur. Duis congue magna in dolor volutpat ultrices.
        </S.TextTermos2>

        <S.TextTermos2>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum massa a dolor convallis, sit amet cursus tellus viverra. 
            Nulla vulputate efficitur nulla, eget tempus nisi ornare in. Nulla id massa vestibulum leo commodo pellentesque. Nullam et pharetra nibh. 
            Nulla quis eros ac ex vehicula consectetur. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum massa a dolor convallis, sit amet cursus tellus viverra.
            Nulla vulputate efficitur nulla, eget tempus nisi ornare in. Nulla id massa vestibulum leo commodo pellentesque. Nullam et pharetra nibh. 
            Nulla quis eros ac ex vehicula consectetur. Duis congue magna in dolor volutpat ultrices.
        </S.TextTermos2>

        <S.TextTermos2>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum massa a dolor convallis, sit amet cursus tellus viverra. 
            Nulla vulputate efficitur nulla, eget tempus nisi ornare in. Nulla id massa vestibulum leo commodo pellentesque. Nullam et pharetra nibh. 
            Nulla quis eros ac ex vehicula consectetur. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum massa a dolor convallis, sit amet cursus tellus viverra.
            Nulla vulputate efficitur nulla, eget tempus nisi ornare in. Nulla id massa vestibulum leo commodo pellentesque. Nullam et pharetra nibh. 
            Nulla quis eros ac ex vehicula consectetur. Duis congue magna in dolor volutpat ultrices.
        </S.TextTermos2>

        <S.TextTermos2>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum massa a dolor convallis, sit amet cursus tellus viverra. 
            Nulla vulputate efficitur nulla, eget tempus nisi ornare in. Nulla id massa vestibulum leo commodo pellentesque. Nullam et pharetra nibh. 
            Nulla quis eros ac ex vehicula consectetur. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum massa a dolor convallis, sit amet cursus tellus viverra.
            Nulla vulputate efficitur nulla, eget tempus nisi ornare in. Nulla id massa vestibulum leo commodo pellentesque. Nullam et pharetra nibh. 
            Nulla quis eros ac ex vehicula consectetur. Duis congue magna in dolor volutpat ultrices.
        </S.TextTermos2>

        <S.TextTermos2>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum massa a dolor convallis, sit amet cursus tellus viverra. 
            Nulla vulputate efficitur nulla, eget tempus nisi ornare in. 
        </S.TextTermos2>

        <S.TextTermos2>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum massa a dolor convallis, sit amet cursus tellus viverra. 
            Nulla vulputate efficitur nulla, eget tempus nisi ornare in. Nulla id massa vestibulum leo commodo pellentesque. Nullam et pharetra nibh. 
            Nulla quis eros ac ex vehicula consectetur. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum massa a dolor convallis, sit amet cursus tellus viverra. 
            Nulla vulputate efficitur nulla, eget tempus nisi ornare in. Nulla id massa vestibulum leo commodo pellentesque. Nullam et pharetra nibh. 
            Nulla quis eros ac ex vehicula consectetur. 
        </S.TextTermos2>

        <S.BotaoCadastro onClick={() => MandaVoltar()}>Voltar</S.BotaoCadastro>
      </S.ForaTermos>

      <S.ForaImgBaixoSobreMim>
        <S.ImagemBaixoSobreMim src={ImgBaixoSobreMim} />
      </S.ForaImgBaixoSobreMim>

      <Footer />
    </>
  );
}

export default Fotos;
