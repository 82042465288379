import styled from "styled-components";

// import ImgLogo from "./Images/seta_baixo.png";

export const ImagemBanner = styled.img``;

export const ForaConteudoSobreMim = styled.div`
  width: 1370px;
  margin: 0 auto;

  @media (max-width: 1430px) {
    width: 1100px;
  }

  @media (max-width: 1160px) {
    width: 900px;
  }

  @media (max-width: 950px) {
    width: 650px;
  }

  @media (max-width: 700px) {
    width: 90%;
  }
`;






export const ForaBanner = styled.div`
  background-color: #d04a9b;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 150px;

  @media (max-width: 500px) {
    height: 140px;
    padding-top: 120px;
  } 
`;

export const TextBanner = styled.div`
    font-family: "Lato";
    font-size: 55px;
    font-weight: bold;
    text-transform: uppercase;
`;

export const ForaDiario = styled.div`
  width: 1400px;
  margin: 0 auto;

  @media (max-width: 1470px) {
    width: 90%;
  } 
`;

export const TextDiario1 = styled.div`
  color: #d04a9b;
  font-family: "Lato";
  font-size: 37px;
  font-weight: bold;
  font-style: italic;
  padding-top: 60px;

  @media (max-width: 600px) {
    padding-top: 40px;
  } 
`;

export const ItemDiario = styled.div`
  padding-top: 35px;

  @media (max-width: 500px) {
    padding-top: 20px;
  } 
`;

export const TextDiario2 = styled.div`
  color: #d04a9b;
  font-family: "Lato";
  font-size: 25px;
  font-weight: bold;

  @media (max-width: 500px) {
    font-size: 23px;
  } 
`;

export const TextDiario3 = styled.div`
  color: #5f536c;
  font-family: "Lato";
  font-size: 22px;
  font-weight: 400;

  @media (max-width: 500px) {
    font-size: 19px;
  } 
`;

export const TextProxPagina = styled.div`
  color: #5f536c;
  font-family: "Lato";
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
  cursor: pointer;

  @media (max-width: 1000px) {
    padding-top: 60px;
    padding-bottom: 60px;
  } 
`;


export const Fora3Img = styled.div`
    display: flex;
    justify-content: space-between;
    width: 1400px;
    margin: 0 auto;

    @media (max-width: 1470px) {
      width: 90%;
    } 

    @media (max-width: 650px) {
        flex-wrap: wrap;
    } 
`;

export const ForaImgSobreMim = styled.div`
    width: 30%;
    cursor: pointer;

    @media (max-width: 650px) {
        width: 48%;
        margin: 0 auto;
    } 

    @media (max-width: 450px) {
        width: 100%;
        margin-top: 15px;
    } 
`;

export const ImagemSobreMim = styled.img`

`;




export const ForaEntreContato = styled.div`
  background-color: #f7f7f7;
  height: 310px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 120px;

  @media (max-width: 950px) {
    margin-top: 60px;
    height: 230px;
    padding-bottom: 35px;
  }

  @media (max-width: 450px) {
    height: 150px;
    padding-bottom: 53px;
    margin-top: 25px;
  }
`;

export const TextEntreContato1 = styled.div`
  color: #549c00;
  font-family: "Lato";
  font-size: 37px;
  font-weight: bold;
  font-style: italic;
  text-align: center;

  @media (max-width: 450px) {
    font-size: 30px;
  }
`;

export const ForaBotaoEntreContato = styled.div`
  display: flex;
  width: 430px;
  margin: 0 auto;
  background-color: #549c00;
  align-items: center;
  justify-content: center;
  height: 95px;
  border-radius: 14px;
  margin-top: 30px;

  @media (max-width: 950px) {
    height: 75px;
    margin-top: 20px;
  }

  @media (max-width: 510px) {
    width: 360px;
  }

  @media (max-width: 450px) {
    width: 250px;
    height: 60px;
  }
`;

export const ForaImgWhats = styled.div`
  @media (max-width: 450px) {
    width: 45px;
  }
`;

export const ImagemWhats = styled.img``;

export const TextEntreContato2 = styled.div`
  font-family: "Lato";
  font-size: 30px;
  color: #fff;
  padding-left: 10px;

  span {
    font-weight: bold;
  }

  @media (max-width: 450px) {
    font-size: 20px;
    padding-left: 0px;
  }
`;

export const ForaImgBaixoSobreMim = styled.div`
  margin-top: -48px;
  margin-bottom: -95px;

  @media (max-width: 670px) {
    margin-bottom: -35px;
  }

  @media (max-width: 450px) {
    margin-bottom: -25px;
  }
`;

export const ImagemBaixoSobreMim = styled.img``;
