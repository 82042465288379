import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as S from "./Estilos";
import Header from "../../Componentes/Header";
import Footer from "../../Componentes/Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Load from "../../Componentes/Load";
import ImgBanner from "../../Images/AreaPremium/Banner.jpg";
import ImgBannerResp from "../../Images/AreaPremium/BannerResp.jpg";
import ImgBaixoSobreMim from "../../Images/SobreMim/ImgBaixoSobreMim.png";
import ImgPremium1 from "../../Images/AreaPremium/ImgPremium1.png";
import ImgSeta from "../../Images/AreaPremium/SetaFiltro.png";
import ImgAreaPremium1 from "../../Images/AreaPremium/ImgAreaPremium1.png";
import ImgLogin1 from "../../Images/AreaPremium/ImgLogin1.png";
import ImgBotaoForm from "../../Images/AreaPremium/ImgBotaoForm.png";
import ImgItemPremium from "../../Images/ImgItemPremium.png";
import ImgItemPremiumResp from "../../Images/ImgItemPremiumResp.png";
import ImgItemPremiumResp2 from "../../Images/ImgItemPremiumResp2.png";
import ImgItemPremiumResp3 from "../../Images/ImgItemPremiumResp3.png";
import ImgCoracao from "../../Images/ImgCoracao.png";
import ImgItemPremium2 from "../../Images/ImgItemPremium2.png";
import ImgItemPremium3 from "../../Images/ImgItemPremium3.png";
import { AlertForm, AlertFormPagamento } from "../../Componentes/Funcoes";
import { postData } from "../../utils/useData";
function Login(props) {
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [load, setLoad] = useState(false);
  const [form, setForm] = useState(1);
  const history = useHistory();
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 100,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 10,
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      document.getElementById("login").scrollIntoView({ behavior: "smooth" });
    }, 300);
  }, []);

  const SubmitForm = async () => {
    setLoad(true);
    if (senha === "" || senha === undefined) {
      AlertForm("O campo Senha é de preenchimento obrigatório!");
      setLoad(false);
    } else if (email === "" || email === undefined) {
      AlertForm("O campo E-MAIL é de preenchimento obrigatório!");
      setLoad(false);
      //e.preventDefault();
    } else if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      //AlertForm("Enviado com sucesso!");
      setLoad(true);

      const data = JSON.stringify({
        c_email: email,
        c_senha: senha,
      });

      const response = await postData("/pagamento/login", data);
      var res = JSON.parse(response);
      setLoad(false);

      if (res.status) {
        localStorage.setItem("logged", "true");
        localStorage.setItem("id_tb_user", res.id_tb_user);
        localStorage.setItem("c_email", res.c_email);
        localStorage.setItem("c_nome", res.c_nome);
        localStorage.setItem("token", res.c_token);
        setLoad(false);
        history.push("/AreaPremium");
      } else {
        setLoad(false);
        AlertForm("Login ou senha incorretos!");
      }
    } else {
      AlertForm("O campo E-MAIL está com o formato incorreto!");
      setLoad(false);
      //e.preventDefault();
    }
  };

  const SubmitFormSenha = async () => {
    setLoad(true);
    if (email === "" || email === undefined) {
      AlertForm("O campo E-MAIL é de preenchimento obrigatório!");
      setLoad(false);
      //e.preventDefault();
    } else if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      //AlertForm("Enviado com sucesso!");
      setLoad(true);

      const data = JSON.stringify({
        c_email: email,
      });

      const response = await postData("/pagamento/envia_senha", data);
      var res = JSON.parse(response);

      const { status } = res;
      setLoad(false);
      if (status) {
        AlertForm("Reenvio de senha efetuado com sucesso para " + email);
      } else {
        AlertForm("E-mail não encontrado em nossa base de dados!");
      }
    } else {
      AlertForm("O campo E-MAIL está com o formato incorreto!");
      setLoad(false);
      //e.preventDefault();
    }
  };

  const MandaCadastro = () => {
    // document.location = "/MinhaConta";
    history.push("/#BgPremium");
  };

  const AbreEsqueceuSenha = () => {
    setForm(2);
  };

  return (
    <>
      <Header />
      <Load status={load} />
      <>
        <S.BgLogin id="login">
          <S.ForaLogin>
            <S.ForaImgText>
              <S.ImagemText src={ImgAreaPremium1} />
            </S.ForaImgText>
            <S.ForaBaixoLogin>
              <S.ForaFormLogin>
                <S.TextForm1>
                  Entre e desfrute de todo o conteúdo exclusivo!
                </S.TextForm1>

                {form === 1 ? (
                  <form className="FormLogin" name="FormLogin" id="FormLogin">
                    <div style={{ color: "#0000000", marginTop: "10px" }}>
                      <input
                        type="text"
                        placeholder="E-mail"
                        // className="InputLogin"
                        className="InputFormCadastro"
                        name="c_email"
                        id="c_email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                      />
                    </div>
                    <div style={{ color: "#0000000", marginTop: "10px" }}>
                      <input
                        type="password"
                        placeholder="Senha"
                        // className="InputLogin"
                        className="InputFormCadastro"
                        name="c_senha"
                        id="c_senha"
                        onChange={(e) => {
                          setSenha(e.target.value);
                        }}
                        value={senha}
                      />
                    </div>
                    <S.BotaoFormLogin
                      onClick={() => {
                        SubmitForm();
                      }}
                    >
                      <S.ForaImgBotaoLogin>
                        <S.ImagemBotaoLogin src={ImgBotaoForm} />
                      </S.ForaImgBotaoLogin>
                      <S.TextBotaoLogin>entrar</S.TextBotaoLogin>
                    </S.BotaoFormLogin>
                  </form>
                ) : (
                  <form className="FormSenha" name="FormSenha" id="FormSenha">
                    <div style={{ color: "#0000000", marginTop: "10px" }}>
                      <input
                        type="text"
                        placeholder="E-mail"
                        // className="InputLogin"
                        className="InputFormCadastro"
                        name="c_email"
                        id="c_email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                      />
                    </div>
                    <S.BotaoFormLogin
                      onClick={() => {
                        SubmitFormSenha();
                      }}
                    >
                      <S.ForaImgBotaoLogin>
                        <S.ImagemBotaoLogin src={ImgBotaoForm} />
                      </S.ForaImgBotaoLogin>
                      <S.TextBotaoLogin>entrar</S.TextBotaoLogin>
                    </S.BotaoFormLogin>
                  </form>
                )}

                <S.TextForm2
                  onClick={() => {
                    AbreEsqueceuSenha();
                  }}
                >
                  Esqueceu a senha? <span>Clique aqui.</span>
                </S.TextForm2>

                <S.TextForm2
                  onClick={() => {
                    MandaCadastro();
                  }}
                >
                  Ainda não tem um cadastro <span>Clique aqui.</span>
                </S.TextForm2>
              </S.ForaFormLogin>
              <S.ForaImglogin1>
                <S.ImagemLogin1 src={ImgLogin1} />
              </S.ForaImglogin1>
            </S.ForaBaixoLogin>
          </S.ForaLogin>
        </S.BgLogin>
        {/* 
        <S.FundoBaixoLogin>
          <S.ForaBaixoLogin2>
            <S.TextBaixoLogin1>AINDA NÃO É MEMBRO PREMIUM?</S.TextBaixoLogin1>
            <S.TextBaixoLogin2>
              ESCOLHA O MELHOR PLANO PARA VOCÊ
            </S.TextBaixoLogin2>
          </S.ForaBaixoLogin2>

          <S.ForaItensPremium>
            <S.ItemPremium style={{ backgroundColor: "#c44693" }}>
              <S.ForaImgItemPremium>
                <S.ImagemItemPremium src={ImgItemPremium} />
              </S.ForaImgItemPremium>

              <S.ForaImgItemPremiumResp2>
                <S.ForaImgItemPremiumResp>
                  <S.ImagemItemPremium src={ImgItemPremiumResp} />
                </S.ForaImgItemPremiumResp>
              </S.ForaImgItemPremiumResp2>

              <S.ForaTextItemPremium>
                <S.TextItemPremium1 style={{ color: "#491d74" }}>
                  Mensal
                </S.TextItemPremium1>
                <S.TextItemPremium2>
                  <span>R$</span> 54,90
                </S.TextItemPremium2>
                <S.TextItemPremium3>/ mês</S.TextItemPremium3>
              </S.ForaTextItemPremium>

              <S.ForaConteudoItemPremium>
                <S.TextConteudoItemPremium>
                  <span>•</span> Assinatura área Vip Mensal
                </S.TextConteudoItemPremium>
                <S.TextConteudoItemPremium>
                  <span>•</span> Acesso a todos os meus conteúdos
                </S.TextConteudoItemPremium>
                <S.TextConteudoItemPremium>
                  <span>•</span> Vídeos exclusivos
                </S.TextConteudoItemPremium>
                <S.TextConteudoItemPremium>
                  <span>•</span> Atualizações semanais
                </S.TextConteudoItemPremium>
                <S.TextCobrado2>* Cobrado R$ 54,90 mensalmente</S.TextCobrado2>
              </S.ForaConteudoItemPremium>

              <S.ForaAssineJa
                onClick={() => MandaCadastro()}
                style={{ backgroundColor: "#a73b7e" }}
              >
                <S.ForaImgAssineJa>
                  <S.ImgAssineJa src={ImgCoracao} />
                </S.ForaImgAssineJa>
                <S.TextAssineJa>Assine já</S.TextAssineJa>
              </S.ForaAssineJa>
              <S.TextCobrado>* Cobrado R$ 54,90 mensalmente</S.TextCobrado>
            </S.ItemPremium>

            <S.ForaAssineJa2
              onClick={() => MandaCadastro()}
              style={{ backgroundColor: "#a73b7e" }}
            >
              <S.ForaImgAssineJa>
                <S.ImgAssineJa src={ImgCoracao} />
              </S.ForaImgAssineJa>
              <S.TextAssineJa>Assine já</S.TextAssineJa>
            </S.ForaAssineJa2>

            <S.ItemPremium style={{ backgroundColor: "#441b6d" }}>
              <S.ForaImgItemPremium>
                <S.ImagemItemPremium src={ImgItemPremium2} />
              </S.ForaImgItemPremium>

              <S.ForaImgItemPremiumResp2>
                <S.ForaImgItemPremiumResp>
                  <S.ImagemItemPremium src={ImgItemPremiumResp2} />
                </S.ForaImgItemPremiumResp>
              </S.ForaImgItemPremiumResp2>

              <S.ForaTextItemPremium>
                <S.TextItemPremium1 style={{ color: "#c34592" }}>
                  trimestral
                </S.TextItemPremium1>
                <S.TextItemPremium2>
                  <span>R$</span> 54,90
                </S.TextItemPremium2>
                <S.TextItemPremium3>/ mês</S.TextItemPremium3>
              </S.ForaTextItemPremium>

              <S.ForaConteudoItemPremium>
                <S.TextConteudoItemPremium>
                  <span>•</span> Assinatura área Vip Mensal
                </S.TextConteudoItemPremium>
                <S.TextConteudoItemPremium>
                  <span>•</span> Acesso a todos os meus conteúdos
                </S.TextConteudoItemPremium>
                <S.TextConteudoItemPremium>
                  <span>•</span> Vídeos exclusivos
                </S.TextConteudoItemPremium>
                <S.TextConteudoItemPremium>
                  <span>•</span> Atualizações semanais
                </S.TextConteudoItemPremium>
                <S.TextCobrado2>* Cobrado R$ 54,90 mensalmente</S.TextCobrado2>
              </S.ForaConteudoItemPremium>

              <S.ForaAssineJa
                onClick={() => MandaCadastro()}
                style={{ backgroundColor: "#a73b7e" }}
              >
                <S.ForaImgAssineJa>
                  <S.ImgAssineJa src={ImgCoracao} />
                </S.ForaImgAssineJa>
                <S.TextAssineJa>Assine já</S.TextAssineJa>
              </S.ForaAssineJa>
              <S.TextCobrado>* Cobrado R$ 54,90 mensalmente</S.TextCobrado>
            </S.ItemPremium>

            <S.ForaAssineJa2
              onClick={() => MandaCadastro()}
              style={{ backgroundColor: "#a73b7e" }}
            >
              <S.ForaImgAssineJa>
                <S.ImgAssineJa src={ImgCoracao} />
              </S.ForaImgAssineJa>
              <S.TextAssineJa>Assine já</S.TextAssineJa>
            </S.ForaAssineJa2>

            <S.ItemPremium style={{ backgroundColor: "#c44693" }}>
              <S.ForaImgItemPremium>
                <S.ImagemItemPremium src={ImgItemPremium3} />
              </S.ForaImgItemPremium>

              <S.ForaImgItemPremiumResp2>
                <S.ForaImgItemPremiumResp>
                  <S.ImagemItemPremium src={ImgItemPremiumResp3} />
                </S.ForaImgItemPremiumResp>
              </S.ForaImgItemPremiumResp2>

              <S.ForaTextItemPremium>
                <S.TextItemPremium1 style={{ color: "#491d74" }}>
                  semestral
                </S.TextItemPremium1>
                <S.TextItemPremium2>
                  <span>R$</span> 54,90
                </S.TextItemPremium2>
                <S.TextItemPremium3>/ mês</S.TextItemPremium3>
              </S.ForaTextItemPremium>

              <S.ForaConteudoItemPremium>
                <S.TextConteudoItemPremium>
                  <span>•</span> Assinatura área Vip Mensal
                </S.TextConteudoItemPremium>
                <S.TextConteudoItemPremium>
                  <span>•</span> Acesso a todos os meus conteúdos
                </S.TextConteudoItemPremium>
                <S.TextConteudoItemPremium>
                  <span>•</span> Vídeos exclusivos
                </S.TextConteudoItemPremium>
                <S.TextConteudoItemPremium>
                  <span>•</span> Atualizações semanais
                </S.TextConteudoItemPremium>
                <S.TextCobrado2>* Cobrado R$ 54,90 mensalmente</S.TextCobrado2>
              </S.ForaConteudoItemPremium>

              <S.ForaAssineJa
                onClick={() => MandaCadastro()}
                style={{ backgroundColor: "#a73b7e" }}
              >
                <S.ForaImgAssineJa>
                  <S.ImgAssineJa src={ImgCoracao} />
                </S.ForaImgAssineJa>
                <S.TextAssineJa>Assine já</S.TextAssineJa>
              </S.ForaAssineJa>
              <S.TextCobrado>* Cobrado R$ 54,90 mensalmente</S.TextCobrado>
            </S.ItemPremium>

            <S.ForaAssineJa2
              onClick={() => MandaCadastro()}
              style={{ backgroundColor: "#a73b7e" }}
            >
              <S.ForaImgAssineJa>
                <S.ImgAssineJa src={ImgCoracao} />
              </S.ForaImgAssineJa>
              <S.TextAssineJa>Assine já</S.TextAssineJa>
            </S.ForaAssineJa2>
          </S.ForaItensPremium>
        </S.FundoBaixoLogin> */}
      </>

      <S.ForaImgBaixoSobreMim>
        <S.ImagemBaixoSobreMim src={ImgBaixoSobreMim} />
      </S.ForaImgBaixoSobreMim>

      <Footer />
    </>
  );
}

export default Login;
