import styled from 'styled-components';

// import ImgLogo from "./Images/seta_baixo.png";


export const ForaBanner = styled.div`
     margin-bottom: -4px;
     padding-top: 100px;

    @media (max-width: 750px) {
        display: none;
    } 
`;

export const ForaBannerResp = styled.div`
    display: none;
    padding-top: 119px;

    @media (max-width: 750px) {
        display: block;
        margin-bottom: -4px;
    } 
`;

export const ImagemBanner = styled.img`
 
`;

export const ForaConteudoSobreMim = styled.div`
    width: 1370px;
    margin: 0 auto;

    @media (max-width: 1430px) {
        width: 1100px;
    } 

    @media (max-width: 1160px) {
        width: 900px;
    } 

    @media (max-width: 950px) {
        width: 650px;
    } 

    @media (max-width: 700px) {
        width: 90%;
    } 
`;







/* CONTEUDO CONTEUDO CONTEUDO CONTEUDO CONTEUDO CONTEUDO */

export const BgConteudo = styled.div`
    background-color: #f6f6f6;
    padding-bottom: 160px;

    @media (max-width: 1390px) {
        padding-bottom: 140px;
    } 

    @media (max-width: 800px) {
        padding-bottom: 10px;
    } 
`;

export const ForaConteudo = styled.div`
    width: 1330px;
    margin: 0 auto;

    @media (max-width: 1390px) {
        width: 1050px;
    } 

    @media (max-width: 1090px) {
        width: 800px;
    } 

    @media (max-width: 830px) {
        width: 90%;
    } 
`;



export const ForaItemConteudo = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
    flex-wrap: wrap;

    @media (max-width: 830px) {
        flex-wrap: wrap;
    } 
`;

export const ItemConteudo = styled.div`
    width: 24%;
    margin-top: 50px;

    @media (max-width: 830px) {
        width: 48%;
        margin-top: 30px;
    } 
`;

export const ForaImgConteudo = styled.div`
    width: 100%;
    height: 215px;
    background-size: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`;

export const ImgConteudo = styled.img`

`;

export const BaixoConteudo = styled.div`
    background-color: #f6f6f6;
    box-shadow: 0 0 10px #eeeeee;
    margin-top: -4px;
    padding-bottom: 20px;
`;

export const ForaBaixoConteudo = styled.div`
    width: 80%;
    margin: 0 auto;
`;

export const TextConteudo3 = styled.div`
    font-family: 'Lato';
    font-size: 20px;
    color: #bf448f;
    font-weight: 900;
    font-style: italic;
    padding-top: 25px;
    padding-bottom: 10px;

    @media (max-width: 500px) {
        font-size: 17px;
        padding-top: 20px;
    } 
`;

export const TextConteudo4 = styled.div`
    font-family: 'Lato';
    font-size: 15px;
    color: #431b6c;
    line-height: 22px;
`;

export const TextConteudo5 = styled.div`
    font-family: 'Lato';
    font-size: 30px;
    color: #491d74;
    font-weight: 900;
    padding-top: 10px;

    span {
        font-weight: 500;
    }

    @media (max-width: 1090px) {
        font-size: 27px;
    } 
`;

export const BotaoAdquirir = styled.div`
    font-family: 'Lato';
    font-size: 15px;
    color: #fff;
    font-weight: 900;
    background-color: #431b6c;
    text-transform: uppercase;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-top: 25px;
    margin-bottom: 25px;
    cursor: pointer;

    :hover {
        background-color: #c34591;
        cursor: pointer;
    }

    @media (max-width: 500px) {
        height: 35px;
        margin-top: 15px;
        margin-bottom: 0px;
    } 
`;








export const ForaEntreContato = styled.div`
    background-color: #f7f7f7;
    height: 310px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;

    @media (max-width: 950px) {
        margin-top: 60px;
        height: 230px;
        padding-bottom: 35px;
    } 

    @media (max-width: 450px) {
        height: 150px;
        padding-bottom: 53px;
        margin-top: 25px;
    } 
`;

export const TextEntreContato1 = styled.div`
    color: #549c00;
    font-family: 'Lato';
    font-size: 37px;
    font-weight: bold;
    font-style: italic;
    text-align: center;

    @media (max-width: 450px) {
        font-size: 30px;
    } 
`;

export const ForaBotaoEntreContato = styled.div`
    display: flex;
    width: 430px;
    margin: 0 auto;
    background-color: #549c00;
    align-items: center;
    justify-content: center;
    height: 95px;
    border-radius: 14px;
    margin-top: 30px;
    cursor: pointer;

    @media (max-width: 950px) {
        height: 75px;
        margin-top: 20px;
    } 

    @media (max-width: 510px) {
        width: 360px;
    } 

    @media (max-width: 450px) {
        width: 250px;
        height: 60px;
    } 

`;

export const ForaImgWhats = styled.div`
    @media (max-width: 450px) {
        width: 45px;
    } 
`;

export const ImagemWhats = styled.img`

`;

export const TextEntreContato2 = styled.div`
    font-family: 'Lato';
    font-size: 30px;
    color: #fff;
    padding-left: 10px;

    span {
        font-weight: bold;
    }

    @media (max-width: 450px) {
        font-size: 20px;
        padding-left: 0px;
    } 
`;

export const ForaImgBaixoSobreMim = styled.div`
    margin-top: -48px;
    margin-bottom: -95px;

    @media (max-width: 670px) {
        margin-bottom: -35px;
    } 

    @media (max-width: 450px) {
        margin-bottom: -25px;
    } 
`;

export const ImagemBaixoSobreMim = styled.img`

`;
