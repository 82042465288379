import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as S from "./Estilos";
import Header from "../../Componentes/Header";
import Footer from "../../Componentes/Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ImgBanner from "../../Images/ImgAreaPremium1.png";
import IconeWhats from "../../Images/ImgWhats.png";
import ImgPremiumInterna from "../../Images/AreaPremium/ImgPremiumInterna.jpg";
import ImgBaixoSobreMim from "../../Images/SobreMim/ImgBaixoSobreMim.png";
import ImgPremium1 from "../../Images/AreaPremium/ImgPremium1.png";
import ImgSeta from "../../Images/AreaPremium/SetaFiltro.png";
import ImgAreaPremium1 from "../../Images/AreaPremium/ImgAreaPremium1.png";
import ImgLogin1 from "../../Images/AreaPremium/ImgLogin1.png";
import ImgBotaoForm from "../../Images/AreaPremium/ImgBotaoForm.png";
import ImgItemPremium from "../../Images/ImgItemPremium.png";
import ImgItemPremiumResp from "../../Images/ImgItemPremiumResp.png";
import ImgItemPremiumResp2 from "../../Images/ImgItemPremiumResp2.png";
import ImgItemPremiumResp3 from "../../Images/ImgItemPremiumResp3.png";
import ImgCoracao from "../../Images/ImgCoracao.png";
import ImgItemPremium2 from "../../Images/ImgItemPremium2.png";
import ImgItemPremium3 from "../../Images/ImgItemPremium3.png";

function AreaPremium(props) {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 100,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 10,
        },
      },
    ],
  };

  const MandaWhats = () => {
    window.location.href = "https://wa.me/5511952875123";
  };

  const MandaCadastro = () => {
    document.location = "/MinhaConta";
  };

  const [Login, setLogin] = useState(1);

  return (
    <>
      <Header />

      {Login == 1 ? (
        <>
          <S.BgBanner>
            <S.ForaBanner>
              <S.ImagemBanner src={ImgBanner} />
            </S.ForaBanner>
          </S.BgBanner>

          <S.ForaImgInterna>
            <S.ImgInterna src={ImgPremiumInterna} />
          </S.ForaImgInterna>

          <S.BaixoVideo>
            <S.ForaBaixoVideo>
              <S.TituloVideo>
                Título do Vídeo Exemplo Página do PlayerPack -10 fotos
              </S.TituloVideo>
              <S.BotaoRodape onClick={() => MandaWhats()}>
                <S.ForaImgRodape>
                  <S.ImgRodape src={IconeWhats} />
                </S.ForaImgRodape>
                <S.TextBotaoRodape>
                  <span>+55 11</span> 95287-5123
                </S.TextBotaoRodape>
              </S.BotaoRodape>
            </S.ForaBaixoVideo>
          </S.BaixoVideo>

          <S.ForaExclusivo>
            <S.ForaFiltro>
              <S.RiscoFiltro></S.RiscoFiltro>

              <S.ItemFiltro>
                <S.TextItemFiltro>Todo os Conteúdos</S.TextItemFiltro>
                <S.ForaImgSeta>
                  <S.ImagemSeta src={ImgSeta} />
                </S.ForaImgSeta>
              </S.ItemFiltro>

              <S.RiscoFiltro2></S.RiscoFiltro2>

              <S.ItemFiltro>
                <S.TextItemFiltro>Fotos e Vídeos</S.TextItemFiltro>
                <S.ForaImgSeta>
                  <S.ImagemSeta src={ImgSeta} />
                </S.ForaImgSeta>
              </S.ItemFiltro>

              <S.RiscoFiltro></S.RiscoFiltro>
            </S.ForaFiltro>

            <S.ConteudoExclusivo>
              <S.ItemPremiumExclusivo>
                <S.ForaImgPremium1>
                  <S.ImagemPremium1 src={ImgPremium1} />
                </S.ForaImgPremium1>
                <S.TextExclusivo2>Título do conteúdo</S.TextExclusivo2>
                <S.TextExclusivo3>Pack -10 fotos</S.TextExclusivo3>
              </S.ItemPremiumExclusivo>

              <S.ItemPremiumExclusivo>
                <S.ForaImgPremium1>
                  <S.ImagemPremium1 src={ImgPremium1} />
                </S.ForaImgPremium1>
                <S.TextExclusivo2>Título do conteúdo</S.TextExclusivo2>
                <S.TextExclusivo3>Pack -10 fotos</S.TextExclusivo3>
              </S.ItemPremiumExclusivo>

              <S.ItemPremiumExclusivo>
                <S.ForaImgPremium1>
                  <S.ImagemPremium1 src={ImgPremium1} />
                </S.ForaImgPremium1>
                <S.TextExclusivo2>Título do conteúdo</S.TextExclusivo2>
                <S.TextExclusivo3>Pack -10 fotos</S.TextExclusivo3>
              </S.ItemPremiumExclusivo>

              <S.ItemPremiumExclusivo>
                <S.ForaImgPremium1>
                  <S.ImagemPremium1 src={ImgPremium1} />
                </S.ForaImgPremium1>
                <S.TextExclusivo2>Título do conteúdo</S.TextExclusivo2>
                <S.TextExclusivo3>Pack -10 fotos</S.TextExclusivo3>
              </S.ItemPremiumExclusivo>

              <S.ItemPremiumExclusivo>
                <S.ForaImgPremium1>
                  <S.ImagemPremium1 src={ImgPremium1} />
                </S.ForaImgPremium1>
                <S.TextExclusivo2>Título do conteúdo</S.TextExclusivo2>
                <S.TextExclusivo3>Pack -10 fotos</S.TextExclusivo3>
              </S.ItemPremiumExclusivo>

              <S.ItemPremiumExclusivo>
                <S.ForaImgPremium1>
                  <S.ImagemPremium1 src={ImgPremium1} />
                </S.ForaImgPremium1>
                <S.TextExclusivo2>Título do conteúdo</S.TextExclusivo2>
                <S.TextExclusivo3>Pack -10 fotos</S.TextExclusivo3>
              </S.ItemPremiumExclusivo>

              <S.ItemPremiumExclusivo>
                <S.ForaImgPremium1>
                  <S.ImagemPremium1 src={ImgPremium1} />
                </S.ForaImgPremium1>
                <S.TextExclusivo2>Título do conteúdo</S.TextExclusivo2>
                <S.TextExclusivo3>Pack -10 fotos</S.TextExclusivo3>
              </S.ItemPremiumExclusivo>

              <S.ItemPremiumExclusivo>
                <S.ForaImgPremium1>
                  <S.ImagemPremium1 src={ImgPremium1} />
                </S.ForaImgPremium1>
                <S.TextExclusivo2>Título do conteúdo</S.TextExclusivo2>
                <S.TextExclusivo3>Pack -10 fotos</S.TextExclusivo3>
              </S.ItemPremiumExclusivo>

              <S.ItemPremiumExclusivo>
                <S.ForaImgPremium1>
                  <S.ImagemPremium1 src={ImgPremium1} />
                </S.ForaImgPremium1>
                <S.TextExclusivo2>Título do conteúdo</S.TextExclusivo2>
                <S.TextExclusivo3>Pack -10 fotos</S.TextExclusivo3>
              </S.ItemPremiumExclusivo>

              <S.ItemPremiumExclusivo>
                <S.ForaImgPremium1>
                  <S.ImagemPremium1 src={ImgPremium1} />
                </S.ForaImgPremium1>
                <S.TextExclusivo2>Título do conteúdo</S.TextExclusivo2>
                <S.TextExclusivo3>Pack -10 fotos</S.TextExclusivo3>
              </S.ItemPremiumExclusivo>

              <S.ItemPremiumExclusivo>
                <S.ForaImgPremium1>
                  <S.ImagemPremium1 src={ImgPremium1} />
                </S.ForaImgPremium1>
                <S.TextExclusivo2>Título do conteúdo</S.TextExclusivo2>
                <S.TextExclusivo3>Pack -10 fotos</S.TextExclusivo3>
              </S.ItemPremiumExclusivo>

              <S.ItemPremiumExclusivo>
                <S.ForaImgPremium1>
                  <S.ImagemPremium1 src={ImgPremium1} />
                </S.ForaImgPremium1>
                <S.TextExclusivo2>Título do conteúdo</S.TextExclusivo2>
                <S.TextExclusivo3>Pack -10 fotos</S.TextExclusivo3>
              </S.ItemPremiumExclusivo>

              <S.ItemPremiumExclusivo>
                <S.ForaImgPremium1>
                  <S.ImagemPremium1 src={ImgPremium1} />
                </S.ForaImgPremium1>
                <S.TextExclusivo2>Título do conteúdo</S.TextExclusivo2>
                <S.TextExclusivo3>Pack -10 fotos</S.TextExclusivo3>
              </S.ItemPremiumExclusivo>

              <S.ItemPremiumExclusivo>
                <S.ForaImgPremium1>
                  <S.ImagemPremium1 src={ImgPremium1} />
                </S.ForaImgPremium1>
                <S.TextExclusivo2>Título do conteúdo</S.TextExclusivo2>
                <S.TextExclusivo3>Pack -10 fotos</S.TextExclusivo3>
              </S.ItemPremiumExclusivo>

              <S.ItemPremiumExclusivo>
                <S.ForaImgPremium1>
                  <S.ImagemPremium1 src={ImgPremium1} />
                </S.ForaImgPremium1>
                <S.TextExclusivo2>Título do conteúdo</S.TextExclusivo2>
                <S.TextExclusivo3>Pack -10 fotos</S.TextExclusivo3>
              </S.ItemPremiumExclusivo>

              <S.ItemPremiumExclusivo>
                <S.ForaImgPremium1>
                  <S.ImagemPremium1 src={ImgPremium1} />
                </S.ForaImgPremium1>
                <S.TextExclusivo2>Título do conteúdo</S.TextExclusivo2>
                <S.TextExclusivo3>Pack -10 fotos</S.TextExclusivo3>
              </S.ItemPremiumExclusivo>

              <S.ItemPremiumExclusivo>
                <S.ForaImgPremium1>
                  <S.ImagemPremium1 src={ImgPremium1} />
                </S.ForaImgPremium1>
                <S.TextExclusivo2>Título do conteúdo</S.TextExclusivo2>
                <S.TextExclusivo3>Pack -10 fotos</S.TextExclusivo3>
              </S.ItemPremiumExclusivo>

              <S.ItemPremiumExclusivo>
                <S.ForaImgPremium1>
                  <S.ImagemPremium1 src={ImgPremium1} />
                </S.ForaImgPremium1>
                <S.TextExclusivo2>Título do conteúdo</S.TextExclusivo2>
                <S.TextExclusivo3>Pack -10 fotos</S.TextExclusivo3>
              </S.ItemPremiumExclusivo>

              <S.ItemPremiumExclusivo>
                <S.ForaImgPremium1>
                  <S.ImagemPremium1 src={ImgPremium1} />
                </S.ForaImgPremium1>
                <S.TextExclusivo2>Título do conteúdo</S.TextExclusivo2>
                <S.TextExclusivo3>Pack -10 fotos</S.TextExclusivo3>
              </S.ItemPremiumExclusivo>

              <S.ItemPremiumExclusivo>
                <S.ForaImgPremium1>
                  <S.ImagemPremium1 src={ImgPremium1} />
                </S.ForaImgPremium1>
                <S.TextExclusivo2>Título do conteúdo</S.TextExclusivo2>
                <S.TextExclusivo3>Pack -10 fotos</S.TextExclusivo3>
              </S.ItemPremiumExclusivo>
            </S.ConteudoExclusivo>
          </S.ForaExclusivo>
        </>
      ) : (
        <>
          <S.BgLogin>
            <S.ForaLogin>
              <S.ForaImgText>
                <S.ImagemText src={ImgAreaPremium1} />
              </S.ForaImgText>
              <S.ForaBaixoLogin>
                <S.ForaFormLogin>
                  <S.TextForm1>
                    Entre e desfrute de todo o conteúdo exclusivo!
                  </S.TextForm1>
                  <form className="FormLogin" name="FormLogin" id="FormLogin">
                    <input
                      type="text"
                      placeholder="E-mail"
                      className="InputLogin"
                      name="c_email"
                      id="c_email"
                    />
                    <input
                      type="text"
                      placeholder="Senha"
                      className="InputLogin"
                      name="c_senha"
                      id="c_senha"
                    />
                    <S.BotaoFormLogin>
                      <S.ForaImgBotaoLogin>
                        <S.ImagemBotaoLogin src={ImgBotaoForm} />
                      </S.ForaImgBotaoLogin>
                      <S.TextBotaoLogin>entrar</S.TextBotaoLogin>
                    </S.BotaoFormLogin>
                  </form>
                  <S.TextForm2>
                    Esqueceu a senha? <span>Clique aqui.</span>
                  </S.TextForm2>
                </S.ForaFormLogin>
                <S.ForaImglogin1>
                  <S.ImagemLogin1 src={ImgLogin1} />
                </S.ForaImglogin1>
              </S.ForaBaixoLogin>
            </S.ForaLogin>
          </S.BgLogin>

          <S.FundoBaixoLogin>
            <S.ForaBaixoLogin2>
              <S.TextBaixoLogin1>AINDA NÃO É MEMBRO PREMIUM?</S.TextBaixoLogin1>
              <S.TextBaixoLogin2>
                ESCOLHA O MELHOR PLANO PARA VOCÊ
              </S.TextBaixoLogin2>
            </S.ForaBaixoLogin2>

            <S.ForaItensPremium>
              <S.ItemPremium style={{ backgroundColor: "#c44693" }}>
                <S.ForaImgItemPremium>
                  <S.ImagemItemPremium src={ImgItemPremium} />
                </S.ForaImgItemPremium>

                <S.ForaImgItemPremiumResp2>
                  <S.ForaImgItemPremiumResp>
                    <S.ImagemItemPremium src={ImgItemPremiumResp} />
                  </S.ForaImgItemPremiumResp>
                </S.ForaImgItemPremiumResp2>

                <S.ForaTextItemPremium>
                  <S.TextItemPremium1 style={{ color: "#491d74" }}>
                    Mensal
                  </S.TextItemPremium1>
                  <S.TextItemPremium2>
                    <span>R$</span> 54,90
                  </S.TextItemPremium2>
                  <S.TextItemPremium3>/ mês</S.TextItemPremium3>
                </S.ForaTextItemPremium>

                <S.ForaConteudoItemPremium>
                  <S.TextConteudoItemPremium>
                    <span>•</span> Assinatura área Vip Mensal
                  </S.TextConteudoItemPremium>
                  <S.TextConteudoItemPremium>
                    <span>•</span> Acesso a todos os meus conteúdos
                  </S.TextConteudoItemPremium>
                  <S.TextConteudoItemPremium>
                    <span>•</span> Vídeos exclusivos
                  </S.TextConteudoItemPremium>
                  <S.TextConteudoItemPremium>
                    <span>•</span> Atualizações semanais
                  </S.TextConteudoItemPremium>
                  <S.TextCobrado2>
                    * Cobrado R$ 54,90 mensalmente
                  </S.TextCobrado2>
                </S.ForaConteudoItemPremium>

                <S.ForaAssineJa
                  onClick={() => MandaCadastro()}
                  style={{ backgroundColor: "#a73b7e" }}
                >
                  <S.ForaImgAssineJa>
                    <S.ImgAssineJa src={ImgCoracao} />
                  </S.ForaImgAssineJa>
                  <S.TextAssineJa>Assine já</S.TextAssineJa>
                </S.ForaAssineJa>
                <S.TextCobrado>* Cobrado R$ 54,90 mensalmente</S.TextCobrado>
              </S.ItemPremium>

              <S.ForaAssineJa2
                onClick={() => MandaCadastro()}
                style={{ backgroundColor: "#a73b7e" }}
              >
                <S.ForaImgAssineJa>
                  <S.ImgAssineJa src={ImgCoracao} />
                </S.ForaImgAssineJa>
                <S.TextAssineJa>Assine já</S.TextAssineJa>
              </S.ForaAssineJa2>

              <S.ItemPremium style={{ backgroundColor: "#441b6d" }}>
                <S.ForaImgItemPremium>
                  <S.ImagemItemPremium src={ImgItemPremium2} />
                </S.ForaImgItemPremium>

                <S.ForaImgItemPremiumResp2>
                  <S.ForaImgItemPremiumResp>
                    <S.ImagemItemPremium src={ImgItemPremiumResp2} />
                  </S.ForaImgItemPremiumResp>
                </S.ForaImgItemPremiumResp2>

                <S.ForaTextItemPremium>
                  <S.TextItemPremium1 style={{ color: "#c34592" }}>
                    trimestral
                  </S.TextItemPremium1>
                  <S.TextItemPremium2>
                    <span>R$</span> 54,90
                  </S.TextItemPremium2>
                  <S.TextItemPremium3>/ mês</S.TextItemPremium3>
                </S.ForaTextItemPremium>

                <S.ForaConteudoItemPremium>
                  <S.TextConteudoItemPremium>
                    <span>•</span> Assinatura área Vip Mensal
                  </S.TextConteudoItemPremium>
                  <S.TextConteudoItemPremium>
                    <span>•</span> Acesso a todos os meus conteúdos
                  </S.TextConteudoItemPremium>
                  <S.TextConteudoItemPremium>
                    <span>•</span> Vídeos exclusivos
                  </S.TextConteudoItemPremium>
                  <S.TextConteudoItemPremium>
                    <span>•</span> Atualizações semanais
                  </S.TextConteudoItemPremium>
                  <S.TextCobrado2>
                    * Cobrado R$ 54,90 mensalmente
                  </S.TextCobrado2>
                </S.ForaConteudoItemPremium>

                <S.ForaAssineJa
                  onClick={() => MandaCadastro()}
                  style={{ backgroundColor: "#a73b7e" }}
                >
                  <S.ForaImgAssineJa>
                    <S.ImgAssineJa src={ImgCoracao} />
                  </S.ForaImgAssineJa>
                  <S.TextAssineJa>Assine já</S.TextAssineJa>
                </S.ForaAssineJa>
                <S.TextCobrado>* Cobrado R$ 54,90 mensalmente</S.TextCobrado>
              </S.ItemPremium>

              <S.ForaAssineJa2
                onClick={() => MandaCadastro()}
                style={{ backgroundColor: "#a73b7e" }}
              >
                <S.ForaImgAssineJa>
                  <S.ImgAssineJa src={ImgCoracao} />
                </S.ForaImgAssineJa>
                <S.TextAssineJa>Assine já</S.TextAssineJa>
              </S.ForaAssineJa2>

              <S.ItemPremium style={{ backgroundColor: "#c44693" }}>
                <S.ForaImgItemPremium>
                  <S.ImagemItemPremium src={ImgItemPremium3} />
                </S.ForaImgItemPremium>

                <S.ForaImgItemPremiumResp2>
                  <S.ForaImgItemPremiumResp>
                    <S.ImagemItemPremium src={ImgItemPremiumResp3} />
                  </S.ForaImgItemPremiumResp>
                </S.ForaImgItemPremiumResp2>

                <S.ForaTextItemPremium>
                  <S.TextItemPremium1 style={{ color: "#491d74" }}>
                    semestral
                  </S.TextItemPremium1>
                  <S.TextItemPremium2>
                    <span>R$</span> 54,90
                  </S.TextItemPremium2>
                  <S.TextItemPremium3>/ mês</S.TextItemPremium3>
                </S.ForaTextItemPremium>

                <S.ForaConteudoItemPremium>
                  <S.TextConteudoItemPremium>
                    <span>•</span> Assinatura área Vip Mensal
                  </S.TextConteudoItemPremium>
                  <S.TextConteudoItemPremium>
                    <span>•</span> Acesso a todos os meus conteúdos
                  </S.TextConteudoItemPremium>
                  <S.TextConteudoItemPremium>
                    <span>•</span> Vídeos exclusivos
                  </S.TextConteudoItemPremium>
                  <S.TextConteudoItemPremium>
                    <span>•</span> Atualizações semanais
                  </S.TextConteudoItemPremium>
                  <S.TextCobrado2>
                    * Cobrado R$ 54,90 mensalmente
                  </S.TextCobrado2>
                </S.ForaConteudoItemPremium>

                <S.ForaAssineJa
                  onClick={() => MandaCadastro()}
                  style={{ backgroundColor: "#a73b7e" }}
                >
                  <S.ForaImgAssineJa>
                    <S.ImgAssineJa src={ImgCoracao} />
                  </S.ForaImgAssineJa>
                  <S.TextAssineJa>Assine já</S.TextAssineJa>
                </S.ForaAssineJa>
                <S.TextCobrado>* Cobrado R$ 54,90 mensalmente</S.TextCobrado>
              </S.ItemPremium>

              <S.ForaAssineJa2
                onClick={() => MandaCadastro()}
                style={{ backgroundColor: "#a73b7e" }}
              >
                <S.ForaImgAssineJa>
                  <S.ImgAssineJa src={ImgCoracao} />
                </S.ForaImgAssineJa>
                <S.TextAssineJa>Assine já</S.TextAssineJa>
              </S.ForaAssineJa2>
            </S.ForaItensPremium>
          </S.FundoBaixoLogin>
        </>
      )}

      <S.ForaImgBaixoSobreMim>
        <S.ImagemBaixoSobreMim src={ImgBaixoSobreMim} />
      </S.ForaImgBaixoSobreMim>

      <Footer />
    </>
  );
}

export default AreaPremium;
