import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as S from "./Estilos";
import Snackbar from "@material-ui/core/Snackbar";
import { SnackbarContent } from "@material-ui/core";

function Snack(props) {
  const [status, setStatus] = useState(false);

  useEffect(() => {
    setStatus(props.status);
  }, [props.status]);

  return (
    <>
      <Snackbar
        ContentProps={{
          sx: {
            background: "red",
            fontFamily: "Exo",
            color: "#FFFFFF",
          },
        }}
        autoHideDuration={props.time ? props.time : 5000}
        message="Note archived"
        onClose={() => setStatus(false)}
        open={status}
      >
        <SnackbarContent
          message={
            <span id="client-snackbar" style={{ color: "#7F1A54!important" }}>
              {props.mensagem}
            </span>
          }
          style={{
            backgroundColor: "#FFFFFF",
            color: "#7F1A54",
          }}
        />
      </Snackbar>
    </>
  );
}

export default Snack;
