import styled from 'styled-components';
// import ImgLogo from "./Images/seta_baixo.png";


export const BgTopo = styled.div`
    background-color: #491d74;
    background-image: linear-gradient(to right, #491d74 , #24083f);
`;

export const ForaTopo = styled.div`
    width: 1310px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    height: 150px;
    align-items: center;

    @media (max-width: 1350px) {
        width: 1070px;
    }    

    @media (max-width: 1100px) {
        width: 960px;
    }    

    @media (max-width: 1000px) {
        width: 90%;
    } 

    @media (max-width: 500px) {
        height: 120px;
    } 
`;

export const MenuResp = styled.div`
    display: none;
    cursor: pointer;

    @media (max-width: 1000px) {
        display: block;
        width: 70px;
    }  

    @media (max-width: 500px) {
        width: 50px;
    }  
`;

export const ImgMenuResp = styled.img`

`;

export const ForaLogoTopo = styled.div`
    cursor: pointer;

    @media (max-width: 1350px) {
        width: 135px;
    }  

    @media (max-width: 1000px) {
        width: 190px;
    }  

    @media (max-width: 500px) {
        width: 145px;
    }  
`;

export const LogoTopo = styled.img`

`;

export const MenuTopo = styled.div`
    width: 55%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1350px) {
        width: 62%;
    }    

    @media (max-width: 1100px) {
        width: 67%;
    }    

    @media (max-width: 1000px) {
        display: none;
    }  
`;

export const TextMenuTopo = styled.div`
    font: 15px 'latoregular', Arial, sans-serif;
    color: #d04a9b;
    text-transform: uppercase;
    cursor: pointer;
`;

export const DireitaTopo = styled.div`
    display: flex;
    width: 25%;
    justify-content: space-between;

    @media (max-width: 1350px) {
        width: 22%;
    }  

    @media (max-width: 1100px) {
        width: 17%;
    } 

    @media (max-width: 1000px) {
        width: 70px;
    } 

    @media (max-width: 500px) {
        width: 55px;
    } 
`;

export const IconeDireitaTopo = styled.div`
    cursor: pointer;
    width: auto !important;

    @media (max-width: 1000px) {
        width: 100% !important;
    } 

    @media (max-width: 500px) {
        width: 145px;
    }  
`;

export const IconeTopo = styled.img`

`;








export const MenuMobile = styled.div`
    background-color: #24083f;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
`;

export const ForaMenuMobile = styled.div`
    width: 90%;
    margin: 0 auto;
`;

export const CimaMenuMobile = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    align-items: center;
`;

export const ForaImgMenuMobille = styled.div`
    @media (max-width: 500px) {
        width: 60px;
    }  
`;

export const ImagemMenuMobile = styled.img`

`;

export const ForaLogoMenuMobille = styled.div`
    cursor: pointer;

    @media (max-width: 500px) {
        width: 120px;
    }  
`;

export const ImagemLogoMobile = styled.img`

`;

export const FechaMenu = styled.div`
    font: 43px 'latoregular',Arial,sans-serif;
    color: #fff;
    background-color: #d04a9b;
    width: 70px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;

    @media (max-width: 500px) {
        font: 32px 'latoregular',Arial,sans-serif;
        width: 55px;
        height: 45px;
    }  
`;

export const ForaImgAreaPremiumMobile = styled.div`
     width: 90%;
    margin: 0 auto;
    padding-top: 35px;
`;

export const ImagemAreaPremiumMobile = styled.img`

`;

export const BotaoMobile = styled.div`
    display: flex;
    width: 215px;
    height: 60px;
    border: 2px solid #d04a9b;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 11px;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 25px;
    cursor: pointer;

    @media (max-width: 500px) {
        width: 155px;
        height: 45px;
        margin-top: 20px;
    } 
`;

export const ForaImgBotaoMobile = styled.div`
    @media (max-width: 500px) {
        width: 29px;
    }  
`;

export const ImagemBotaoMobile = styled.img`

`;

export const TextBotaoMobile = styled.div`
    font: 26px 'latoregular',Arial,sans-serif;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding-left: 15px;

    @media (max-width: 500px) {
        font: 21px 'latoregular',Arial,sans-serif;
        padding-left: 10px;
    }  
`;

export const TextMenuMobile = styled.div`
    font: 37px 'latoregular',Arial,sans-serif;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    border-top: 1px solid #bf4492;
    padding-top: 15px;
    padding-bottom: 15px;

    @media (max-width: 500px) {
        font: 28px 'latoregular',Arial,sans-serif;
    }  
`;

export const ForaImgBotaoWhats = styled.div`
    width: 320px;
    margin: 0 auto;
    margin-top: 10px;
    cursor: pointer;

    @media (max-width: 500px) {
        width: 270px;
    }  
`;

export const ImagemBotaoWhats = styled.img`

`;





/* POPUP POPUP POPUP POPUP POPUP POPUP POPUP */


export const FundoPopUp = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
`;

export const PopIdade = styled.div`
    width: 950px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 12px;

    @media (max-width: 1050px) {
        width: 585px;
    }  

    @media (max-width: 630px) {
        width: 90%;
    }  
`;

export const ForaPopIdade = styled.div`
    width: 95%;
    margin: 0 auto;
    background: -webkit-linear-gradient(#f3f3f3,#ffff);
    border-radius: 12px;
    margin-top: 22px;
`;

export const ForaImgIdade = styled.div`
   width: 195px;
   margin: 0 auto;

   @media (max-width: 470px) {
        width: 140px;
    }  
`;

export const ImagemIdade = styled.img`
    margin-top: -90px;
`;

export const TextPopIdade1 = styled.div`
    font: 25px 'latomedium',Arial,sans-serif;
    color: #696969;
    text-align: center;

    @media (max-width: 1050px) {
        width: 90%;
        margin: 0 auto;
    }  

    @media (max-width: 470px) {
        font-size: 20px;
    }  
`;

export const TextPopIdade2 = styled.div`
    font: 60px 'latomedium',Arial,sans-serif;
    color: #491d74;
    font-weight: bold;
    font-style: italic;
    text-align: center;

    @media (max-width: 1050px) {
        font-size: 38px;
        margin-top: 10px;
    }  

    @media (max-width: 470px) {
        font-size: 27px;
    }  
`;

export const TextPopCookie2 = styled.div`
    font: 55px 'latomedium',Arial,sans-serif;
    color: #491d74;
    font-weight: bold;
    font-style: italic;
    text-align: center;
    padding-top: 25px;

    @media (max-width: 1050px) {
        font-size: 38px;
        margin-top: 10px;
    }  

    @media (max-width: 470px) {
        font-size: 27px;
    }  
`;

export const TextPopCookie3 = styled.div`
    font: 22px 'latoregular',Arial,sans-serif;
    color: #696969;
    text-align: center;
    width: 70%;
    margin: 0 auto;
    padding-top: 15px;
    padding-bottom: 5px;

    @media (max-width: 1050px) {
        font-size: 18px;
        width: 80%;
    }  

    @media (max-width: 500px) {
        width: 90%;
    }  

`;

export const BotaoSim = styled.div`
    font: 30px 'latoblack',Arial,sans-serif;
    color: #fff;
    background-color: #d04a9b;
    width: 430px;
    height: 80px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: center;
    font-weight: bold;
    margin: 0 auto;
    border-radius: 12px;
    margin-top: 20px;
    cursor: pointer;

    @media (max-width: 1050px) {
        height: 60px;
        font-size: 22px;
    }  

    @media (max-width: 530px) {
        width: 90%;
    }  
`;

export const BotaoNao = styled.div`
    font: 30px 'latoblack',Arial,sans-serif;
    color: #fff;
    background-color: #6f6f6f;
    width: 430px;
    height: 80px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: center;
    font-weight: bold;
    margin: 0 auto;
    border-radius: 12px;
    margin-top: 20px;
    margin-bottom: 65px;
    cursor: pointer;

    @media (max-width: 1050px) {
        height: 60px;
        font-size: 22px;
        margin-bottom: 45px;
    }  

    @media (max-width: 530px) {
        width: 90%;
    }  
`;



export const TextPopCadastroMaior = styled.div`
    font: 55px 'latomedium',Arial,sans-serif;
    color: #491d74;
    font-weight: bold;
    font-style: italic;
    text-align: center;

    @media (max-width: 1050px) {
        font-size: 38px;
    }  

    @media (max-width: 470px) {
        font-size: 27px;
    }  
`;

export const TextPopCadastro1 = styled.div`
    font: 30px 'latomedium',Arial,sans-serif;
    color: #d04a9b;
    font-weight: bold;
    font-style: italic;
    text-align: center;

    @media (max-width: 630px) {
        font-size: 25px;
    }  
`;

export const ForaQuadradoParabens = styled.div`
    width: 265px;
    border: 1px solid #000;
    height: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 15px;

    @media (max-width: 1050px) {
        width: 225px;
        height: 212px;
    }  
`;

export const QuadradoParabens = styled.div`
    background-color: #494949;
    width: 230px;
    height: 225px;

    @media (max-width: 1050px) {
        width: 200px;
        height: 190px;
    }  
`;

export const TextPopCadastro2 = styled.div`
    font: 35px 'latoregular',Arial,sans-serif;
    color: #101010;
    text-transform: uppercase;
    text-align: center;
    margin-top: 20px;

    @media (max-width: 1050px) {
        font-size: 30px;
    }  

    @media (max-width: 630px) {
        font-size: 25px;
    }  
`;

export const ForaCopiarCodigo = styled.div`
    display: flex;
    width: 350px;
    background-color: #d04a9b;
    height: 55px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    margin-top: 10px;
    cursor: pointer;

    @media (max-width: 630px) {
        width: 250px;
        height: 50px;
    }  
`;

export const ForaImgCopiarCodigo = styled.div`
    @media (max-width: 630px) {
        width: 30px;
    }  
`;

export const ImagemCopiarCodigo = styled.img`

`;

export const TextCopiarCodigo = styled.div`
    font: 25px 'latoblack',Arial,sans-serif;
    color: #fff;
    text-transform: uppercase;

    @media (max-width: 630px) {
        font-size: 20px;
    } 
`;

export const TextPopCadastro3 = styled.div`
    font: 25px 'latomedium',Arial,sans-serif;
    color: #491d74;
    font-weight: bold;
    font-style: italic;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;

    @media (max-width: 630px) {
        font-size: 20px;
        font-size: 20px;
        margin-top: 15px;
        margin-bottom: 20px;
    }  
`;


