const requestHeaders = new Headers();
requestHeaders.set("Content-Type", "application/json");
const baseUrl = "https://lizzysweet.com.br:8085";

export const getCard = async (card) => {
  const response = await fetch(
    `https://api.mercadopago.com/v1/payment_methods/search?public_key=APP_USR-e8609f33-8504-462b-b325-7fae4d874a73&locale=en-US&js_version=2.0.0&referer=http%3A%2F%2Flocalhost%3A8080&marketplace=NONE&status=active&bins=${card
      .substring(0, 9)
      .replace(" ", "")}&processing_mode=aggregator`,

    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  const jsonResult = await response.json();
  return jsonResult;
};

export const getCardFlag = async (card) => {
  const response = await fetch(
    `https://api.mercadopago.com/v1/payment_methods/installments?public_key=APP_USR-e8609f33-8504-462b-b325-7fae4d874a73&locale=en-US&js_version=2.0.0&referer=http%3A%2F%2Flocalhost%3A8080&bin=${card
      .substring(0, 9)
      .replace(
        " ",
        ""
      )}&processing_mode=aggregator&payment_type_id=credit_card&amount=10`,

    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  const jsonResult = await response.json();
  return jsonResult;
};

export const getCardToken = async (myData) => {
  const response = await fetch(
    `https://api.mercadopago.com/v1/card_tokens?public_key=APP_USR-e8609f33-8504-462b-b325-7fae4d874a73&locale=en-US&js_version=2.0.0&referer=http%3A%2F%2Flocalhost%3A8080`,
    {
      method: "POST",
      headers: requestHeaders,
      body: myData,
    }
  );

  const jsonResult = await response.json();
  return jsonResult;
};
