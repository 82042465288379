import React, { useEffect, useState, useContext, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as S from "./Estilos";
import Header from "../../Componentes/Header/";
import Load from "../../Componentes/Load";
import Footer from "../../Componentes/Footer/";
import {
  AlertForm,
  AlertFormPagamentoPix,
  AlertFormPagamentoPixRenova,
  AlertFormPagamentoBoleto,
} from "../../Componentes/Funcoes";
import { Oval } from "react-loader-spinner";
import ViewerContext from "../../context/context";
import { getAssinatura } from "../../utils/getAssinatura";
import ImgBaixoSobreMim from "../../Images/SobreMim/ImgBaixoSobreMim.png";
import { getData, postData } from "../../utils/useData";
import Snack from "../../Componentes/Snack";

function Cadastro(props) {
  const history = useHistory();
  const [context, setContext] = useContext(ViewerContext);
  const [mensagem, setMensagem] = useState("");
  const [status, setStatus] = useState(false);
  const [load, setLoad] = useState(true);
  const [code, setCode] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [senha, setSenha] = useState("");
  const [qrcodebase64, setQrcodebase64] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [mostraBotao, setMostraBotao] = useState(false);

  const page = useRef(1);
  const id = useRef(0);

  useEffect(() => {
    setLoad(true);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    EditCadastro();
  }, []);

  const EditCadastro = async () => {
    const data = JSON.stringify({
      id_tb_user: localStorage.getItem("id_tb_user"),
    });

    const response = await postData("/pagamento/cadastro_edit", data);
    setNome(response.res.c_nome);
    setEmail(response.res.c_email);
    setCpf(response.res.c_cpf);
    setSenha(response.senha);
    setLoad(false);
  };

  const onSubmitCadastro = async (data, e) => {
    setLoad(true);

    if (nome === "" || nome === undefined) {
      AlertForm("O campo Nome é de preenchimento obrigatório!");
      setLoad(false);
    } else if (cpf === "" || cpf === undefined) {
      AlertForm("O campo CPF é de preenchimento obrigatório!");
      setLoad(false);
    } else if (senha === "" || senha === undefined) {
      AlertForm("O campo Senha é de preenchimento obrigatório!");
      setLoad(false);
    } else if (email === "" || email === undefined) {
      AlertForm("O campo E-MAIL é de preenchimento obrigatório!");
      setLoad(false);
      //e.preventDefault();
    } else if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      //AlertForm("Enviado com sucesso!");
      setLoad(true);

      const data = JSON.stringify({
        id_tb_user: localStorage.getItem("id_tb_user"),
        c_nome: nome,
        c_email: email,
        c_cpf: cpf,
        c_senha: senha,
      });

      const response = await postData("/pagamento/cadastro_edit_submit", data);

      if (response.tipo === "duplicado") {
        setLoad(false);
        AlertForm(
          "E-mail já cadastrado no sistema, por favor efetue login para prosseguir!"
        );
      } else {
        setContext({
          ...context,
          logged: true,
          id_tb_user: response.id_tb_user,
        });
        // AlertFormPagamento(response.qr_code, response.qr_code_base64);
        setMensagem("Cadastro atualizado com sucesso!");
        setStatus(true);
        setTimeout(() => {
          setStatus(false);
        }, 5000);
        //setStatus(true);
        setQrCode(response.qr_code);
        setQrcodebase64(response.qr_code_base64);
        //setQrVisible(true);
        setLoad(false);
      }
    } else {
      AlertForm("O campo E-MAIL está com o formato incorreto!");
      setLoad(false);
      //e.preventDefault();
    }
  };
  const AbreCadastro = () => {
    history.push("/Cadastro");
  };

  const AbreAssinaturas = () => {
    history.push("/Assinaturas");
  };

  return (
    <>
      <Snack mensagem={mensagem} status={status} />
      <Load status={load} />

      <Header tipo={"Cadastro"} />

      <S.ForaBotaoEditaCadastro>
        <S.BotaoEditaCadastro onClick={() => AbreCadastro(1)}>
          Editar Cadastro
        </S.BotaoEditaCadastro>
        <S.BotaoEditaCadastro onClick={() => AbreAssinaturas(1)}>
          Minhas assinaturas
        </S.BotaoEditaCadastro>
      </S.ForaBotaoEditaCadastro>

      <>
        <S.ForaFormCadastro id="FormularioCadastro">
          <>
            <S.ItemForm>
              <S.TextItemForm>NOME</S.TextItemForm>
              <input
                onChange={(e) => setNome(e.target.value)}
                className="InputFormCadastro"
                name="c_nome"
                id="c_nome"
                value={nome}
              />
            </S.ItemForm>
            <S.ItemForm>
              <S.TextItemForm>CPF</S.TextItemForm>
              <input
                onChange={(e) => setCpf(e.target.value)}
                className="InputFormCadastro"
                name="c_cpf"
                id="c_cpf"
                value={cpf}
              />
            </S.ItemForm>
            <S.ItemForm>
              <S.TextItemForm>E-MAIL</S.TextItemForm>
              <input
                onChange={(e) => setEmail(e.target.value)}
                className="InputFormCadastro"
                name="c_email"
                id="c_email"
                value={email}
              />
            </S.ItemForm>
            <S.ItemForm>
              <S.TextItemForm>SENHA</S.TextItemForm>
              <input
                onChange={(e) => setSenha(e.target.value)}
                className="InputFormCadastro"
                name="c_senha"
                id="c_senha"
                type={"password"}
                value={senha}
              />
            </S.ItemForm>

            <button
              onClick={() => onSubmitCadastro()}
              className="BotaoCadastrar"
            >
              Gravar Dados
            </button>
          </>
        </S.ForaFormCadastro>
      </>

      <S.ForaImgBaixoSobreMim>
        <S.ImagemBaixoSobreMim src={ImgBaixoSobreMim} />
      </S.ForaImgBaixoSobreMim>

      <Footer />
    </>
  );
}

export default Cadastro;
