const requestHeaders = new Headers();
requestHeaders.set("Content-Type", "application/json");
const baseUrl = "https://lizzysweet.com.br:8085";

export const getAssinatura = function (id_tb_user) {
  const data = JSON.stringify({
    id_tb_user: id_tb_user,
  });

  return new Promise(async function (resolve, reject) {
    await fetch(`${baseUrl}/pagamento/verifica_assinatura`, {
      method: "POST",
      headers: requestHeaders,
      body: data,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          resolve(true);
        } else {
          if (responseJson.renovacao) {
            //alert('renovacao true');
            resolve(false);
          } else {
            //alert('renovacao false');
            resolve(false);
          }
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
