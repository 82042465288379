import styled from "styled-components";
import FundoLogin from "../../Images/AreaPremium/BgLogin.jpg";
// import ImgLogo from "./Images/seta_baixo.png";

export const ForaBanner = styled.div`
  margin-bottom: -4px;

  @media (max-width: 750px) {
    display: none;
  }
`;

export const ForaBannerResp = styled.div`
  display: none;

  @media (max-width: 750px) {
    display: block;
    margin-bottom: -4px;
  }
`;

export const ImagemBanner = styled.img``;

export const FundoPremiumCima = styled.div`
  background-color: #f6f6f6;
`;

export const ForaPremiumCima = styled.div`
  padding-top: 60px;
  padding-bottom: 80px;

  @media (max-width: 660px) {
    padding-top: 38px;
    padding-bottom: 45px;
  }
`;

export const TextPremiumCima1 = styled.div`
  font-family: "Lato";
  font-size: 37px;
  font-weight: bold;
  font-style: italic;
  color: #491d74;
  text-align: center;
  padding-bottom: 50px;

  @media (max-width: 880px) {
    padding-bottom: 35px;
  }

  @media (max-width: 430px) {
    font-size: 31px;
  }
`;

export const ForaConteudoPremiumCima = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1600px;
  margin: 0 auto;

  @media (max-width: 1630px) {
    width: 1220px;
  }

  @media (max-width: 1250px) {
    width: 850px;
  }

  @media (max-width: 880px) {
    width: 630px;
  }

  @media (max-width: 660px) {
    display: none;
  }
`;

export const ItemPremiumCima = styled.div`
  width: 32%;
`;

export const ForaImgPremium1 = styled.div``;

export const ImagemPremium1 = styled.img``;

export const TextPremiumCima2 = styled.div`
  font-family: "Lato";
  font-size: 25px;
  font-weight: bold;
  font-style: italic;
  color: #491d74;
  text-align: center;
  text-transform: uppercase;
  padding-top: 30px;

  @media (max-width: 1250px) {
    font-size: 20px;
    padding-top: 10px;
  }

  @media (max-width: 880px) {
    font-size: 17px;
  }
`;

export const TextPremiumCima3 = styled.div`
  font-family: "Lato";
  font-size: 25px;
  color: #777777;
  text-align: center;

  @media (max-width: 1250px) {
    font-size: 20px;
  }

  @media (max-width: 880px) {
    font-size: 17px;
  }
`;

export const ForaExclusivo = styled.div`
  width: 1430px;
  margin: 0 auto;
  padding-bottom: 130px;
  padding-top: 90px;

  @media (max-width: 1460px) {
    width: 1070px;
  }

  @media (max-width: 1100px) {
    width: 720px;
    padding-top: 50px;
  }

  @media (max-width: 760px) {
    width: 90%;
  }

  @media (max-width: 480px) {
    padding-bottom: 75px;
  }
`;

export const TextExclusivo1 = styled.div`
  font-family: "Lato";
  font-size: 37px;
  color: #d04a9b;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 28px;
  }
`;

export const ForaFiltro = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 60px;

  @media (max-width: 1100px) {
    padding-top: 40px;
  }

  @media (max-width: 480px) {
    flex-wrap: wrap;
    padding-top: 20px;
  }
`;

export const RiscoFiltro = styled.div`
  width: 150px;
  background-color: #cdcdcd;
  height: 2px;

  @media (max-width: 1460px) {
    width: 115px;
  }

  @media (max-width: 1100px) {
    width: 80px;
  }

  @media (max-width: 760px) {
    display: none;
  }
`;

export const RiscoFiltro2 = styled.div`
  width: 90px;
  background-color: #cdcdcd;
  height: 2px;

  @media (max-width: 1100px) {
    width: 60px;
  }

  @media (max-width: 760px) {
    display: none;
  }
`;

export const ItemFiltro = styled.div`
  display: flex;
  background-color: #f6f6f6;
  width: 480px;
  justify-content: space-between;
  height: 70px;
  align-items: center;
  border-radius: 24px;
  cursor: pointer;

  @media (max-width: 1460px) {
    width: 335px;
  }

  @media (max-width: 1100px) {
    width: 215px;
    height: 55px;
  }

  @media (max-width: 480px) {
    width: 100%;
    margin-top: 15px;
  }
`;

export const TextItemFiltro = styled.div`
  font-family: "Lato";
  font-size: 20px;
  color: #24083f;
  padding-left: 35px;

  @media (max-width: 1100px) {
    font-size: 17px;
    padding-left: 20px;
  }
`;

export const ForaImgSeta = styled.div`
  padding-right: 35px;

  @media (max-width: 1100px) {
    padding-right: 20px;
  }
`;

export const ImagemSeta = styled.img``;

export const ConteudoExclusivo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  padding-top: 40px;

  @media (max-width: 480px) {
    padding-top: 20px;
  }
`;

export const ItemPremiumExclusivo = styled.div`
  width: 24%;
  padding-top: 30px;

  @media (max-width: 760px) {
    width: 32%;
  }

  @media (max-width: 480px) {
    width: 48%;
  }
`;

export const TextExclusivo2 = styled.div`
  font-family: "Lato";
  font-size: 17px;
  font-weight: bold;
  font-style: italic;
  color: #491d74;
  text-align: center;
  text-transform: uppercase;
  padding-top: 10px;
`;

export const TextExclusivo3 = styled.div`
  font-family: "Lato";
  font-size: 17px;
  color: #777777;
  text-align: center;
`;

export const ForaImgBaixoSobreMim = styled.div`
  margin-top: -48px;
  margin-bottom: -95px;

  @media (max-width: 670px) {
    margin-bottom: -35px;
  }

  @media (max-width: 450px) {
    margin-bottom: -25px;
  }
`;

export const ImagemBaixoSobreMim = styled.img``;

/* LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN  */

export const BgLogin = styled.div`
  background-image: url(${FundoLogin});
  padding-top: 100px;
`;

export const ForaLogin = styled.div`
  width: 990px;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 60px;

  @media (max-width: 1050px) {
    width: 700px;
    padding-top: 45px;
  }

  @media (max-width: 720px) {
    width: 85%;
    padding-top: 45px;
  }

  @media (max-width: 420px) {
    padding-top: 20px;
  }
`;

export const ForaImgText = styled.div`
  width: 890px;
  margin: 0 auto;

  @media (max-width: 1050px) {
    width: 100%;
  }
`;

export const ImagemText = styled.img``;

export const ForaBaixoLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 720px) {
    flex-wrap: wrap;
  }
`;

export const ForaFormLogin = styled.div`
  width: 44%;

  @media (max-width: 720px) {
    width: 100%;
  }
`;

export const TextForm1 = styled.div`
  font-family: "Lato";
  font-weight: bold;
  font-style: italic;
  font-size: 37px;
  color: #fff;
  line-height: 33px;

  @media (max-width: 1050px) {
    font-size: 30px;
  }

  @media (max-width: 450px) {
    font-size: 25px;
    line-height: 25px;
  }
`;

export const BotaoFormLogin = styled.div`
  display: flex;
  background-color: #24083f;
  height: 70px;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  cursor: pointer;

  @media (max-width: 1050px) {
    height: 60px;
  }

  @media (max-width: 720px) {
    height: 50px;
  }
`;

export const ForaImgBotaoLogin = styled.div``;

export const ImagemBotaoLogin = styled.img``;

export const TextBotaoLogin = styled.div`
  font-family: "Lato";
  font-weight: bold;
  font-size: 23px;
  text-transform: uppercase;
  padding-left: 15px;
`;

export const TextForm2 = styled.div`
  font-family: "Lato";
  font-size: 17px;
  padding-top: 15px;
  cursor: pointer;

  span {
    text-decoration: underline;
  }

  @media (max-width: 500px) {
    padding-top: 25px;
  }
`;

export const ForaImglogin1 = styled.div`
  width: 52%;
  margin-top: -78px;

  @media (max-width: 720px) {
    width: 75%;
    margin: 0 auto;
    margin-top: 23px;
    margin-bottom: -135px;
  }
`;

export const ImagemLogin1 = styled.img``;

export const FundoBaixoLogin = styled.div`
  background-color: #f6f6f6;

  @media (max-width: 720px) {
    padding-bottom: 40px;
  }
`;

export const ForaBaixoLogin2 = styled.div`
  margin: 0 auto;
`;

export const TextBaixoLogin1 = styled.div`
  font-family: "Lato";
  font-weight: bold;
  font-size: 37px;
  text-transform: uppercase;
  color: #24083f;
  font-style: italic;
  text-align: center;
  padding-top: 80px;

  @media (max-width: 600px) {
    font-size: 28px;
    padding-top: 95px;
  }

  @media (max-width: 450px) {
    font-size: 23px;
  }
`;

export const TextBaixoLogin2 = styled.div`
  text-align: center;
  font-family: "Lato";
  font-weight: bold;
  font-size: 25px;
  text-transform: uppercase;
  color: #d04a9b;
  font-style: italic;
  padding-bottom: 60px;

  @media (max-width: 600px) {
    font-size: 20px;
    padding-bottom: 40px;
  }

  @media (max-width: 450px) {
    font-size: 17px;
    padding-top: 5px;
  }
`;

export const ForaItensPremium = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 130px;
  width: 1255px;
  margin: 0 auto;

  @media (max-width: 1290px) {
    width: 820px;
    padding-top: 60px;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    padding-top: 30px;
  }

  @media (max-width: 860px) {
    width: 90%;
    padding-top: 0px;
  }
`;

export const ItemPremium = styled.div`
  width: 31%;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;

  @media (max-width: 1175px) {
    width: 32.5%;
  }

  @media (max-width: 900px) {
    width: 94%;
    display: flex;
    margin-top: 16px;
    align-items: center;
    padding: 25px;
    justify-content: space-between;
  }

  @media (max-width: 795px) {
    padding-bottom: 35px;
    width: 90%;
    margin: 0 auto;
  }

  @media (max-width: 650px) {
    flex-wrap: wrap;
    padding-bottom: 45px;
  }

  @media (max-width: 500px) {
    padding: 0px;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 40px;
    border-radius: 12px;
  }
`;

export const ForaImgItemPremium = styled.div`
  width: 85%;
  margin: 0 auto;
  margin-top: -88px;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const ForaImgItemPremiumResp = styled.div`
  display: none;

  @media (max-width: 900px) {
    display: block;
  }

  @media (max-width: 795px) {
    width: 130px;
  }

  @media (max-width: 500px) {
    width: 160px;
  }
`;

export const ForaImgItemPremiumResp2 = styled.div`
  @media (max-width: 650px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const ImagemItemPremium = styled.img``;

export const ForaTextItemPremium = styled.div`
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const TextItemPremium1 = styled.div`
  font-family: "Lato";
  font-size: 37px;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  margin-top: 30px;

  @media (max-width: 900px) {
    margin-top: 0px;
    font-size: 30px;
  }
`;

export const TextItemPremium2 = styled.div`
  font-family: "Lato";
  font-size: 37px;
  font-weight: 900;
  color: #fff;
  text-align: center;

  span {
    font-weight: 400;
  }
`;

export const TextItemPremium3 = styled.div`
  font-family: "Lato";
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
`;

export const ForaConteudoItemPremium = styled.div`
  width: 63%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;

  @media (max-width: 900px) {
    margin: unset;
    height: 128px;
    width: 284px;
  }

  @media (max-width: 795px) {
    height: 115px;
    width: 236px;
  }

  @media (max-width: 500px) {
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 0px;
  }
`;

export const TextConteudoItemPremium = styled.div`
  font-family: "Lato";
  font-size: 15px;
  line-height: 22px;

  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 25px;
  }

  @media (max-width: 795px) {
    font-size: 15px;
    line-height: 22px;
  }
`;

export const ForaAssineJa = styled.div`
  display: flex;
  width: 130px;
  height: 40px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  margin-bottom: 30px;
  cursor: pointer;

  :hover {
    cursor: pointer;
    background-image: linear-gradient(#ff63d8, #aa3d8f);
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const ForaAssineJa2 = styled.div`
  display: none;
  width: 130px;
  height: 40px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  margin-bottom: 30px;
  cursor: pointer;

  :hover {
    cursor: pointer;
    background-image: linear-gradient(#ff63d8, #aa3d8f);
  }

  @media (max-width: 900px) {
    display: flex;
    background-image: linear-gradient(#ff63d8, #aa3d8f);
    margin-top: -21px;
  }
`;

export const ForaImgAssineJa = styled.div`
  margin-top: 3px;
  margin-right: 5px;
`;

export const ImgAssineJa = styled.img``;

export const TextAssineJa = styled.div`
  font-family: "Lato";
  font-size: 15px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
`;

export const TextCobrado = styled.div`
  font-family: "Lato";
  font-size: 13px;
  text-align: center;
  padding-bottom: 50px;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const TextCobrado2 = styled.div`
  font-family: "Lato";
  font-size: 13px;
  padding-bottom: 50px;
  display: none;

  @media (max-width: 900px) {
    display: block;
    font-size: 15px;
    padding-top: 10px;
  }

  @media (max-width: 795px) {
    padding-top: 8px;
  }
`;
