import styled from "styled-components";

// import ImgLogo from "./Images/seta_baixo.png";

export const ForaBanner = styled.div`
  margin-bottom: -4px;
  padding-top: 150px;

  @media (max-width: 750px) {
    display: none;
  }
`;

export const ForaBannerResp = styled.div`
  display: none;
  padding-top: 119px;

  @media (max-width: 750px) {
    display: block;
    margin-bottom: -4px;
  }
`;

export const ImagemBanner = styled.img``;

export const ForaConteudoSobreMim = styled.div`
  width: 1370px;
  margin: 0 auto;

  @media (max-width: 1430px) {
    width: 1100px;
  }

  @media (max-width: 1160px) {
    width: 900px;
  }

  @media (max-width: 950px) {
    width: 650px;
  }

  @media (max-width: 700px) {
    width: 90%;
  }
`;

export const BgMinhasFotos = styled.div`
  background-color: #491d74;
`;

export const ForaMinhasFotos = styled.div`
  width: 94%;
  margin: 0 auto;
  padding-bottom: 150px;
  padding-top: 70px;

  @media (max-width: 1500px) {
    padding-bottom: 265px;
  }

  @media (max-width: 850px) {
    width: 100%;
  }

  @media (max-width: 1175px) {
    padding-bottom: 170px;
  }

  @media (max-width: 900px) {
    padding-bottom: 20vw;
  }
`;

export const ForaImgTextMinhasFotos = styled.div`
  width: 305px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 55px;

  @media (max-width: 550px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;
export const ImgTextMinhasFotos = styled.img``;

export const ForaGaleria = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ForaFoto = styled.div`
  background-position: top center;
  height: 15vw;
  background-size: cover;

  :hover {
    filter: unset;
  }

  filter: opacity(0.4) drop-shadow(0 0 0 purple) grayscale(100%)
    brightness(1.75);
  cursor: pointer;
  margin-top: 0px;
  width: 25%;

  @media (max-width: 900px) {
    width: 33.3%;
    height: 22vw;
  }

  @media (max-width: 550px) {
    width: 33.3%;
  }
`;

export const BgImgMaior = styled.div`
  background-color: #24083f;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const XImg = styled.div`
  position: absolute;
  top: 30px;
  right: 65px;
  font-family: "Lato";
  font-size: 30px;
  font-weight: 900;
  cursor: pointer;

  @media (max-width: 670px) {
    right: 20px;
  }
`;

export const ForaImgMaior = styled.div`
  width: 1000px;
  margin: 0 auto;

  @media (max-width: 1050px) {
    width: 600px;
  }

  @media (max-width: 670px) {
    width: 90%;
  }
`;

export const ImgMaior = styled.img``;

export const ImgFoto = styled.img`
  margin-bottom: -3px;
`;

export const MaisFotos = styled.div`
  width: 255px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: 1px solid #fff;
  margin-top: 85px;
  height: 55px;
  border-radius: 10px;

  @media (max-width: 850px) {
    margin-top: 55px;
  }

  @media (max-width: 500px) {
    margin-top: 30px;
    height: 45px;
    width: 190px;
  }
`;

export const TextMaisFotos = styled.div`
  cursor: pointer;

  span {
    color: #d04a9b;
  }

  font-family: "Lato";
  font-size: 21px;
  font-weight: 900;
  text-transform: uppercase;
`;

export const ForaEntreContato = styled.div`
  background-color: #f7f7f7;
  height: 310px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 120px;

  @media (max-width: 950px) {
    margin-top: 60px;
    height: 230px;
    padding-bottom: 35px;
  }

  @media (max-width: 450px) {
    height: 150px;
    padding-bottom: 53px;
    margin-top: 25px;
  }
`;

export const TextEntreContato1 = styled.div`
  color: #549c00;
  font-family: "Lato";
  font-size: 37px;
  font-weight: bold;
  font-style: italic;
  text-align: center;

  @media (max-width: 450px) {
    font-size: 30px;
  }
`;

export const ForaBotaoEntreContato = styled.div`
  display: flex;
  width: 430px;
  margin: 0 auto;
  background-color: #549c00;
  align-items: center;
  justify-content: center;
  height: 95px;
  border-radius: 14px;
  margin-top: 30px;

  @media (max-width: 950px) {
    height: 75px;
    margin-top: 20px;
  }

  @media (max-width: 510px) {
    width: 360px;
  }

  @media (max-width: 450px) {
    width: 250px;
    height: 60px;
  }
`;

export const ForaImgWhats = styled.div`
  @media (max-width: 450px) {
    width: 45px;
  }
`;

export const ImagemWhats = styled.img``;

export const TextEntreContato2 = styled.div`
  font-family: "Lato";
  font-size: 30px;
  color: #fff;
  padding-left: 10px;

  span {
    font-weight: bold;
  }

  @media (max-width: 450px) {
    font-size: 20px;
    padding-left: 0px;
  }
`;

export const ForaImgBaixoSobreMim = styled.div`
  margin-top: -48px;
  margin-bottom: -95px;

  @media (max-width: 670px) {
    margin-bottom: -35px;
  }

  @media (max-width: 450px) {
    margin-bottom: -25px;
  }
`;

export const ImagemBaixoSobreMim = styled.img``;
