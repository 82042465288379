import styled from "styled-components";
import ImgBgSobreMim from "./Images/BgSobreMim.jpg";
import ImgBgDiario from "./Images/BgDiario.png";
import BgDiarioResp from "./Images/BgDiarioResp.png";
import ImgBgFeedback from "./Images/BgFeedback.png";
import ImgBgFeedbackResp from "./Images/BgFeedbackResp.png";
import ImgBgFeedbackResp2 from "./Images/BgFeedbackResp2.png";

/* BANNER BANNER BANNER BANNER BANNER */

export const ForaFormCadastro = styled.div`
  width: 815px;
  margin: 0 auto;
  padding-top: 45px;

  @media (max-width: 860px) {
    width: 590px;
  }

  @media (max-width: 640px) {
    width: 88%;
  }
`;

export const Fora2Form = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;

export const ItemForm = styled.div`
  margin-top: 17px;
`;

export const ItemForm2 = styled.div`
  margin-top: 17px;
  display: flex;
`;

export const TextItemForm = styled.div`
  font-family: "Lato";
  font-size: 16px;
  color: #491d74;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 8px;
`;

export const TextItemForm2 = styled.div`
  font-family: "Lato";
  font-size: 16px;
  color: #491d74;
  padding-left: 5px;

  a {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const TextFormCadastro1 = styled.div`
  font-family: "Lato";
  font-size: 18px;
  color: #d04a9b;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-top: 25px;

  span {
    font-size: 18px;
    color: #491d74;
    font-weight: bold;
  }

  @media (max-width: 500px) {
    margin-bottom: 20px;
    font-size: 16px;
  }
`;

export const ForaBanner = styled.div`
  padding-top: 62px;

  @media (max-width: 500px) {
    padding-top: 99px;
  }
`;

export const ForaImgBanner = styled.div`
  @media (max-width: 940px) {
    display: none;
  }
`;

export const ForaImgBannerResp = styled.div`
  display: none;

  @media (max-width: 940px) {
    display: block;
  }
`;

export const ImgBanner = styled.img``;

export const ForaConteudoBanner = styled.div`
  background-color: rgb(73, 29, 116, 0.85);
  margin-top: -344px;
  position: relative;
  height: 340px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 1600px) {
    height: 270px;
    margin-top: -270px;
  }

  @media (max-width: 1220px) {
    height: 240px;
    margin-top: -244px;
  }

  @media (max-width: 570px) {
    height: 150px;
    margin-top: -4px;
  }
`;

export const ForaImgAreaPremium = styled.div`
  width: 480px;
  margin: 0 auto;

  @media (max-width: 1600px) {
    width: 410px;
  }

  @media (max-width: 1220px) {
    width: 310px;
    margin-top: 15px;
  }

  @media (max-width: 570px) {
    width: 230px;
    margin-top: 0px;
  }
`;

export const ImgAreaPremium = styled.img``;

export const TextBanner1 = styled.div`
  font-family: "Lato";
  font-size: 35px;
  font-weight: 900;
  color: #ff4db9;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: 1600px) {
    font-size: 29px;
  }

  @media (max-width: 1220px) {
    font-size: 23px;
  }

  @media (max-width: 570px) {
    font-size: 16px;
  }
`;

export const ForaAssineJaBanner = styled.div`
  display: flex;
  width: 255px;
  height: 70px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  margin-bottom: 30px;
  margin-top: 30px;
  cursor: pointer;

  /* @media (max-width: 1600px) {
    width: 215px;
    height: 60px;
    margin-bottom: 8px;
  } */

  /* @media (max-width: 1220px) {
    width: 188px;
    height: 45px;
    margin-top: 11px;
  } */

  @media (max-width: 570px) {
    width: 170px;
    height: 30px;
    margin-top: 8px;
    margin-bottom: 2px;
  }
`;

export const ForaImgAssineJaBanner = styled.div`
  margin-top: 3px;
  margin-right: 5px;

  @media (max-width: 570px) {
    width: 19px;
  }
`;

export const TextAssineJaBanner = styled.div`
  font-family: "Lato";
  font-size: 25px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 570px) {
    font-size: 16px;
  }
`;

/* SOBREMIM SOBREMIM SOBREMIM SOBREMIM SOBREMIM */

export const BgSobreMim = styled.div`
  background-image: url(${ImgBgSobreMim});
  background-size: cover;
  width: 100%;
  background-position: center;

  @media (max-width: 1045px) {
    background-image: unset;
  }
`;

export const ForaSobreMim = styled.div`
  width: 1320px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;

  @media (max-width: 1345px) {
    width: 1025px;
  }

  @media (max-width: 1045px) {
    width: 100%;
    flex-direction: column;
    padding-top: 0px;
  }

  @media (max-width: 500px) {
    padding-bottom: 40px;
  }
`;

export const ForaImgSobreMim = styled.div`
  width: 38%;

  @media (max-width: 1045px) {
    display: none;
  }
`;

export const ForaImgSobreMimResp = styled.div`
  display: none;

  @media (max-width: 1045px) {
    display: block;
  }
`;

export const ImgSobreMim = styled.img``;

export const DireitaSobreMim = styled.div`
  width: 55%;

  @media (max-width: 1045px) {
    width: 90%;
  }
`;

export const TextSobreMim1 = styled.div`
  span {
    color: #d04a9b;
  }

  font-family: "Lato";
  font-size: 37px;
  font-weight: 900;
  color: #491d75;
  font-style: italic;

  @media (max-width: 1045px) {
    text-align: center;
  }

  @media (max-width: 450px) {
    font-size: 28px;
  }
`;

export const TextSobreMim2 = styled.div`
  font-family: "Lato";
  font-size: 25px;
  color: #491d74;
  padding-top: 40px;
  text-align: justify;

  @media (max-width: 1045px) {
    text-align: center;
  }

  @media (max-width: 500px) {
    font-size: 20px;
    padding-top: 15px;
  }
`;

export const ForaItemSobreMim = styled.div`
  display: flex;
  width: 70%;
  justify-content: space-between;
  padding-top: 40px;
  align-items: center;
  cursor: pointer;
  line-height: 28px;

  @media (max-width: 1345px) {
    width: 100%;
  }

  @media (max-width: 1045px) {
    width: 80%;
    margin: 0 auto;
  }

  @media (max-width: 625px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    padding-top: 20px;
  }
`;

export const ForaItemSobreMimBaixo = styled.div`
  display: flex;
  width: 70%;
  justify-content: space-between;
  padding-top: 20px;
  align-items: center;
  cursor: pointer;

  @media (max-width: 1345px) {
    width: 100%;
  }
`;

export const ItemSobreMim = styled.div`
  background-color: #491d74;
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  border-radius: 12px;

  @media (max-width: 500px) {
    width: 230px;
    height: 45px;

    :nth-child(2) {
      margin-top: 15px;
    }
  }
`;

export const TextSobreMim3 = styled.div`
  span {
    color: #d04a9b;
    font-weight: 400;
  }

  font-family: "Lato";
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 900;
`;

export const ForaImgItemSobreMim = styled.div`
  width: 25px;
  padding-right: 8px;
  margin-top: 10px;
`;

export const ImgItemSobreMim = styled.img``;

export const MeioSobreMim = styled.div`
  display: flex;
  justify-content: space-between;
  width: 51%;
  align-items: center;

  @media (max-width: 1045px) {
    width: 255px;
  }

  @media (max-width: 510px) {
    width: 220px;
  }
`;

export const TextSobreMim4 = styled.div`
  font-family: "Lato";
  font-size: 13px;
  font-weight: 900;
  color: #491d74;
  text-transform: uppercase;
  width: 50%;

  @media (max-width: 510px) {
    width: 59%;
  }
`;

export const ForaImgIconeSobreMim = styled.div`
  cursor: pointer;

  @media (max-width: 510px) {
    font-size: 12px;
    width: 40px;
  }
`;

export const ImgIconeSobreMim = styled.img`
  margin-bottom: -4px;
`;

export const RiscoSobreMim = styled.div`
  width: 46%;
  background-color: #491d74;
  height: 1px;

  @media (max-width: 1045px) {
    width: 30%;
  }

  @media (max-width: 760px) {
    width: 25%;
  }

  @media (max-width: 615px) {
    width: 20%;
  }

  @media (max-width: 445px) {
    width: 34%;
  }

  @media (max-width: 390px) {
    width: 23%;
  }
`;

export const RiscoSobreMimResp = styled.div`
  width: 46%;
  background-color: #491d74;
  height: 1px;
  display: none;

  @media (max-width: 1045px) {
    display: block;
    width: 30%;
  }

  @media (max-width: 760px) {
    width: 25%;
  }

  @media (max-width: 615px) {
    width: 20%;
  }

  @media (max-width: 445px) {
    display: none;
  }
`;

/* MinhasFotos MinhasFotos MinhasFotos MinhasFotos MinhasFotos */

export const BgMinhasFotos = styled.div`
  background-color: #491d74;
`;

export const ForaMinhasFotos = styled.div`
  width: 94%;
  margin: 0 auto;
  padding-bottom: 300px;

  @media (max-width: 1500px) {
    padding-bottom: 265px;
  }

  @media (max-width: 850px) {
    width: 100%;
  }

  @media (max-width: 1175px) {
    padding-bottom: 170px;
  }

  @media (max-width: 900px) {
    padding-bottom: 20vw;
  }
`;

export const ForaImgTextMinhasFotos = styled.div`
  width: 305px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 55px;

  @media (max-width: 550px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;
export const ImgTextMinhasFotos = styled.img``;

export const ForaGaleria = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ForaFoto = styled.div`
  background-position: top center;
  height: 15vw;
  background-size: cover;

  :hover {
    filter: unset;
  }

  filter: opacity(0.4) drop-shadow(0 0 0 purple) grayscale(100%)
    brightness(1.75);
  cursor: pointer;
  width: 25%;

  @media (max-width: 900px) {
    width: 33.3%;
    height: 22vw;
  }

  @media (max-width: 550px) {
    width: 33.3;
  }
`;

export const BgImgMaior = styled.div`
  background-color: #24083f;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const XImg = styled.div`
  position: absolute;
  top: 30px;
  right: 65px;
  font-family: "Lato";
  font-size: 30px;
  font-weight: 900;
  cursor: pointer;
`;

export const ForaImgMaior = styled.div`
  /* width: 1100px; */
  width: 460px;
  margin: 0 auto;

  @media (max-width: 1150px) {
    width: 600px;
  }

  @media (max-width: 670px) {
    width: 90%;
  }
`;

export const ForaImgMaior2 = styled.div`
  width: 460px;
  margin: 0 auto;

  @media (max-width: 670px) {
    width: 90%;
  }
`;

export const ImgMaior = styled.img``;

export const ImgFoto = styled.img`
  margin-bottom: -3px;
`;

export const MaisFotos = styled.div`
  width: 255px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: 1px solid #fff;
  margin-top: 85px;
  height: 55px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 1;
  position: relative;

  @media (max-width: 850px) {
    margin-top: 55px;
  }

  @media (max-width: 500px) {
    margin-top: 30px;
    height: 45px;
    width: 190px;
  }
`;

export const TextMaisFotos = styled.div`
  span {
    color: #d04a9b;
  }

  font-family: "Lato";
  font-size: 21px;
  font-weight: 900;
  text-transform: uppercase;
`;

/* AreaPremium AreaPremium AreaPremium AreaPremium AreaPremium */

export const BgPremium = styled.div`
  background-color: #a73b7e;
`;

export const ForaPremium = styled.div`
  width: 1490px;
  margin: 0 auto;
  padding-bottom: 190px;

  @media (max-width: 1500px) {
    width: 1140px;
    padding-bottom: 155px;
  }

  @media (max-width: 1175px) {
    width: 880px;
  }

  @media (max-width: 900px) {
    width: 90%;
    padding-bottom: 55px;
  }
`;

export const ForaImgPremium = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const ForaImgPremiumResp = styled.div`
  width: 100%;
  margin: 0 auto;
  z-index: 99;
  position: relative;
  display: none;

  @media (max-width: 900px) {
    display: block;
  }

  @media (max-width: 685px) {
    display: block;
  }
`;

export const ImagemPremium = styled.img`
  margin-top: -484px;

  @media (max-width: 1500px) {
    margin-top: -370px;
  }

  @media (max-width: 1175px) {
    margin-top: -285px;
  }

  @media (max-width: 900px) {
    margin-top: -28.3vw;
  }
`;

export const ForaImgPremium2 = styled.div`
  width: 820px;
  margin: 0 auto;
  margin-top: -125px;

  @media (max-width: 900px) {
    width: 600px;
    margin-top: -110px;
  }

  @media (max-width: 685px) {
    width: 100%;
    margin-top: -15vw;
  }
`;

export const ImgPremium2 = styled.img``;

export const TextPremium2 = styled.div`
  color: #461c70;
  font-family: "Lato";
  font-size: 25px;
  font-style: italic;
  font-weight: 900;
  text-align: center;
`;

export const TextPremium3 = styled.div`
  font-family: "Lato";
  font-size: 15px;
  text-align: center;
  width: 790px;
  margin: 0 auto;
  line-height: 25px;
  margin-top: 20px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const ForaItensPremium = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 130px;
  width: 1255px;
  margin: 0 auto;

  @media (max-width: 1500px) {
    width: 100%;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    padding-top: 30px;
  }

  @media (max-width: 500px) {
    width: 100%;
    margin: 0 auto;
  }
`;

export const ItemPremium = styled.div`
  width: 31%;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;

  @media (max-width: 1175px) {
    width: 32.5%;
  }

  @media (max-width: 900px) {
    width: 94%;
    display: flex;
    margin-top: 16px;
    align-items: center;
    padding: 25px;
    justify-content: space-between;
  }

  @media (max-width: 795px) {
    padding-bottom: 35px;
    width: 90%;
    margin: 0 auto;
  }

  @media (max-width: 650px) {
    flex-wrap: wrap;
    padding-bottom: 45px;
  }

  @media (max-width: 500px) {
    padding: 0px;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 40px;
    border-radius: 12px;
  }
`;

export const ForaImgItemPremium = styled.div`
  width: 85%;
  margin: 0 auto;
  margin-top: -88px;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const ForaImgItemPremiumResp = styled.div`
  display: none;

  @media (max-width: 900px) {
    display: block;
  }

  @media (max-width: 795px) {
    width: 130px;
  }

  @media (max-width: 500px) {
    width: 160px;
  }
`;

export const ForaImgItemPremiumResp2 = styled.div`
  @media (max-width: 650px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const ImagemItemPremium = styled.img``;

export const ForaTextItemPremium = styled.div`
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const TextItemPremium1 = styled.div`
  font-family: "Lato";
  font-size: 37px;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  margin-top: 30px;

  @media (max-width: 900px) {
    margin-top: 0px;
    font-size: 30px;
  }
`;

export const TextItemPremium2 = styled.div`
  font-family: "Lato";
  font-size: 37px;
  font-weight: 900;
  color: #fff;
  text-align: center;

  span {
    font-weight: 400;
  }
`;

export const TextItemPremium3 = styled.div`
  font-family: "Lato";
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
`;

export const ForaConteudoItemPremium = styled.div`
  width: 63%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;

  @media (max-width: 900px) {
    margin: unset;
    height: 128px;
    width: 284px;
  }

  @media (max-width: 795px) {
    height: 115px;
    width: 236px;
  }

  @media (max-width: 500px) {
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 0px;
  }
`;

export const TextConteudoItemPremium = styled.div`
  font-family: "Lato";
  font-size: 15px;
  line-height: 22px;

  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 25px;
  }

  @media (max-width: 795px) {
    font-size: 15px;
    line-height: 22px;
  }
`;

export const ForaAssineJa = styled.div`
  display: flex;
  width: 130px;
  height: 40px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  margin-bottom: 30px;
  cursor: pointer;

  :hover {
    cursor: pointer;
    background-image: linear-gradient(#ff63d8, #aa3d8f);
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const ForaAssineJa2 = styled.div`
  display: none;
  width: 130px;
  height: 40px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  margin-bottom: 30px;
  cursor: pointer;

  :hover {
    cursor: pointer;
    background-image: linear-gradient(#ff63d8, #aa3d8f);
  }

  @media (max-width: 900px) {
    display: flex;
    background-image: linear-gradient(#ff63d8, #aa3d8f);
    margin-top: -21px;
  }
`;

export const ForaImgAssineJa = styled.div`
  margin-top: 3px;
  margin-right: 5px;
`;

export const ImgAssineJa = styled.img``;

export const TextAssineJa = styled.div`
  font-family: "Lato";
  font-size: 15px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
`;

export const TextCobrado = styled.div`
  font-family: "Lato";
  font-size: 13px;
  text-align: center;
  padding-bottom: 50px;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const TextCobrado2 = styled.div`
  font-family: "Lato";
  font-size: 13px;
  padding-bottom: 50px;
  display: none;

  @media (max-width: 900px) {
    display: block;
    font-size: 15px;
    padding-top: 10px;
  }

  @media (max-width: 795px) {
    padding-top: 8px;
  }
`;

/* DIARIO DIARIO DIARIO DIARIO DIARIO DIARIO */

export const BgDiario = styled.div`
  background-image: url(${ImgBgDiario});
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -21px;
  background-position: center;

  @media (max-width: 1050px) {
    background-image: url(${BgDiarioResp});
    margin-top: -44px;
  }
`;

export const ForaItemDiario = styled.div`
  width: 1218px;
  display: flex;
  margin: 0 auto;
  justify-content: flex-end;

  @media (max-width: 1260px) {
    width: 1000px;
  }

  @media (max-width: 1050px) {
    width: 700px;
  }

  @media (max-width: 750px) {
    width: 90%;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const ForaIconeDiario = styled.div`
  display: flex;
  align-items: center;
  margin-right: -54px;
  margin-top: -25px;

  @media (max-width: 1050px) {
    display: none;
  }
`;

export const ForaIconeDiarioResp = styled.div`
  display: flex;
  align-items: unset;
  width: 80px;
  margin: 0 auto;
  margin-top: -58px;
  margin-bottom: 22px;
`;

export const ImgIconeDiario = styled.img``;

export const ItemDiario = styled.div`
  width: 705px;
  background-color: #fff;
  padding-top: 35px;
  padding-bottom: 35px;
  margin-top: 125px;
  margin-bottom: 172px;
  border-radius: 23px;

  @media (max-width: 1260px) {
    padding-bottom: 15px;
    padding-top: 15px;
    width: 595px;
  }

  @media (max-width: 1050px) {
    width: 100%;
    margin-top: 90vw;
    margin-bottom: 18vw;
  }
`;

export const ForaTextDiario = styled.div`
  width: 190px;
  margin: 0 auto;

  @media (max-width: 1050px) {
    width: 145px;
  }
`;

export const ImgDiario = styled.img``;

export const ForaConteudoDiario = styled.div``;

export const TextDiario1 = styled.div`
  font-family: "Lato";
  font-size: 25px;
  color: #491d74;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;

  @media (max-width: 1260px) {
    font-size: 23px;
    margin-top: 5px;
  }
`;

export const TextDiario2 = styled.div`
  font-family: "Lato";
  font-size: 20px;
  color: #a73b7e;
  text-align: center;
  margin-top: 20px;

  @media (max-width: 1260px) {
    margin-top: 15px;
  }
`;

export const VerMais = styled.div`
  font-family: "Lato";
  font-size: 20px;
  color: #a73b7e;
  text-align: center;
  margin-top: 60px;
  text-decoration: underline;
  cursor: pointer;
`;

/* CONTEUDO CONTEUDO CONTEUDO CONTEUDO CONTEUDO CONTEUDO */

export const BgConteudo = styled.div`
  background-color: #f6f6f6;
  padding-bottom: 160px;

  @media (max-width: 1390px) {
    padding-bottom: 140px;
  }
`;

export const ForaConteudo = styled.div`
  width: 1330px;
  margin: 0 auto;

  @media (max-width: 1390px) {
    width: 1050px;
  }

  @media (max-width: 1090px) {
    width: 800px;
  }

  @media (max-width: 830px) {
    width: 90%;
  }
`;

export const ForaTextConteudo = styled.div`
  width: 450px;
  margin: 0 auto;
  padding-top: 65px;

  @media (max-width: 1390px) {
    padding-top: 0px;
  }

  @media (max-width: 1050px) {
    padding-top: 55px;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const ImgTextConteudo = styled.img``;

export const TextConteudo2 = styled.div`
  font-family: "Lato";
  font-size: 17px;
  color: #491d74;
  text-align: center;
  font-weight: 700;
  padding-top: 25px;
  padding-bottom: 45px;

  @media (max-width: 830px) {
    padding-bottom: 25px;
  }

  @media (max-width: 500px) {
    padding-bottom: 5px;
  }
`;

export const ForaItemConteudo = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;

  @media (max-width: 830px) {
    flex-wrap: wrap;
  }
`;

export const ItemConteudo = styled.div`
  width: 24%;

  @media (max-width: 830px) {
    width: 48%;
    margin-top: 30px;
  }
`;

export const ForaImgConteudo = styled.div`
    width: 100%;
    height: 215px;
    background-size: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`;

export const ImgConteudo = styled.img``;

export const BaixoConteudo = styled.div`
  background-color: #f6f6f6;
  box-shadow: 0 0 10px #eeeeee;
  margin-top: -4px;
  padding-bottom: 20px;
`;

export const ForaBaixoConteudo = styled.div`
  width: 80%;
  margin: 0 auto;
`;

export const TextConteudo3 = styled.div`
  font-family: "Lato";
  font-size: 20px;
  color: #bf448f;
  font-weight: 900;
  font-style: italic;
  padding-top: 25px;
  padding-bottom: 10px;

  @media (max-width: 500px) {
    font-size: 17px;
    padding-top: 20px;
  }
`;

export const TextConteudo4 = styled.div`
  font-family: "Lato";
  font-size: 15px;
  color: #431b6c;
  line-height: 22px;
`;

export const TextConteudo5 = styled.div`
  font-family: "Lato";
  font-size: 30px;
  color: #491d74;
  font-weight: 900;
  padding-top: 10px;

  span {
    font-weight: 500;
  }

  @media (max-width: 1090px) {
    font-size: 27px;
  }
`;

export const BotaoAdquirir = styled.div`
  font-family: "Lato";
  font-size: 15px;
  color: #fff;
  font-weight: 900;
  background-color: #431b6c;
  text-transform: uppercase;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-top: 25px;
  margin-bottom: 25px;
  cursor: pointer;

  :hover {
    background-color: #c34591;
    cursor: pointer;
  }

  @media (max-width: 500px) {
    height: 35px;
    margin-top: 15px;
    margin-bottom: 0px;
  }
`;

export const BotaoMaisConteudo = styled.div`
  color: #c34591;
  font-family: "Lato";
  font-size: 18px;
  color: #c34591;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 50px;
  text-transform: uppercase;
  margin: 0 auto;
  border: 1px solid #431b6c;
  border-radius: 10px;
  cursor: pointer;
`;

/* FEEDBACK FEEDBACK FEEDBACK FEEDBACK FEEDBACK */

export const BgFeedback = styled.div`
  background-color: #d04a9b;
  padding-top: 1px;
  padding-bottom: 1px;
`;

export const ForaFeedback = styled.div`
  width: 1335px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 45px;
  padding-bottom: 90px;

  @media (max-width: 1390px) {
    width: 87%;
  }
`;

export const ForaImgFeedBack = styled.div`
  width: 235px;
  margin: 0 auto;
  padding-top: 95px;
  padding-left: 22px;

  @media (max-width: 670px) {
    padding-top: 40px;
  }
`;

export const ForaImgFeed = styled.div`
  width: 96%;
  margin-bottom: -4px;
  cursor: pointer;

  @media (max-width: 650px) {
    width: 96%;
  }
`;

export const ImgFeed = styled.img``;

export const ImgFeedBack = styled.img``;

export const ItemFeedback = styled.div``;

export const TextFeedback1 = styled.div`
  color: #fff;
  font-family: "Lato";
  font-size: 23px;
  text-align: center;
  font-weight: 500;
  width: 625px;
  margin: 0 auto;
  padding-top: 39px;

  @media (max-width: 890px) {
    width: 100%;
    padding-top: 10px;
  }
`;

export const TextFeedback2 = styled.div`
  color: #fff;
  font-family: "Lato";
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  padding-top: 20px;
`;

export const ImgItemPagamento = styled.img``;

export const ColunaPagamento = styled.div`
  flex-direction: row;
  width: auto;
  display: flex;
`;

export const ItemPagamento = styled.div`
  display: flex;
  padding: 8px;
  cursor: pointer;
  width: 100px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  border-radius: 12px;
`;

export const ForaHoverPagamento = styled.div`
  background: rgba(36, 8, 63, 0.85);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ForaResultadoPagamento = styled.div`
  width: 600px;
  margin: 0 auto;
  background-color: hsla(0, 0%, 100%, 0.9);
  border-radius: 12px;

  @media (max-width: 600px) {
    width: 90%;
  }
`;

export const TextResultadoPagamento1 = styled.div`
  color: #a7000c;
  font-family: "montserrat";
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  width: 325px;
  margin: 0 auto;
  margin-top: 25px;

  @media (max-width: 600px) {
    font-size: 22px;
    width: 90%;
    margin-top: 20px;
  }
`;
export const TextResultadoPagamento1Apro = styled.div`
  color: #00ae3d;
  font-family: "montserrat";
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  width: 325px;
  margin: 0 auto;
  margin-top: 25px;

  @media (max-width: 600px) {
    font-size: 22px;
    width: 90%;
    margin-top: 20px;
  }
`;

export const TextResultadoPagamento2 = styled.div`
  background-color: rgb(167, 59, 126);
  font-family: "montserrat";
  font-size: 25px;
  text-transform: uppercase;
  text-align: center;
  width: 260px;
  margin: 0 auto;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
  cursor: pointer;
  border-radius: 12px;

  @media (max-width: 600px) {
    font-size: 22px;
  }
`;

export const ForaCoracaoPop = styled.div`
  width: 100px;
  margin: 0 auto;
  margin-top: 15px;
`;

export const ImagemCoracaoPop = styled.img``;
