import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as S from "./Estilos";
import Header from "../../Componentes/Header";
import Footer from "../../Componentes/Footer";

import ImgBanner from "../../Images/Fotos/Banner.jpg";
import ImgBannerResp from "../../Images/Fotos/BannerResp.jpg";
import ImgWhats from "../../Images/SobreMim/ImgWhats.png";
import ImgBaixoSobreMim from "../../Images/SobreMim/ImgBaixoSobreMim.png";
import ImgMinhasFotos from "../../Images/ImgMinhasFotos.png";
import ImgSobreMim1 from "../../Images/SobreMim/ImgSobreMim1.png";
import ImgSobreMim2 from "../../Images/SobreMim/ImgSobreMim2.png";
import ImgSobreMim3 from "../../Images/SobreMim/ImgSobreMim3.png";
import { getData } from "../../utils/useData";
function Fotos(props) {
  const history = useHistory();
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [box, setBox] = useState(false);
  const { handleSubmit } = useForm();
  const page = useRef(1);
  const [foto, setFoto] = useState([]);
  const [AbreFoto, setAbreFoto] = useState("");

  const MandaFotos = () => {
    // document.location = "/Fotos";
    history.push("/Fotos");
  };

  const MandaAtendimento = () => {
    // document.location = "/Atendimento";
    history.push("/Atendimento");
  };

  const MandaAreaPremium = () => {
    // document.location = "/AreaPremium";
    history.push("/AreaPremium");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    CarregaDados();
  }, []);

  function CarregaDados() {
    getData(
      "/site/diario/" + page.current //url
    )
      .then((response) => {
        setData(response);
      })
      .catch((err) => {});
  }

  function CarregaMais() {
    page.current = page.current + 1;
    getData(
      "/site/diario/" + page.current //url
    )
      .then((response) => {
        setData((data) => [...data, ...response]);
      })
      .catch((err) => {});
  }

  return (
    <>
      <Header />


    <S.ForaBanner>
      <S.TextBanner>Diário</S.TextBanner>
    </S.ForaBanner>

    <S.ForaDiario>
      <S.TextDiario1>Meu Diário</S.TextDiario1>
      
      {data.map((res, index) => (
          <>
          <S.ItemDiario>
            <S.TextDiario2>{res.c_data.substr(6, 2)}/{res.c_data.substr(4, 2)}/{res.c_data.substr(0, 4)}</S.TextDiario2>
            <S.TextDiario3>{res.c_texto}</S.TextDiario3>
          </S.ItemDiario>
          </>
        ))}

      <S.TextProxPagina onClick={() => {
          CarregaMais();
        }}
        >próxima página</S.TextProxPagina>
    </S.ForaDiario>

    <S.Fora3Img>
          <S.ForaImgSobreMim onClick={() => MandaFotos()}>
            <S.ImagemSobreMim src={ImgSobreMim1} />
          </S.ForaImgSobreMim>
          <S.ForaImgSobreMim onClick={() => MandaAtendimento()}>
            <S.ImagemSobreMim src={ImgSobreMim2} />
          </S.ForaImgSobreMim>
          <S.ForaImgSobreMim
            onClick={() => MandaAreaPremium()}
            className="TerceiraImg"
          >
            <S.ImagemSobreMim src={ImgSobreMim3} />
          </S.ForaImgSobreMim>
    </S.Fora3Img>

      <S.ForaConteudoSobreMim>
        <S.ForaEntreContato>
          <S.TextEntreContato1>Entre em contato!</S.TextEntreContato1>
          <S.ForaBotaoEntreContato>
            <S.ForaImgWhats>
              <S.ImagemWhats src={ImgWhats} />
            </S.ForaImgWhats>
            <S.TextEntreContato2>
              +55 11 <span>95287-5123 </span>
            </S.TextEntreContato2>
          </S.ForaBotaoEntreContato>
        </S.ForaEntreContato>
      </S.ForaConteudoSobreMim>

      <S.ForaImgBaixoSobreMim>
        <S.ImagemBaixoSobreMim src={ImgBaixoSobreMim} />
      </S.ForaImgBaixoSobreMim>

      <Footer />
    </>
  );
}

export default Fotos;
