import styled from 'styled-components';

// import ImgLogo from "./Images/seta_baixo.png";


export const ForaBanner = styled.div`
    padding-top: 100px;

     @media (max-width: 750px) {
        display: none;
        margin-bottom: -4px;
    } 
`;

export const ForaBannerResp = styled.div`
    display: none;
    padding-top: 119px;

    @media (max-width: 750px) {
        display: block;
        margin-bottom: -4px;
    } 
`;

export const ImagemBanner = styled.img`
 
`;

export const ForaConteudoSobreMim = styled.div`
    width: 1370px;
    margin: 0 auto;

    @media (max-width: 1430px) {
        width: 1100px;
    } 

    @media (max-width: 1160px) {
        width: 900px;
    } 

    @media (max-width: 950px) {
        width: 650px;
    } 

    @media (max-width: 700px) {
        width: 90%;
    } 
`;

export const ForaImgLizzy = styled.div`
    padding-top: 90px;
    padding-bottom: 90px;

    @media (max-width: 1160px) {
        padding-top: 60px;
        padding-bottom: 60px;
    } 

    @media (max-width: 450px) {
        padding-top: 35px;
        padding-bottom: 25px;
    } 
`;

export const ImagemLizzy = styled.img`
 
`;

export const CimaSobreMim = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 1160px) {
        flex-direction: column;
    } 
`;

export const EsqCimaSobreMim = styled.div`
    width: 62%;

    @media (max-width: 1430px) {
        width: 59%;
    } 

    @media (max-width: 1160px) {
        width: 100%;
    } 
`;

export const TextCimaSobreMim1 = styled.div`
    font-family: 'Lato';
    font-weight: bold;
    font-style: italic;
    font-size: 37px;
    color: #491d74;

    span {
        color: #d04a9b;
    }

    @media (max-width: 400px) {
        font-size: 31px;
    } 
`;

export const TextCimaSobreMim2 = styled.div`
    font-family: 'Lato';
    font-size: 25px;
    color: #491d74;
    padding-top: 40px;

    @media (max-width: 1160px) {
        padding-top: 20px;
    } 

    @media (max-width: 400px) {
        font-size: 21px;
    } 
`;

export const DirCimaSobreMim = styled.div`
    display: flex;
    width: 485px;
    box-shadow: 0 0 20px #ccc;
    border-radius: 25px;
    align-items: center;
    margin-top: 40px;
    height: 335px;

    @media (max-width: 1430px) {
        width: 420px;
        box-shadow: 0 0 20px #ccc;
        border-radius: 25px;
        align-items: center;
        margin-top: 40px;
        height: 310px;
    }

    @media (max-width: 1160px) {
        width: 550px;
        margin: 0 auto;
        margin-top: 55px;
    }

    @media (max-width: 650px) {
        width: 100%;
        height: 250px;
    }

    @media (max-width: 440px) {
        height: 195px;
        margin-top: 35px;
    }
`;



export const RiscoCimaSobreMim = styled.div`
    height: 335px;
    width: 25px;
    background-color: #d04a9b;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;

    @media (max-width: 1430px) {
        height: 312px;
    } 

    @media (max-width: 650px) {
        height: 250px;
    }

    @media (max-width: 440px) {
        height: 195px;
        width: 18px;
    }
`;

export const ForaConteudoDirSobreMim = styled.div`
    width: 370px;
    margin: 0 auto;
    height: 185px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1430px) {
        width: 330px;
    } 

    @media (max-width: 1160px) {
        width: 400px;
    }

    @media (max-width: 650px) {
        width: 85%;
    }

    @media (max-width: 440px) {
        height: 135px;
    }
`;

export const ItemDirSobreMim = styled.div`
 
`;

export const CimaConteudoDirSobreMim = styled.div`
    display: flex;
`;

export const BaixoConteudoDirSobreMim = styled.div`
   display: flex;
   justify-content: space-between;
`;

export const TextDirSobreMim1 = styled.div`
    color: #d04a9b;
    font-family: 'Lato';
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 600;

    @media (max-width: 440px) {
        font-size: 23px;
    }
`;

export const TextDirSobreMim2 = styled.div`
    font-family: 'Lato';
    font-size: 30px;
    color: #491d74;
    text-transform: uppercase;
    font-weight: 600;

    @media (max-width: 440px) {
        font-size: 23px;
    }
`;

export const RiscoSobreMim = styled.div`
    background-color: #dedede;
    width: 100%;
    height: 2px;
    margin-top: 75px;
    margin-bottom: 75px;

    @media (max-width: 650px) {
        margin-top: 60px;
        margin-bottom: 40px;
    }
`;

export const ForaSugestoes = styled.div`

`;

export const CimaSugestoes = styled.div`
    width: 430px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 480px) {
        width: 360px;
    }

    @media (max-width: 430px) {
        width: 320px;
    }

    @media (max-width: 380px) {
        width: 290px;
    }
`;

export const ForaImgPresente = styled.div`
    @media (max-width: 380px) {
        width: 28px;
    }
`;

export const ImagemPresente = styled.img`

`;

export const TextSugestoes1 = styled.div`
    font-family: 'Lato';
    font-size: 37px;
    color: #491d74;
    font-weight: bold;
    font-style: italic;

    @media (max-width: 480px) {
        font-size: 33px;
    }

    @media (max-width: 380px) {
        font-size: 30px;
    }
`;

export const ConteudoSugestoes = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    justify-content: space-between;

    @media (max-width: 650px) {
        margin-top: 15px;
    }
`;

export const ItemSugestoes = styled.div`
    width: 30%;
    padding-top: 30px;

    @media (max-width: 950px) {
        width: 47%;
    }

    @media (max-width: 450px) {
        width: 100%;
    }
`;

export const TextConteudoSugestoes1 = styled.div`
    font-family: 'Lato';
    font-size: 25px;
    color: #d04a9b;
    text-transform: uppercase;
    font-weight: bold;
`;

export const TextConteudoSugestoes2 = styled.div`
    font-family: 'Lato';
    font-size: 25px;
    color: #5f536c;

    @media (max-width: 1430px) {
        font-size: 23px;
    } 

    @media (max-width: 650px) {
        font-size: 20px;
    } 
`;

export const ItemCoracao = styled.div`
    display: flex;
    align-items: center;
    width: auto;
    padding-top: 15px;
`;

export const ForaImgCoracao = styled.div`
    padding-top: 6px;
    width: 25px;
`;

export const ImagemCoracao = styled.img`

`;

export const TextCoracao = styled.div`
    font-family: 'Lato';
    font-size: 24px;
    color: #4c2176;
    font-weight: bold;
    padding-left: 17px;
    width: 90%;
`;

export const Fora3Img = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 110px;

    @media (max-width: 950px) {
        padding-top: 65px;
    } 

    @media (max-width: 650px) {
        padding-top: 45px;
        flex-wrap: wrap;
    } 

    @media (max-width: 450px) {
        padding-top: 30px;
    } 
`;

export const ForaImgSobreMim = styled.div`
    width: 30%;
    cursor: pointer;

    @media (max-width: 650px) {
        width: 48%;
        margin: 0 auto;
    } 

    @media (max-width: 450px) {
        width: 100%;
        margin-top: 15px;
    } 
`;

export const ImagemSobreMim = styled.img`

`;

export const ForaEntreContato = styled.div`
    background-color: #f7f7f7;
    height: 310px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;

    @media (max-width: 950px) {
        margin-top: 60px;
        height: 230px;
        padding-bottom: 35px;
    } 

    @media (max-width: 450px) {
        height: 150px;
        padding-bottom: 53px;
        margin-top: 25px;
    } 
`;

export const TextEntreContato1 = styled.div`
    color: #549c00;
    font-family: 'Lato';
    font-size: 37px;
    font-weight: bold;
    font-style: italic;
    text-align: center;

    @media (max-width: 450px) {
        font-size: 30px;
    } 
`;

export const ForaBotaoEntreContato = styled.div`
    display: flex;
    width: 430px;
    margin: 0 auto;
    background-color: #549c00;
    align-items: center;
    justify-content: center;
    height: 95px;
    border-radius: 14px;
    margin-top: 30px;
    cursor: pointer;

    @media (max-width: 950px) {
        height: 75px;
        margin-top: 20px;
    } 

    @media (max-width: 510px) {
        width: 360px;
    } 

    @media (max-width: 450px) {
        width: 250px;
        height: 60px;
    } 

`;

export const ForaImgWhats = styled.div`
    @media (max-width: 450px) {
        width: 45px;
    } 
`;

export const ImagemWhats = styled.img`

`;

export const TextEntreContato2 = styled.div`
    font-family: 'Lato';
    font-size: 30px;
    color: #fff;
    padding-left: 10px;

    span {
        font-weight: bold;
    }

    @media (max-width: 450px) {
        font-size: 20px;
        padding-left: 0px;
    } 
`;

export const ForaImgBaixoSobreMim = styled.div`
    margin-top: -48px;
    margin-bottom: -95px;

    @media (max-width: 670px) {
        margin-bottom: -35px;
    } 

    @media (max-width: 450px) {
        margin-bottom: -25px;
    } 
`;

export const ImagemBaixoSobreMim = styled.img`

`;
