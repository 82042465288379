const requestHeaders = new Headers();
requestHeaders.set("Content-Type", "application/json");
const baseUrl = "https://lizzysweet.com.br:8085";

export const getData = function (url) {
  return new Promise(async function (resolve, reject) {
    await fetch(`${baseUrl}${url}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const postData = function (url, data) {
  return new Promise(async function (resolve, reject) {
    await fetch(`${baseUrl}${url}`, {
      method: "POST",
      headers: requestHeaders,
      body: data,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
