import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as S from "./Estilos";

import Logo from "../../Images/Logo.png";
import LogoRodapeResp from "../../Images/LogoRodapeResp.png";
import IconeWhats from "../../Images/ImgWhats.png";
import IconeTwitter from "../../Images/IconeTwitter.png";
import IconeInsta from "../../Images/InstaRodape.png";
import ImgItemRodape1 from "../../Images/ImgItemRodape1.png";
import ImgItemRodape2 from "../../Images/ImgItemRodape2.png";
import ImgItemRodape3 from "../../Images/ImgItemRodape3.png";
import ImgCoracao from "../../Images/ImgCoracao.png";

function Footer(props) {
  const history = useHistory();
  const [Loading, setLoading] = useState(false);
  const [box, setBox] = useState(false);
  const { handleSubmit } = useForm();

  const MandaWhats = () => {
    window.location.href = "https://wa.me/5511952875123";
  };

  const MandaInsta = () => {
    window.open("https://www.instagram.com/lizzysweet_1/");
  };

  const MandaTwitter = () => {
    window.open("https://twitter.com/lizzysweet_");
  };

  const MandaHome = () => {
    document.location = "/";
  };

  const MandaSobreMim = () => {
    document.location = "/SobreMim";
  };

  const MandaAtendimento = () => {
    document.location = "/Atendimento";
  };

  const MandaFoto = () => {
    document.location = "/Fotos";
  };

  const MandaLoja = () => {
    window.open("https://lizzysweet.com.br/Loja", "_blank");
  };

  const MandaAreaPremium = () => {
    document.location = "/AreaPremium";
  };

  const MandaDiario = () => {
    document.location = "/#BgDiario";
  };
  
  const MandaCadastroCima = (tipo, valor) => {
    history.push("/#BgPremium");
  };

  const TermosDeUso = () => {
    window.open("https://lizzysweet.com.br/TermosDeUso", "_blank");
  };

  const MandaFeed = () => {
    document.location = "/#BgFeedback";
  };

  return (
    <>
      <link rel="stylesheet" href="fontes/stylesheet.css" />

      <S.BgRodape>
        <S.ForaRodape>
          <S.RodapeCimaResp>
            <S.AssineRodape onClick={() => MandaCadastroCima()}>
              <S.ForaCoracaoRodape>
                <S.ImgCoracaoRodape src={ImgCoracao} />
              </S.ForaCoracaoRodape>
              <S.TextAssineRodape>Assine</S.TextAssineRodape>
            </S.AssineRodape>

            <S.BotaoRodape onClick={() => MandaWhats()}>
              <S.ForaImgRodape>
                <S.ImgRodape src={IconeWhats} />
              </S.ForaImgRodape>
              <S.TextBotaoRodape>
                <span>+55 11</span> 95287-5123
              </S.TextBotaoRodape>
            </S.BotaoRodape>
          </S.RodapeCimaResp>

          <S.ForaSeguirResp>
            <S.RiscoSeguirResp></S.RiscoSeguirResp>
            <S.BaixoDireitaRodape>
              <S.TextBaixoRodape>
                Venha me seguir nas redes sociais!
              </S.TextBaixoRodape>
              <S.ForaImgRedes onClick={() => MandaTwitter()}>
                <S.ImgRedes src={IconeTwitter} />
              </S.ForaImgRedes>
              <S.ForaImgRedes onClick={() => MandaInsta()}>
                <S.ImgRedes src={IconeInsta} />
              </S.ForaImgRedes>
            </S.BaixoDireitaRodape>
            <S.RiscoSeguirResp2></S.RiscoSeguirResp2>
          </S.ForaSeguirResp>

          <S.CimaRodape>
            <S.ForaLogoRodape>
              <S.ImgLogoRodape src={Logo} />
            </S.ForaLogoRodape>

            <S.ForaLogoRodapeResp>
              <S.ImgLogoRodape src={LogoRodapeResp} />
            </S.ForaLogoRodapeResp>

            <S.ForaMenusRodape>
              <S.ForaMenuRodape>
                <S.TextMenuRodape onClick={() => MandaHome()}>
                  Home
                </S.TextMenuRodape>
                <S.TextMenuRodape onClick={() => MandaSobreMim()}>
                  Sobre Mim
                </S.TextMenuRodape>
                <S.TextMenuRodape onClick={() => MandaAtendimento()}>
                  Atendimentos
                </S.TextMenuRodape>
                <S.TextMenuRodape onClick={() => MandaFoto()}>
                  Fotos
                </S.TextMenuRodape>
              </S.ForaMenuRodape>

              <S.ForaMenuRodape>
                <S.TextMenuRodape onClick={() => MandaLoja()}>
                  Loja
                </S.TextMenuRodape>
                <S.TextMenuRodape onClick={() => MandaAreaPremium()}>
                  Área Premium
                </S.TextMenuRodape>
              {/*
                <S.TextMenuRodape onClick={() => MandaDiario()}>
                  Diário
                </S.TextMenuRodape>
              */}
              </S.ForaMenuRodape>

              <S.ForaMenuRodape>
                <S.TextMenuRodape onClick={() => MandaAreaPremium()}>
                  Minha Conta
                </S.TextMenuRodape>
                <S.TextMenuRodape onClick={() => TermosDeUso()}>
                  Termos e Condições
                </S.TextMenuRodape>
                <S.TextMenuRodape onClick={() => MandaFeed()}>
                  Feedbacks
                </S.TextMenuRodape>
              </S.ForaMenuRodape>
            </S.ForaMenusRodape>

            <S.DireitaCimaRodape>
              <S.BotaoRodape onClick={() => MandaWhats()}>
                <S.ForaImgRodape>
                  <S.ImgRodape src={IconeWhats} />
                </S.ForaImgRodape>
                <S.TextBotaoRodape>
                  <span>+55 11</span> 95287-5123
                </S.TextBotaoRodape>
              </S.BotaoRodape>
              <S.BaixoDireitaRodape>
                <S.TextBaixoRodape>
                  Venha me seguir nas redes sociais!
                </S.TextBaixoRodape>
                <S.ForaImgRedes onClick={() => MandaTwitter()}>
                  <S.ImgRedes src={IconeTwitter} />
                </S.ForaImgRedes>
                <S.ForaImgRedes onClick={() => MandaInsta()}>
                  <S.ImgRedes src={IconeInsta} />
                </S.ForaImgRedes>
              </S.BaixoDireitaRodape>
            </S.DireitaCimaRodape>
          </S.CimaRodape>

          <S.RiscoRodape></S.RiscoRodape>

          <S.ForaMeioRodape>
            <S.ForaImgMeioRodape>
              <S.ImgMeioRodape src={ImgItemRodape1} />
            </S.ForaImgMeioRodape>
            <S.ForaImgMeioRodape className="ForaMeioRodape2">
              <S.ImgMeioRodape src={ImgItemRodape2} />
            </S.ForaImgMeioRodape>
          </S.ForaMeioRodape>

          <S.RiscoRodape></S.RiscoRodape>

          <S.TextDireitos>
            2022 - Todos os direitos reservados - Lizzy Sweet
          </S.TextDireitos>
        </S.ForaRodape>
      </S.BgRodape>
    </>
  );
}

export default Footer;
