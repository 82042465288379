import React, { useContext, useEffect, useState } from "react";
import ReactDOM /*render*/ from "react-dom";
import {
  BrowserRouter,
  /*HashRouter, Router,*/ Route,
  Switch,
} from "react-router-dom";
import ViewerContext from "../context/context";
import Home from "../Pages/Home";
import SobreMim from "../Pages/SobreMim";
import Atendimento from "../Pages/Atendimento";
import Fotos from "../Pages/Fotos";
import Loja from "../Pages/Loja";
import Login from "../Pages/Login";
import AreaPremium from "../Pages/AreaPremium";
import AreaPremiumFotos from "../Pages/AreaPremiumFotos";
import MinhaConta from "../Pages/MinhaConta";
import Assinaturas from "../Pages/Assinaturas";
import Cadastro from "../Pages/Cadastro";
import TermosDeUso from "../Pages/TermosDeUso";
import Teste from "../Pages/Teste";
import AreaPremiumPlayer from "../Pages/AreaPremiumPlayer";
import Diario from "../Pages/Diario";
import AlertPayment from "../Componentes/AlertPayment";

const Router = () => {
  const contextValues = {
    logged: false,
    forma_pagamento: 0,
    tipo_assinatura: "",
    valor_assinatura: 0,
    id_tb_user: 0,
  };
  // const contextValues = {
  //   logged: false,
  //   id_tb_categoria: 0,
  //   c_titulo_categoria: "",
  //   c_sessao: "Home",
  //   tb_teste: [
  //     {
  //       teste1: false,
  //     },
  //     {
  //       teste1: false,
  //     },
  //   ],

  //   tb_outro: [
  //     {
  //       outro: false,
  //     },
  //     {
  //       outro: false,
  //     },
  //   ],
  // };
  const [context, setContext] = useState(contextValues);

  return (
    <>
      <ViewerContext.Provider value={[context, setContext]}>
        <BrowserRouter basename="/">
          <AlertPayment>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/SobreMim" exact component={SobreMim} />
              <Route path="/Atendimento" exact component={Atendimento} />
              <Route path="/Fotos" exact component={Fotos} />
              <Route path="/Loja" component={Loja} />
              <Route path="/AreaPremium" exact component={AreaPremium} />
              <Route
                path="/AreaPremiumFotos"
                exact
                component={AreaPremiumFotos}
              />
              <Route path="/Login" exact component={Login} />
              <Route path="/Diario" exact component={Diario} />
              <Route
                path="/AreaPremiumPlayer"
                exact
                component={AreaPremiumPlayer}
              />
              <Route path="/MinhaConta" exact component={MinhaConta} />
              <Route path="/Assinaturas" exact component={Assinaturas} />
              <Route path="/Cadastro" exact component={Cadastro} />
              <Route path="/TermosDeUso" exact component={TermosDeUso} />
              <Route path="/Teste" exact component={Teste} />
            </Switch>
          </AlertPayment>
        </BrowserRouter>
      </ViewerContext.Provider>
    </>
  );
};

export default Router;
