import styled from 'styled-components';


export const ForaBanner = styled.div`
    margin-bottom: -4px;
    padding-top: 100px;

     @media (max-width: 750px) {
        display: none;
    } 
`;

export const ForaBannerResp = styled.div`
    display: none;
    padding-top: 119px;

    @media (max-width: 750px) {
        display: block;
        margin-bottom: -4px;
    } 
`;

export const ImagemBanner = styled.img`
 
`;

export const ForaConteudoSobreMim2 = styled.div`
    width: 1270px;
    margin: 0 auto;
`;


export const ForaCimaAtendimento = styled.div`
    display: flex;
    width: 1270px;
    margin: 0 auto;
    justify-content: space-between;

    @media (max-width: 1310px) {
        width: 1000px;
    } 

    @media (max-width: 1050px) {
        width: 800px;
    } 

    @media (max-width: 830px) {
        width: 640px;
    } 

    @media (max-width: 660px) {
        width: 90%;
        flex-wrap: wrap;
    } 
`;

export const ForaImgAtendimento = styled.div`
    width: 60%;
    margin-top: -35px;
    margin-bottom: -35px;

    @media (max-width: 1310px) {
        width: 66%;
    } 

    @media (max-width: 830px) {
        margin-top: -17px;
    } 

    @media (max-width: 660px) {
        display: none;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: -23px;
    } 

    @media (max-width: 480px) {
        width: 90%;
    } 
`;  

export const ImagemAtendimento = styled.img`
 
`;

export const ForaDirAtendimento = styled.div`
    padding-top: 40px;
    padding-bottom: 30px;
    width: 31%;

    @media (max-width: 1050px) {
        padding-top: 30px;
        padding-bottom: 30px;
    } 
    
    @media (max-width: 830px) {
        padding-top: 5px;
        padding-bottom: 5px;
    } 

    @media (max-width: 660px) {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    } 
`;

export const TextAtendimento1 = styled.div`
    color: #d04a9b;
    font-family: 'Lato';
    font-size: 50px;
    font-weight: bold;
    font-style: italic;

    @media (max-width: 1310px) {
        font-size: 40px;
    } 

    @media (max-width: 1050px) {
        font-size: 32px;
    } 

    @media (max-width: 830px) {
        font-size: 26px;
    } 

    @media (max-width: 660px) {
        width: 100%;
        font-size: 32px;
        margin-top: 20px;
    } 
`;

export const ItemAtendimento = styled.div`
     @media (max-width: 660px) {
        margin-top: 10px;
    } 
`;

export const TextAtendimento2 = styled.div`
    color: #491d74;
    font-family: 'Lato';
    font-size: 37px;
    font-weight: bold;
    padding-top: 20px;

    @media (max-width: 1310px) {
        font-size: 30px;
        padding-top: 3px;
    } 

    @media (max-width: 1050px) {
        font-size: 28px;
    } 

    @media (max-width: 830px) {
        font-size: 24px;
    } 
`;

export const ForaCertinho = styled.div`
     display: flex;
     padding-top: 20px;

     @media (max-width: 1310px) {
        padding-top: 15px;
    } 

    @media (max-width: 1050px) {
        padding-top: 10px;
    } 
`;

export const ForaImgCertinho = styled.div`
    padding-right: 20px;
    width: 26px;

    @media (max-width: 830px) {
        padding-right: 10px;
    } 
`;

export const ImagemCertinho = styled.img`
 
`;

export const TextCertinho = styled.div`
    color: #491d74;
    font-family: 'Lato';
    font-size: 25px;
    width: 90%;
    margin-top: -6px;

    @media (max-width: 830px) {
        padding-top: 0px;
        font-size: 22px;
    } 
`;







export const BgCache = styled.div`
    background-color: #dfdfdf;
`;

export const ForaCache = styled.div`
    width: 1370px;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 110px;

    @media (max-width: 1420px) {
        width: 1060px;
    }
    
    @media (max-width: 1100px) {
        width: 860px;
        padding-top: 60px;
        padding-bottom: 90px;
    } 

    @media (max-width: 900px) {
        width: 690px;
    } 

    @media (max-width: 730px) {
        width: 90%;
    } 

    @media (max-width: 480px) {
        padding-top: 40px;
    } 
`;

export const TextCache1 = styled.div`
    color: #491d74;
    font-family: 'Lato';
    font-size: 62px;
    font-weight: bold;
    font-style: italic;
    text-align: center;
    padding-bottom: 50px;

    @media (max-width: 1100px) {
        padding-bottom: 35px;
    } 

    @media (max-width: 480px) {
        padding-bottom: 0px;  
        font-size: 50px;
    } 
`;

export const ForaItensCache = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 730px) {
        flex-wrap: wrap;
    } 
`;

export const ItemCache = styled.div`
    border-radius: 20px;
    width: 31%;
    background-color: #fff;

    @media (max-width: 1420px) {
        width: 32%;
    } 

    @media (max-width: 730px) {
        width: 48%;
    } 

    @media (max-width: 480px) {
        width: 100%;
        margin-top: 40px;
    } 
`;

export const RiscoRosa = styled.div`
    background-color: #d04a9b;
    height: 25px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
`;

export const ForaConteudoItemCache = styled.div`
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 45px;
    padding-bottom: 40px;

    @media (max-width: 1420px) {
        width: 85%;
        padding-top: 35px;
        padding-bottom: 30px;
    } 

    @media (max-width: 1100px) {
        width: 100%;
        padding-top: 25px;
        padding-bottom: 25px;
    } 
`;

export const TextCache2 = styled.div`
    color: #fff;
    font-family: 'Lato';
    font-size: 36px;
    font-weight: bold;
    font-style: italic;
    background-color: #491d74;
    width: 120px;
    text-align: center;
    border-radius: 14px;
`;

export const TextCache3 = styled.div`
    color: #d04a9b;
    font-family: 'Lato';
    font-size: 36px;
    font-weight: bold;
    padding-top: 20px;

    span {
        font-weight: unset;
    }
`;

export const TextCache4 = styled.div`
    color: #491d74;
    font-family: 'Lato';
    font-size: 15px;
    font-style: italic;
    padding-top: 8px;
`;

export const TextCache5 = styled.div`
    color: #d04a9b;
    font-family: 'Lato';
    font-size: 25px;
    font-weight: bold;
    line-height: 34px;
`;

export const TextCache6 = styled.div`
    color: #491d74;
    font-family: 'Lato';
    font-size: 18px;
    text-align: center;
    width: 80%;
    margin: 0 auto;
`;

export const TextCache7 = styled.div`
    color: #777777;
    font-family: 'Lato';
    font-size: 12px;
    padding-top: 20px;

    @media (max-width: 900px) {
        text-align: center;
    } 

    @media (max-width: 660px) {
        width: 80%;
    } 
`;

export const TextCacheValido = styled.div`
    color: #777777;
    font-family: 'Lato';
    font-size: 12px;
    padding-top: 10px;

    @media (max-width: 900px) {
        text-align: center;
    } 

    @media (max-width: 660px) {
        width: 80%;
    } 
`;

export const RiscoCache = styled.div`
    background-color: #ebebea;
    width: 100%;
    height: 2px;
    margin-top: 25px;
    margin-bottom: 20px;

    @media (max-width: 1100px) {
        margin-top: 20px;
        margin-bottom: 15px;
    } 
`;

export const TextAgende = styled.div`
    color: #fff;
    font-family: 'Lato';
    font-size: 15px;
    background-image: linear-gradient(rgb(255, 99, 216), rgb(170, 61, 143));
    width: 135px;
    height: 40px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin: 0 auto;
    margin-bottom: -19px;
    cursor: pointer;
`;







export const ForaAtendimento = styled.div`
    width: 1370px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 1420px) {
        width: 1045px;
    } 

    @media (max-width: 1100px) {
        width: 850px;
    } 

    @media (max-width: 900px) {
        width: 670px;
    } 

    @media (max-width: 720px) {
        width: 90%;
    } 
`;

export const TextLocalAtendimento1 = styled.div`
    color: #491d74;
    font-family: 'Lato';
    font-size: 62px;
    font-weight: bold;
    font-style: italic;
    width: 100%;
    text-align: center;
    padding-top: 100px;

    @media (max-width: 1100px) {
        padding-top: 65px;
    } 

    @media (max-width: 720px) {
        padding-top: 35px;
    }
    
    @media (max-width: 720px) {
        padding-top: 35px;
        font-size: 45px;
    } 

    @media (max-width: 490px) {
        font-size: 35px;
    } 

    @media (max-width: 380px) {
        font-size: 32px;
    } 
`;

export const ConteudoAtendimento = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 70px;

    @media (max-width: 720px) {
        padding-top: 35px;
        flex-wrap: wrap;
    } 
`;

export const ItemLocalAtendimento = styled.div`
    background-color: #d1519f;
    width: 49%;
    border-radius: 17px;

    @media (max-width: 720px) {
        width: 100%;
    } 
`;

export const RiscoAtendimento = styled.div`
    height: 22px;
    width: 100%;
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
`;

export const DentroItemAtendimento = styled.div`
    width: 80%;
    margin: 0 auto;
    padding-top: 60px;
    padding-bottom: 70px;

    @media (max-width: 1420px) {
        padding-top: 45px;
        padding-bottom: 55px;
    } 

    @media (max-width: 1100px) {
        padding-top: 30px;
        padding-bottom: 40px;
    } 

    @media (max-width: 500px) {
        width: 85%;
        padding-top: 20px;
        padding-bottom: 25px;
    } 
`;

export const CimaDentroAtendimento = styled.div`
    display: flex;
    align-items: center;
`;

export const ForaImgDentroAtendimento = styled.div`

`;

export const ImagemDentroAtendimento = styled.img`

`;

export const TextDentroAtendimento = styled.div`
    font-family: 'Lato';
    font-size: 36px;
    color: #fff;
    font-weight: bold;
    font-style: italic;
    padding-left: 15px;
`;

export const TextItemAtendimento1 = styled.div`
    font-family: 'Lato';
    font-size: 23px;
    color: #fff;
    padding-top: 30px;
    text-align: justify;

    @media (max-width: 1420px) {
        padding-top: 15px;
    } 

    @media (max-width: 1100px) {
        font-size: 20px;
    } 
`;








export const ForaConteudoSobreMim = styled.div`
    width: 1370px;
    margin: 0 auto;

    @media (max-width: 1430px) {
        width: 1100px;
    } 

    @media (max-width: 1160px) {
        width: 900px;
    } 

    @media (max-width: 950px) {
        width: 650px;
    } 

    @media (max-width: 700px) {
        width: 90%;
    } 
`;


export const ForaEntreContato = styled.div`
    background-color: #f7f7f7;
    height: 310px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 120px;

    @media (max-width: 950px) {
        margin-top: 60px;
        height: 230px;
        padding-bottom: 35px;
    } 

    @media (max-width: 450px) {
        height: 150px;
        padding-bottom: 53px;
        margin-top: 25px;
    } 
`;

export const TextEntreContato1 = styled.div`
    color: #549c00;
    font-family: 'Lato';
    font-size: 37px;
    font-weight: bold;
    font-style: italic;
    text-align: center;

    @media (max-width: 450px) {
        font-size: 30px;
    } 
`;

export const ForaBotaoEntreContato = styled.div`
    display: flex;
    width: 430px;
    margin: 0 auto;
    background-color: #549c00;
    align-items: center;
    justify-content: center;
    height: 95px;
    border-radius: 14px;
    margin-top: 30px;
    cursor: pointer;

    @media (max-width: 950px) {
        height: 75px;
        margin-top: 20px;
    } 

    @media (max-width: 510px) {
        width: 360px;
    } 

    @media (max-width: 450px) {
        width: 250px;
        height: 60px;
    } 

`;

export const ForaImgWhats = styled.div`
    @media (max-width: 450px) {
        width: 45px;
    } 
`;

export const ImagemWhats = styled.img`

`;

export const TextEntreContato2 = styled.div`
    font-family: 'Lato';
    font-size: 30px;
    color: #fff;
    padding-left: 10px;

    span {
        font-weight: bold;
    }

    @media (max-width: 450px) {
        font-size: 20px;
        padding-left: 0px;
    } 
`;

export const ForaImgBaixoSobreMim = styled.div`
    margin-top: -48px;
    margin-bottom: -95px;

    @media (max-width: 670px) {
        margin-bottom: -35px;
    } 

    @media (max-width: 450px) {
        margin-bottom: -25px;
    } 
`;

export const ImagemBaixoSobreMim = styled.img`

`;

