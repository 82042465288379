import styled from 'styled-components';
import ImgBgRodape from "../../Images/BgRodape.jpg";


export const BgRodape = styled.div`
    background-image: url(${ImgBgRodape});
    background-repeat: no-repeat;
    background-size: cover;
`;

export const ForaRodape = styled.div`
    width: 1090px;
    margin: 0 auto;
    padding-top: 75px;

    @media (max-width: 1150px) {
        width: 910px;
    } 

    @media (max-width: 950px) {
        width: 90%;
    } 

    @media (max-width: 670px) {
        padding-top: 35px;
    } 

    @media (max-width: 450px) {
        padding-top: 15px;
    } 
`;

export const RodapeCimaResp = styled.div`
    display: none;

    @media (max-width: 950px) {
        display: flex;
        width: 715px;
        margin: 0 auto;
        justify-content: space-between;
    } 

    @media (max-width: 790px) {
        width: 100%;
    }
    
    
    @media (max-width: 550px) {
        flex-direction: column;
        align-items: center;
    } 
`;

export const AssineRodape = styled.div`
    display: none;

    @media (max-width: 950px) {
        display: flex;
        background-color: #ea5ac6;
        width: 48%;
        height: 55px;
        margin-top: 15px;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
    } 

    @media (max-width: 550px) {
        width: 245px;
        height: 45px;
    } 
`;

export const ForaCoracaoRodape = styled.div`
    width: 24px;
    padding-top: 5px;
    padding-right: 15px;
`;

export const ImgCoracaoRodape = styled.img`

`;

export const TextAssineRodape = styled.div`
    color: #fff;
    font-family: 'Lato';
    font-size: 22px;
    font-weight: bold;
`;


export const CimaRodape = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    @media (max-width: 950px) {
        flex-wrap: wrap;
    }

    @media (max-width: 450px) {
        margin-bottom: 25px;
    }
`;

export const ForaLogoRodape = styled.div`
    @media (max-width: 950px) {
        margin: 0 auto;
        display: none;
    } 
`;

export const ForaLogoRodapeResp = styled.div`
    display: none;

    @media (max-width: 950px) {
        margin: 0 auto;
        display: block;
        margin-top: 40px;
        margin-bottom: 35px;
    } 

    @media (max-width: 450px) {
        width: 270px;
        margin-top: 25px;
        margin-bottom: 20px;
    } 

`;

export const ImgLogoRodape = styled.img`

`;

export const ForaSeguirResp = styled.div`
    padding-top: 60px;
    display: none;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 950px) {
        display: flex;
    } 

    @media (max-width: 780px) {
        padding-top: 40px;
    }

    @media (max-width: 450px) {
        padding-top: 25px;
    }
`;

export const RiscoSeguirResp = styled.div`
    width: 30%;
    height: 2px;
    background-color: #d04a9b;
    float: right;

    @media (max-width: 780px) {
        width: 25%;
    } 

    @media (max-width: 595px) {
        width: 20%;
    } 

    @media (max-width: 550px) {
       display: none;
    } 
`;

export const RiscoSeguirResp2 = styled.div`
    width: 30%;
    height: 2px;
    background-color: #d04a9b;
    float: left;

    @media (max-width: 780px) {
        width: 25%;
    } 

    @media (max-width: 595px) {
        width: 20%;
    } 
`;

export const ForaMenusRodape = styled.div`
    display: flex;
    width: 435px;
    justify-content: space-between;

    @media (max-width: 950px) {
        width: 100%;
    } 
`;

export const ForaMenuRodape = styled.div`

`;

export const TextMenuRodape = styled.div`
    color: #d04a9b;
    font-family: 'Lato';
    font-size: 16px;
    line-height: 28px;
    cursor: pointer;

    @media (max-width: 435px) {
        font-size: 13px;
        line-height: 22px;
    } 
`;

export const DireitaCimaRodape = styled.div`
    width: 250px;

    @media (max-width: 950px) {
        display: none;
    } 
`;

export const BotaoRodape = styled.div`
    display: flex;
    background-color: #549c00;
    align-items: center;
    justify-content: center;
    height: 55px;
    border-radius: 10px;
    margin-top: 15px;
    cursor: pointer;

    @media (max-width: 950px) {
        width: 48%;
    } 

    @media (max-width: 550px) {
        width: 245px;
        height: 45px;
    } 
`;

export const ForaImgRodape = styled.div`
    padding-right: 12px;
`;

export const ImgRodape = styled.img`

`;

export const TextBotaoRodape = styled.div`
    color: #fff;
    font-family: 'Lato';
    font-size: 18px;
    font-weight: 900;

    span {
        font-weight: 400;
    }
`;

export const BaixoDireitaRodape = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;

    @media (max-width: 950px) {
        width: 245px;
        padding-top: 0px;
    } 
`;

export const TextBaixoRodape = styled.div`
    color: #fff;
    font-family: 'Lato';
    font-size: 13px;
    font-weight: 900;
    text-transform: uppercase;
    width: 128px;
`;

export const ForaImgRedes = styled.div`
    cursor: pointer;
`;

export const ImgRedes = styled.img`

`;


export const RiscoRodape = styled.div`
    width: 100%;
    height: 2px;
    background-color: #d04a9b;
`;

export const ForaMeioRodape = styled.div`
    display: flex;
    justify-content: space-between;
    width: 440px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
    
    @media (max-width: 550px) {
        width: 370px;
    } 

    @media (max-width: 450px) {
        width: 305px;
        margin-top: 20px;
        margin-bottom: 20px;
    } 
`;

export const ForaImgMeioRodape = styled.div`

`;

export const ImgMeioRodape = styled.img`

`;

export const TextDireitos = styled.div`
    color: #fff;
    font-family: 'Lato';
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    margin-top: 30px;
    padding-bottom: 50px;

    @media (max-width: 450px) {
        margin-top: 25px;
        padding-bottom: 35px;
    } 
`;

